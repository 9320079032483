import { ErrorMessageProps } from "../../Types/MeetsType";


function ErrorMessage(props: ErrorMessageProps) {

    const { fieldName, validationType, minLength, maxLength } = props

    return (
        <>
            {validationType === "required" && <span className="error_message">{fieldName} is required.</span>}
            {validationType === "pattern" && <span className="error_message">{fieldName} is invalid.</span>}
            {validationType === "minLength" && <span className="error_message">{fieldName} minimum length should be {minLength}.</span>}
            {validationType === "maxLength" && <span className="error_message">{fieldName} maximum length should be {maxLength}.</span>}
            {validationType === "isValidAge" && <span className="error_message">{fieldName} is invalid.</span>}
            {validationType === "isValidEmail" &&
                <span className="error_message">{fieldName} is only allow gmail, yahoo, infinire domains.</span>}
            {validationType === "dateOfBirthValidation" &&
                <span className="error_message">{fieldName} is should be less than todaay date.</span>}
            {validationType === "uniqueMeetsName" &&
                <span className="error_message">The {fieldName} already exists.</span>}
        </>
    );
}

export default ErrorMessage;