import { groupBy } from "../../Utils/CommonUtils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeetsTimelineList, clearMeetTimelineData } from "../../Store/manageSlice";
import DaterangePicker from "../DateRangePicker/DateRangePicker";
import { format } from "date-fns";
import { meetsTimelinePostInput } from "../../Types/MeetsType";
import ErrorMessage from "./ErrorMessage";
import MeetsTimeLineSkeletonLoader from "../SkeletonLoader/MeetsTimeLineSkeletonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";


let postObj: meetsTimelinePostInput = {
    fromDate: '',
    toDate: '',
    prompts: [],
    promptsAllChecked: true,
    memberIds: [],
    membersAllChecked : true,
    meetsId: '',
    takeAfter: '0',
    pageSize: '10',
    timeZone: '',
    timeZoneDescription: '',
    loginUserId: '',
    role:'Project Manager',
    customPrompt:'',
    reportType:'Paragraph',
    summaryType:'Brief',
    objectives:[],
    tenantId:''
}

let errors = {
    IsPromptError: false,
    IsMemberError: false
}

function MeetsLog(props: any) {

    const dispatch = useDispatch<any>();
    const [postObject, setPostObject] = useState(postObj);
    const [error, setError] = useState(errors);
    const [IsLoading, setIsLoading] = useState(false);
    let groupedObject: any = [];
    const [meetTimeLine, setMeetTimeLine] = useState(groupedObject);
    let meetsTimeZone: any = {};
    const [meetsTimeZoneList, setmeetsTimeZoneDetail] = useState(meetsTimeZone);
    let meetsLogMember: any = [];
    const [meetsLogMembers, setmeetsLogMembers] = useState(meetsLogMember);
    const [allMemberLogViewPermission, setallMemberLogViewPermission] = useState(true);

    const userAdObjectId = useSelector(
        (state: any) => state.authReducer.userDetails.userAdObjectId
    );

    let meetsTimelineList = useSelector(
        (state: any) => state.manageReducer.meestTimeLineList
    )
    let hasMoreTimelineData = useSelector(
        (state: any) => state.manageReducer.hasMoreTimelineData
    )

    let memberList = useSelector(
        (state: any) => state.manageReducer.meetsData.meetMembers
    )

    const promptList = useSelector(
        (state: any) => state.manageReducer.meetsData.questions
    )   

    let meetsTimeZoneDetail = useSelector(
        (state: any) => state.manageReducer.meetsTimeZoneList
    )

    const meetsData = useSelector(
        (state: any) => state.manageReducer.meetsData
    );
    const { meetsId } = useParams<"meetsId">();

    const [hasMore, setHasMore] = useState(hasMoreTimelineData);
    const [count, setCount] = useState(meetsTimelineList.length);
    let [isAllFilterSet, setIsAllFilterSet] = useState(false);

    useEffect(() => {
        meetsTimelineList = meetsTimelineList.filter((meetsLog: any) => meetsLog.report.length > 0);
        groupedObject = groupBy(meetsTimelineList, "reportDate");
        setMeetTimeLine(groupedObject);
        setCount(meetsTimelineList.length);
    }, [meetsTimelineList])


    useEffect(() => {
        if (postObject.memberIds.length != 0 && ( postObject.prompts.length != 0 || props.isProfile) && postObject.fromDate && postObject.toDate) {
            (async () => {
                await dispatch(clearMeetTimelineData([]));
                postObject.takeAfter = '0';
                await getMeetsLog();               
            })();
        }
    }, [isAllFilterSet, postObject.fromDate, postObject.toDate, error])

    const getMeetsLog = async () => {
        setIsLoading(true);
        postObject.loginUserId = userAdObjectId;
        postObject.timeZone = props.isProfile ? props.timeZone : meetsTimeZoneList ? meetsTimeZoneList.timeZone : meetsData.meetsTrigger.timezone;
        postObject.timeZoneDescription = meetsTimeZoneList ? meetsTimeZoneList.timeZoneDescription : "";
        postObject.membersAllChecked = !allMemberLogViewPermission ? false : postObject.membersAllChecked;      

        await dispatch(fetchMeetsTimelineList(postObject));
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        setPostObject((existingValues: any) => {
            let prePostObject = { ...existingValues };
            if (!props.isProfile) {
                prePostObject.prompts = formQuestionList();
                prePostObject.memberIds = formMembersList();
                prePostObject.meetsId = meetsId;
            }
            else{
                prePostObject.memberIds = [userAdObjectId];
                prePostObject.prompts = [];
                prePostObject.meetsId = "";
                prePostObject.teamsId = props.teamsId;
                prePostObject.membersAllChecked = false;
            }
            if (userAdObjectId && prePostObject.memberIds.length > 0 && ( prePostObject.prompts.length >0 || props.isProfile) && ( prePostObject.meetsId || prePostObject.teamsId))
                setIsAllFilterSet(true);
            return prePostObject;
        })

    }, [memberList, promptList, meetsId, userAdObjectId])

    const dateRangeFilter = async (dateRange: any) => {
        const { endDate, startDate } = dateRange;
        setPostObject((existingValues: any) => {
            let prePostObject = { ...existingValues };
            prePostObject.fromDate = format(startDate, "yyyy-MM-dd");
            prePostObject.toDate = format(endDate, "yyyy-MM-dd");
            return prePostObject;
        })
    }

    const getPromptsFilterValue = (event: any) => {
        const { checked, value } = event.target;
        let isPromptError = false;
        if (value === 'all') {
            if (checked)
                postObject.prompts = formQuestionList();
            else {
                setMeetTimeLine([])
                postObject.prompts = []
                isPromptError = true;
            }
        }
        else {
            if (checked)
                postObject.prompts.push(value);
            else {
                let index = postObject.prompts.indexOf(value);
                if (index != -1)
                    postObject.prompts.splice(index, 1);
                postObject.prompts.length == 0 ? isPromptError = true : isPromptError = false;
            }
        }

        postObject.prompts.length != promptList.length ? postObject.promptsAllChecked = false: postObject.promptsAllChecked = true;
        setError({
            ...error,
            IsPromptError: isPromptError
        })

        setPostObject(existingValues => ({
            ...existingValues,
            prompts: postObject.prompts,
            promptsAllChecked: postObject.promptsAllChecked
        }))

        if(postObject.prompts && postObject.prompts.length === 0) {
            dispatch(clearMeetTimelineData([]));
        }
    }

    const getMemebersFilterValue = (event: any) => {
        const { checked, value } = event.target;
        let IsMemberError = false;
        if (value === 'all') {
            if (checked)
                postObject.memberIds = formMembersList();
            else {
                setMeetTimeLine([])
                postObject.memberIds = []
                IsMemberError = true;
            }
        }
        else {
            if (checked)
                postObject.memberIds.push(value);
            else {
                let index = postObject.memberIds.indexOf(value);
                if (index != -1)
                    postObject.memberIds.splice(index, 1);
                postObject.memberIds.length == 0 ? IsMemberError = true : IsMemberError = false;
            }
        }
        (postObject.memberIds.length != memberList.length || !allMemberLogViewPermission) ? postObject.membersAllChecked = false: postObject.membersAllChecked = true;
        setError({
            ...error,
            IsMemberError: IsMemberError
        })

        setPostObject(existingValues => ({
            ...existingValues,
            memberIds: postObject.memberIds,
            membersAllChecked: postObject.membersAllChecked
        }))

        if(postObject.memberIds && postObject.memberIds.length === 0) {
            dispatch(clearMeetTimelineData([]));
        }
    }

    const formMembersList = () => {
        let memberIds: string[] = [];
        let allMemberLogViewPermission = true;
        const loggedInMember = memberList.filter((member: any) => member.aadObjectId === userAdObjectId);
        if (loggedInMember.length > 0 && !loggedInMember[0].meetsPermissions.report && !loggedInMember[0].meetsPermissions.manage) {
                allMemberLogViewPermission = false;
        }

        if (allMemberLogViewPermission) {
            setmeetsLogMembers(memberList);
            memberList.map((member: any) => {
                memberIds.push(member.aadObjectId)
            });
        }
        else {
            setmeetsLogMembers(loggedInMember);
            memberIds.push(userAdObjectId);
        }
        setallMemberLogViewPermission(allMemberLogViewPermission);
        return memberIds;
    }

    const formQuestionList = () => {
        let prompts: string[] = [];
        promptList.map((prompt: any) => {
            prompts.push(prompt.question)
        });
        return prompts;
    }

    const IsMemeberSelected = (memberID: any) => {
        const IsSelected = postObject.memberIds.filter((member: any) => member === memberID)[0];
        return IsSelected ? true : false;
    }

    const IsQuestionSelected = (questionName: string) => {
        const IsSelected = postObject.prompts.filter((prompt: any) => prompt === questionName)[0];
        return IsSelected ? true : false;
    }

    const fetchMoreData = async () => {
        postObject.takeAfter = (parseInt(postObject.takeAfter) + 10).toString();
        await getMeetsLog();
    }

    useEffect(() => {
        setHasMore(hasMoreTimelineData)
    }, [hasMoreTimelineData])

    useEffect(() => {    
        setmeetsTimeZoneDetail(meetsTimeZoneDetail);
    }, [meetsTimeZoneDetail])

    useEffect(() => {
        return () => { // works on page leave
            dispatch(clearMeetTimelineData([]));
        };
    }, []);

    return (
        <>
            <div className="container my-3" style={{ padding: '0px' }}>
                <div className="row timeline-heading minites-meeting-heading">
                    <h3 className="h3 fw-bold">Meets Log</h3>
                    <div className="col-lg-4 col-md-4 my-2">
                        <h3 className="h3" style={{overflow: "hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>Date Range<span style={{ fontSize: "14px" }}> - { props.isProfile ? props.timeZone :  (meetsTimeZoneList  ? meetsTimeZoneList.timeZone : meetsData.meetsTrigger.timezone) }</span></h3>
                        <DaterangePicker dateRange={dateRangeFilter} />
                    </div>
                    {!props.isProfile &&
                        <>
                            <div className="col-lg-4 col-md-4 my-2">
                                <h3 className="h3">Members</h3>
                                <div className="position-relative time-line">
                                    <button type="button" className={"form-control form-control-lg focus-prompt position-relative text-start " + (error.IsMemberError ? 'btn_required_validation' : '')}> <span>Members</span>
                                        <ul className="dropdown-menu border-white shadow timeline-prompts mt-2 timeline-height" aria-labelledby="dropdownMenuButton1">
                                            <li className="mt-1 d-flex align-items-center">
                                                <label htmlFor="click">All Members</label>
                                                <div className="ms-auto d-flex align-items-center">
                                                    <input checked={postObject.memberIds.length === meetsLogMembers.length} type="checkbox" id="click"
                                                        onChange={(event) => getMemebersFilterValue(event)} value="all" className="form-check-input mt-0" />
                                                </div>
                                            </li>
                                            {meetsLogMembers.map((members: any, index: any) =>
                                                <li key={"members" + index} className="mt-1 d-flex align-items-center">
                                                    <label htmlFor={`Member${index}`}>{members.name}</label>
                                                    <div key={"member" + index} className="ms-auto d-flex align-items-center">
                                                        <input type="checkbox" checked={IsMemeberSelected(members.aadObjectId)} id={`Member${index}`}
                                                            value={members.aadObjectId} className="form-check-input mt-0" onChange={(event) => getMemebersFilterValue(event)} />
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </button>
                                    {error.IsMemberError && <ErrorMessage validationType='required' fieldName="Members" />}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 my-2">

                                <h3 className="h3 d-flex align-items-center">Prompts</h3>
                                <div className="position-relative time-line">
                                    <button type="button" className={"form-control form-control-lg focus-prompt position-relative text-start " + (error.IsPromptError ? 'btn_required_validation' : '')}> <span>Prompts</span>
                                        <ul className="dropdown-menu border-white shadow timeline-prompts mt-2 timeline-height" aria-labelledby="dropdownMenuButton1">
                                            <li className="mt-1 d-flex align-items-center"><label htmlFor="clicked">All</label>
                                                <div className="ms-auto d-flex align-items-center">
                                                    <input type="checkbox" checked={postObject.prompts.length === promptList.length} id="clicked" className="form-check-input mt-0" value="all" onChange={(event) => getPromptsFilterValue(event)} /></div>
                                            </li>
                                            {
                                                promptList.map((prompts: any, index: any) =>
                                                    <li key={"questions" + index} className="mt-1 d-flex align-items-center">
                                                        <label htmlFor={`Question${index}`}>{prompts.question}</label>
                                                        <div key={"question" + index} className="ms-auto d-flex align-items-center">
                                                            <input type="checkbox" checked={IsQuestionSelected(prompts.question)} id={`Question${index}`}
                                                                value={prompts.question}
                                                                className="form-check-input mt-0" onChange={(event) => getPromptsFilterValue(event)} />
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </button>
                                    {error.IsPromptError && <ErrorMessage validationType='required' fieldName="Prompts" />}
                                </div>
                            </div>
                        </>
                    }
                </div>
                <InfiniteScroll
                    dataLength={count}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={IsLoading && <MeetsTimeLineSkeletonLoader />}
                    scrollThreshold='200px'

                >
                    {   //MeetsTimeLine Date Loop
                        !IsLoading && meetTimeLine.map((TimeLine: any, timelineIndex: any) =>
                            <div className="row mt-4 mx-0" key={'timeline' + timelineIndex}>
                                <div className="col-12">
                                    <div className="p-2">
                                        <h5 className="h5 text-center">{TimeLine.meetDate}</h5>
                                        <div className="row time-left-border">
                                            {
                                                //User Loop
                                                TimeLine.meetList.map((list: any, meetsIndex: any) =>
                                                    <div className="col-lg-6 col-md-6 px-3 mt-2" key={'meetsList' + meetsIndex}>
                                                        <div className="d-flex align-items-center mt-2 timeline-profile">
                                                            <div className="d-flex">
                                                                <div className="teamMember-curve">{list.name.slice(0, 1)}</div>
                                                                <div className="ms-2 py-1">
                                                                    <h6 className="h6 ms-2 mt-2 fw-bolder">{list.name}</h6>
                                                                    <p className="p my-0 ms-2">{list.reportTime}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">

                                                            {   //Question loop
                                                                list.report.map((question: any, reportIndex: any) =>

                                                                    <div className="time-line-left mt-3" key={'report' + reportIndex}>
                                                                        <div className="time-line-border">
                                                                            <h6 className="h6 fw-bolder">{question.prompt}</h6>
                                                                            <div className="p mt-2 mb-0">
                                                                                <div dangerouslySetInnerHTML={{ __html: question.answer }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </InfiniteScroll>
                {
                    (!IsLoading && Object.keys(meetTimeLine) && Object.keys(meetTimeLine).length === 0) &&
                    <div className="container" style={{ textAlign: "center", padding: "20px" }}>
                        <img src={require('../../Assets/Images/norecords.png')} width="100%" alt="UserIcon" />

                    </div>
                }
            </div>
        </>
    )
}

export default MeetsLog;