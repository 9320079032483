import React from "react";
import "./Assets/Css/style.css";
import "./Assets/Css/responsive.css";
import "./Assets/Css/landingPageStyle.css";
import { RouterProvider } from "react-router-dom";
import Router from "./Router/Router";
import UnAuthRouter from "./Router/UnAuthRouter";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import axios from "axios";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig, loginApiRequest } from "./Services/authConfig";

function App() {
  const { accounts, instance } = useMsal();
  const isAuthenticated = accounts.length > 0;

  const msalInstance = new PublicClientApplication(msalConfig);

  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        try {
          const msalResponse = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account,
          });
          config.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect(loginApiRequest).catch((e) => {
              console.log(e);
            });
            localStorage.clear();
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RouterProvider router={isAuthenticated ? Router : UnAuthRouter} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <RouterProvider router={UnAuthRouter} />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
