import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MeetsName from '../../Components/Meets/MeetsName';
import MeetsChannels from '../../Components/Meets/MeetsChannels';
import MeetsPrompts from '../../Components/Meets/MeetsPrompts';
import MeetsMembers from '../../Components/Meets/MeetsMembers';
import MeetsTriggerTime from '../../Components/Meets/MeetsTriggerTime';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
    updateTabDetails, loadDefaultPrompts, createMeetsData, showAndHidePageLoader,
    loadDefaultScheduleTiming, clearMeetsData, fetchMeets, removeSelectedMember,
    updateClone, fetchMembersList, updateTeamsData, fetchChannelList,
    fetchTimeZoneList, updateUserObjectId, getLoggedinUser
} from "../../Store/manageSlice";
import swal from "sweetalert";
import MeetsSchedule from '../../Components/Meets/MeetsSchedule';
import CreateMeetsSkeletonLoader from '../../Components/SkeletonLoader//CreateMeetsSkeletonLoader';
import { getInitials } from "../../Utils/CommonUtils";
import { updateBlockerData } from '../../Store/commonSlice';
import moment from "moment";
import 'moment-timezone';
import {
    clearHolidayTemplateData
} from "../../Store/holidayTemplateSlice";
import MeetsObjectives from '../../Components/Meets/MeetsObjectives';
import MeetsRoles from '../../Components/Meets/MeetsRoles';
import Breadcrumbs from '../../Components/Layout/Shared/Breadcrumbs';


function CreateMeets() {
    const dispatch = useDispatch<any>();

    const currentTab = useSelector(
        (state: any) => state.manageReducer.currentTab
    );

    const meetsData = useSelector(
        (state: any) => state.manageReducer.meetsData
    );
    const [isHolidayChecked, setisHolidayChecked] = useState(false);
    const [isUserBasedPromptsChecked, setisUserBasedPromptsChecked] = useState(false);
    const promptsList = meetsData && meetsData.questions;
    const { meetsId } = useParams<"meetsId">();
    let location = useLocation();

    const defaultMemberList = useSelector(
        (state: any) => state.manageReducer.defaultMemberList
    );

    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );

    const defaultTeamsName = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsName
    );

    const userAdObjectId = useSelector(
        (state: any) => state.authReducer.userDetails.userAdObjectId
    );

    const holidayTemplate = useSelector(
        (state: any) => state.holidayTemplateReducer.holidayTemplate
    );
    let chatGPTApiKey = useSelector(
        (state: any) => state.manageReducer.tenantData.chatGPTApiKey
    )
    let localTimeZone = moment.tz.guess();
    localTimeZone = localTimeZone.replace("Calcutta","Kolkata");

    useEffect(() => {
        dispatch(showAndHidePageLoader({ value: true }));
        if (defaultTeamsId) {
            (async () => {
                await dispatch(fetchMembersList({ teamsId :defaultTeamsId, timeZone : localTimeZone}));
                await dispatch(fetchChannelList(defaultTeamsId));
                await dispatch(fetchTimeZoneList());
                await dispatch(updateTeamsData({ teamsId: defaultTeamsId, teamsName: defaultTeamsName }));
                
                if (!meetsId) {
                    dispatch(loadDefaultPrompts());
                    dispatch(loadDefaultScheduleTiming());
                    dispatch(getLoggedinUser(userAdObjectId));
                }
                else {
                    await dispatch(fetchMeets(meetsId));
                    dispatch(removeSelectedMember())
                    if (location.pathname.toLowerCase().indexOf("clone") > -1) {
                        dispatch(updateClone());
                        dispatch(loadDefaultScheduleTiming());
                    }
                }
                await dispatch(updateUserObjectId({ createdByAadObjectId: userAdObjectId, isEdit:meetsId ? true : false }));
                dispatch(showAndHidePageLoader({ value: false }));
            })();
        }
    }, [defaultTeamsId]);

    useEffect(() => {
        return () => { // works on page leave
            dispatch(clearMeetsData());
            dispatch(clearHolidayTemplateData());
        };
    }, []);

    const pageTitle: String = (meetsId && location.pathname.toLowerCase().indexOf("clone") > -1) ? 'Clone' : meetsId ? 'Edit ' : 'Create ';
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        resetField,
        reset
    } = useForm(
        {
            mode: "all",
        }
    );

    const onSubmit = async () => {
        if (meetsData.questions.length > 0 && meetsData.channels.length > 0 && validateUserBasedPrompts(meetsData)) {
            let meetsPostData = JSON.parse(JSON.stringify(meetsData));
            dispatch(showAndHidePageLoader({ value: true }));
            dispatch(updateBlockerData({ isModified: false }));
            if (isHolidayChecked)
                meetsPostData.holidays = holidayTemplate.holidays;
            else
                meetsPostData.holidays = [];

            if (isUserBasedPromptsChecked)
                meetsPostData.userBasedPrompts = meetsData.userBasedPrompts;
            else
                meetsPostData.userBasedPrompts = [];
            
            let response = await dispatch(createMeetsData(meetsPostData));
            if (response.payload) {
                await swal({ title: "Success", text: `The Meets ${meetsId ? 'updated' : 'created'} successfully`, icon: "success", timer: 3000, buttons: [false] })
                    .then((value) => {
                        routeChange("/")
                        dispatch(showAndHidePageLoader({ value: false }));
                    });
            }
        }
    }

    const validateUserBasedPrompts = (meetsData: any) => {
        let userBasedPromptsValidation = true;
        let filteredPrompts = meetsData.userBasedPrompts.find((x: any) => x.prompts.length === 0);
        if(filteredPrompts){
            userBasedPromptsValidation = false;
        }

        return userBasedPromptsValidation;
    }

    const onError = (errors: any, event: any) => {
        console.log(errors, event);
    }

    const navigateTab = (tabName: string) => {
        dispatch(updateTabDetails(tabName));
    };

    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    }

    const pageLoader = useSelector(
        (state: any) => state.manageReducer.pageLoader
    );

    const selectedMembers = useSelector(
        (state: any) => state.manageReducer.meetsData.meetMembers
    );

    const holidaysCheckeUpdate = (checked : boolean) => {  
        setisHolidayChecked(checked);     
    }

    const userBasedPromptsCheckeUpdate = (checked : boolean) => {  
       setisUserBasedPromptsChecked(checked);
    }

    return (
        <>
            {pageLoader && <CreateMeetsSkeletonLoader />}
            {!pageLoader && <div>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="container-fluid bg-light">
                        <div className="container py-4">
                            <div className="d-flex create-meet">
                                <div className="dashboard-create-meet">
                                    <h1 className="h1 my-0 fw-bold">{pageTitle} Meets</h1>
                                    <Breadcrumbs currentPage={`${pageTitle} Meets`} />
                                </div>
                                <div className="ms-auto my-auto">
                                    <button type="submit" className="btn float-end fw-bold newstandup-button"><i className="fa-solid fa-check me-2"></i>Save</button>
                                    <button type="button" className="btn float-end fw-bold newstandup_cancel_button mx-2" onClick={() => routeChange("/")}><i className="fa-solid fa-xmark me-2"></i>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">

                        <div className="row">
                            <MeetsName register={register}
                                errors={errors} reset={reset} />
                            <MeetsChannels register={register}
                                errors={errors} reset={reset} control={control} />
                        </div>
                        
                                {chatGPTApiKey !='' &&
                                <div>
                        <div className='row'>
                            <MeetsObjectives register={register} errors={errors} resetField={resetField} />
                        </div>
                        <div className='row'>
                            <MeetsRoles register={register} errors={errors} resetField={resetField} />
                        </div>
                        </div>
}
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="nav-align-top mt-1 mb-4">
                                    <ul className="nav nav-pills row">                                        
                                        <li className={"nav-item col-lg-4 col-md-4 col-sm-4 nav-tab mb-3 " + (errors.meetsmembers && (selectedMembers && selectedMembers.length === 0) ? 'active_validation' : '')} onClick={() => navigateTab('meetsMembers')}>
                                            <div className={"nav-link p-0 h-100 " + (currentTab === "meetsMembers" ? 'active' : '')}>
                                                <div className="card border-white weatherCard">
                                                    <h2 className="h2">Members</h2>
                                                    {!meetsId &&
                                                        <div className="info__container my-auto">
                                                            {defaultMemberList?.slice(0, 3).map((data: any, index: any) =>
                                                                <div key={"memberList" + index} className={(data.isSelected) ? 'info' : 'info default-user'}>
                                                                    <div key={"memberList" + index} className="info__value">{getInitials(data.name)}</div>
                                                                </div>
                                                            )}

                                                            {defaultMemberList.length > 3 &&
                                                                <div key="parentCount" className={(meetsData.meetMembers.length > 3) ? 'info bg-dark' : 'info default-user'}>
                                                                    <div key="childCount" className="info__value">+{(meetsData.meetMembers.length > 3) ? meetsData.meetMembers.length - 3 : defaultMemberList.length - 3}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {meetsId &&
                                                        <div className="info__container my-auto">
                                                            {meetsData.meetMembers?.slice(0, 3).map((data: any, index: any) =>
                                                                <div key={"parent" + index} className="info">
                                                                    <div key={"child" + index} className="info__value">{getInitials(data.name)}</div>
                                                                </div>
                                                            )}

                                                            {meetsData.meetMembers.length > 3 &&
                                                                <div key="parentCount" className="info bg-dark">
                                                                    <div key="childCount" className="info__value">+{meetsData.meetMembers.length - 3}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li className={"nav-item col-lg-4 col-md-4 col-sm-4 nav-tab mb-3 "
                                            + ((errors.question || errors.welcomeMessage || errors.thankyouMessage || meetsData.questions.length <= 0) ? 'active_validation' : '')}
                                            onClick={() => navigateTab('meetsPrompt')}>
                                            <div className={"nav-link p-0 h-100 " + (currentTab === "meetsPrompt" ? 'active' : '')}>
                                                <div className="card border-white weatherCard">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <h2 className="h2">Prompt</h2>
                                                            <ul className="ps-4 prompt-li">
                                                                {promptsList && promptsList.length === 0 && <p key="0" className="text-center"> No Prompts </p>}
                                                                {promptsList?.slice(0, 3).map((data: any, index: any) =>
                                                                    <li key={data.questionIndex} className=""> <span>{data.question}</span> </li>
                                                                )}
                                                                {promptsList.length > 3 && <p className='prompt-see-more'>+ {promptsList.length - 3} Prompts</p>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={"nav-item col-lg-4 col-md-4 col-sm-4 nav-tab mb-3 "
                                            + ((errors.period || errors.days || errors.time || errors.timezone || errors.meetDate)
                                                ? 'active_validation' : '')}
                                            onClick={() => navigateTab('meetsTriggerTime')}>
                                            <div className={"nav-link p-0 h-100 " + (currentTab === "meetsTriggerTime" ? 'active' : '')}>
                                                <div className="card border-white weatherCard">
                                                    <h2 className="h2">Trigger Time</h2>
                                                    <MeetsSchedule meetSchedule={meetsData.meetsTrigger} />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content tab-fade-content mt-3">
                                    <div style={{ display: currentTab === "meetsPrompt" ? 'block' : 'none' }}>
                                        <MeetsPrompts register={register} errors={errors} resetField={resetField} userbasedPromptsCheckUpdate = {userBasedPromptsCheckeUpdate} />
                                    </div>
                                    <div style={{ display: currentTab === "meetsMembers" ? 'block' : 'none' }}>
                                        <MeetsMembers register={register} errors={errors} control={control} />
                                    </div>
                                    <div style={{ display: currentTab === "meetsTriggerTime" ? 'block' : 'none' }}>
                                        <MeetsTriggerTime register={register} errors={errors} control={control}
                                            resetField={resetField} reset={reset} holidaysCheckeUpdate = {holidaysCheckeUpdate}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </>
    );
}

export default CreateMeets;