import React from 'react';
import { addObjectives, updateObjectives, removeObjectives } from '../../Store/manageSlice';
import { findHelpText } from "../../Utils/CommonUtils";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import ErrorMessage from "./ErrorMessage";
function MeetsObjectivesComponent(props: any) {
  const { register, errors, resetField } = props;
  const meets = useSelector(
    (state: any) => state.manageReducer.meetsData
  );
  const { meetsId } = useParams<"meetsId">();
  const dispatch = useDispatch<any>();
  const addNewObjective = async (payload: any) => {
    await dispatch(addObjectives(payload));
  }
  const updateObjectiveCallBack = (event: React.ChangeEvent<HTMLInputElement>, objectiveIndex: any) => {
    dispatch(updateObjectives({ obective: event.target.value, objectiveIndex }))

  };
  const removeObjectiveCallBack = (index:any) => {
    swal({
      title: "Warning", text: "Are you sure that you want to delete this objective?", icon: "warning",
      dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
    }).then((willDelete: any) => {
      if (willDelete) {
        swal({ title: "Success", text: "The selected objective has been deleted successfully", icon: "success", timer: 1000, buttons: [false] })
        dispatch(removeObjectives({objectiveIndex:index}));
       resetField(`objective.${index}`);
      }
    });
  }
  return (
    <div className="mt-3">
      <h2 className="h2 mt-12 d-flex align-items-center">Objectives
        <div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("Objectives")}>i</span></div>
        <button type="button" className="prompt-form-add ms-2" onClick={() => addNewObjective({ aadObjectId: '' })}>Add Objectives
          <i className="fa-sharp fa-solid fa-plus ms-1"></i></button>
      </h2>

      {meets.objectives && meets.objectives.map((objectives: any, index: any) =>
        <div style={{ margin: '15px 6px' }}>
          <div className="position-relative">
            <input type="text" className="form-control form-control-lg"
              {...register(`objective.${index}` as const, {
                required: true, maxLength: 200
              })} 
              placeholder="Enter an objective" value={objectives.objective}
              name={"objective." + index} id={"objective." + index} onChange={(event: any) => updateObjectiveCallBack(event, index)} />
            <div className="d-flex position-absolute" style={{ top: "1px", right: "1px" }} >
              <button type="button" onClick={() =>removeObjectiveCallBack(index)} className="fa-solid fa-trash" style={{ border: 'none', color: '#324e63', background: "#EEEEEE", padding: "15px", borderRight: "1px solid #ced4da" }}></button>
            </div>
          </div>
          {!!errors?.objective?.[index] &&
          <ErrorMessage validationType="required" fieldName="Objective" maxLength="200" />}
        </div>
      )}
    </div>
    
  );
}

export default MeetsObjectivesComponent;