import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select, { ControlProps, components } from 'react-select'
import { clearMeetsList, updateTeams } from '../../../Store/meetsSlice';
import { clearMeetsData } from '../../../Store/manageSlice';
import swal from "sweetalert";

const Breadcrumbs = ({ paths = [], currentPage, hideTeams =false }: { paths?: { name: string, route: string }[], currentPage?: string, hideTeams?:boolean }) => {
    const dispatch = useDispatch<any>();
    const defaultMeetName = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsName
    );
    const teamList = useSelector(
        (state: any) => state.meetsReducer.teamsListData
    );
    const navigate = useNavigate();
    const routeChange = (path: string) => {
        navigate(path);
    };
    const [modifiedTeamList, setModifiedTeamList] = useState([]);

    const [defaultMeet, setDefaultMeet] = useState([]);

    const fullPaths = [{ name: "Home", route: "/" }, ...paths];


    const Control = ({ children, ...props }: ControlProps<any>) => {
        //console.log(selectProps.value);
        const { selectProps } = props;
        const { label } = selectProps?.value || '';
        const initial = label?.substring(0, 2) || '';
        return (
            <components.Control {...props}>
                {
                    initial && (
                        <span className="header-logo-button initial_info ms-1 text-uppercase" >{initial}</span>
                    )
                }
                {children}
            </components.Control>
        );
    }
    
    useEffect(() => {
        if (teamList && teamList.length > 0) {
            const modifiedList = teamList?.map((team: any) => ({
                ...team,
                value: team.teamsName,
                label: team.teamsName
            }));
            setModifiedTeamList(modifiedList);
            setDefaultMeet(modifiedList.find((team: any) => team.teamsName === defaultMeetName) ?? modifiedList[0]);
        } else {
            setModifiedTeamList([]);
        }
    }, [teamList]);

    const teamsChange = async (event: any) => {
        let teams = teamList.find((teams: any) => teams.teamsName === event.value);
        if (teams && !teams.isBotInstalled) {
            await swal({
                title: "Warning", text: "Meetstand Bot is not installed in the " + teams.teamsName + ". Please install the meetstand to continue the manage meets.Do you need to install  MeetsStand Bot in " + teams.teamsName + "? ", icon: "warning",
                dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
            }).then(async willInstall => {
                if (willInstall) {
                    window.location.replace(process.env.REACT_APP_TEAMS_REDIRECT_URL as string);
                }
                else {
                    const meet = modifiedTeamList.find((team: any) => team.teamsName === defaultMeetName);
                    setDefaultMeet(meet??modifiedTeamList[0]);
                }
            });
        }
        else {
            dispatch(updateTeams({ name: event.value, value: teams.teamsId }));
            dispatch(clearMeetsData());
            dispatch(clearMeetsList());          
            routeChange("/");
        }
    };

    return (
        <div className="mt-2 d-flex flex-row align-items-center gap-2">

            <div>

                <span className="me-1" style={{ marginTop: '-1px', color: "#8a1bba" }}>
                    <i className="fa-solid fa-house"></i>
                </span>

                {fullPaths.map((path, index) => (
                    <>
                        <span key={index} className="me-1"
                            style={{ cursor: "pointer", color: "#8a1bba" }}
                            onClick={() => routeChange(path.route)}
                        >
                            {path.name}
                        </span>
                        {
                            index < fullPaths.length - 1 && (
                                <span className="me-1">/</span>
                            )
                        }

                    </>
                ))}

                <span>{currentPage && `/ ${currentPage}`}</span>
            </div>
            <div className="d-flex align-items-center team_selection">
            {modifiedTeamList && hideTeams===false && <div className="d-flex align-items-center"> <span className='me-2'> / </span>
                  <Select 
                    components={{ Control }}
                    className='custom_react_select'
                    placeholder="Select Team"
                    options={modifiedTeamList}
                    value={defaultMeet}
                    onChange={teamsChange} />
                  </div>
                    
                    }
            </div>

        </div>
    );
};

export default Breadcrumbs;
