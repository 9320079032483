import { groupBy } from "../../Utils/CommonUtils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {clearMeetUnReportedSummaryData, fetchMeetsUnReportedCounts, fetchMeetsTimelineList, fetchMeetsTimelineSummary, fetchMeetsTimelineConsolidateSummary, clearMeetTimelineData, clearMeetSummaryData } from "../../Store/manageSlice";
import DaterangePicker from "../DateRangePicker/DateRangePicker";
import { format } from "date-fns";
import { meetsTimelinePostInput } from "../../Types/MeetsType";
import ErrorMessage from "./ErrorMessage";
import MeetsTimeLineSkeletonLoader from "../SkeletonLoader/MeetsTimeLineSkeletonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { json } from "stream/consumers";
import Select from 'react-select';
import { meetsSummaryDateCount, meetsSummaryMemberCount } from "../../Constant/ApplicationConstant";
import Chart from "react-apexcharts";

let postObj: meetsTimelinePostInput = {
    fromDate: '',
    toDate: '',
    prompts: [],
    promptsAllChecked: true,
    memberIds: [],
    membersAllChecked: true,
    meetsId: '',
    takeAfter: '0',
    pageSize: '10',
    timeZone: '',
    timeZoneDescription: '',
    loginUserId: '',
    role: 'Project Manager',
    customPrompt: '',
    reportType: 'Paragraph',
    summaryType: 'Brief',
    objectives: [],
    tenantId: ''
}

let errors = {
    IsPromptError: false,
    IsMemberError: false
}
let setCountLength: any = 0

let chatGPTMeetRequestCount: any = 0;
let chatGPTMeetRequestLimit: any = 0;

let TenantRequestCount: any = 0;
let TenantRequestLimit: any = 0;
const colors = [
    '#8a1bba'
];
function MeetsLog(props: any) {

    const dispatch = useDispatch<any>();
    const [postObject, setPostObject] = useState(postObj);
    const [error, setError] = useState(errors);
    const [IsLoading, setIsLoading] = useState(false);
    let groupedObject: any = [];
    const [meetTimeLine, setMeetTimeLine] = useState(groupedObject);
    let meetsTimeZone: any = {};
    const [meetsTimeZoneList, setmeetsTimeZoneDetail] = useState(meetsTimeZone);
    let meetsLogMember: any = [];
    const [meetsLogMembers, setmeetsLogMembers] = useState(meetsLogMember);

    const [allMemberLogViewPermission, setallMemberLogViewPermission] = useState(true);
    const [isConsolidateSummary, setCosolidateSummary] = useState(false);
    const [IsConsolidateSummaryLoading, setIsConsolidateSummaryLoading] = useState(false);
    const [isSummary, setSummary] = useState(false);
    const [isUnReportedSummary, setUnReportedSummary] = useState(false);
    const [isCustom, setCustom] = useState(false);
    const [isDateCountValidated, setDateCountValidated] = useState(true);
    const [isMemberCountValidated, setMemberCountValidated] = useState(true);
    const [isMeetRequestLimitValidate, setMeetRequestLimitValidated] = useState(true);
    const [isRequestLimitValidate, setRequestLimitValidated] = useState(true);

    const userDetails = useSelector(
        (state: any) => state.authReducer.userDetails
    );
    let meetsTimelineList = useSelector(
        (state: any) => state.manageReducer.meestTimeLineList
    )
    let meetsTimeSummary = useSelector(
        (state: any) => state.manageReducer.membersLogSummary
    )
    let unReportedCounts = useSelector(
        (state: any) => state.manageReducer.unRepotedCounts
    )
    let meetsTimeConsolidateSummary = useSelector(
        (state: any) => state.manageReducer.membersLogConsolidateSummary
    )
    let hasMoreTimelineData = useSelector(
        (state: any) => state.manageReducer.hasMoreTimelineData
    )
    let memberList = useSelector(
        (state: any) => state.manageReducer.meetsData.meetMembers
    )
    const promptList = useSelector(
        (state: any) => state.manageReducer.meetsData.questions
    )
    let objectiveList = useSelector(
        (state: any) => state.manageReducer.meetsData.objectives
    )
    let RoleList = useSelector(
        (state: any) => state.manageReducer.meetsData.roles
    )
    let meetsTimeZoneDetail = useSelector(
        (state: any) => state.manageReducer.meetsTimeZoneList
    )
    const meetsData = useSelector(
        (state: any) => state.manageReducer.meetsData
    );
    const { meetsId } = useParams<"meetsId">();

    const [hasMore, setHasMore] = useState(hasMoreTimelineData);
    const [count, setCount] = useState(meetsTimelineList.length);

    let [isAllFilterSet, setIsAllFilterSet] = useState(false);

    let chatGPTRequestCount = useSelector(
        (state: any) => state.manageReducer.chatGPTRequestCount
    )
    let chatGPTMeetLimit = useSelector(
        (state: any) => state.manageReducer.tenantData.meetChatGPTAPIRequestLimit
    )
    let chatGPTTenantRequestLimit = useSelector(
        (state: any) => state.manageReducer.tenantData.tenantChatGPTAPIRequestLimit
    )
    let chatGPTTenantRequestCount = useSelector(
        (state: any) => state.manageReducer.chatGPTTenantRequestCount
    )

    useEffect(() => {

        chatGPTMeetRequestCount = chatGPTRequestCount;
    }, [chatGPTRequestCount]);

    useEffect(() => {

        chatGPTMeetRequestLimit = chatGPTMeetLimit;
    }, [chatGPTMeetLimit]);

    useEffect(() => {

        TenantRequestCount = chatGPTTenantRequestCount;
    }, [chatGPTTenantRequestCount]);
    useEffect(() => {

        TenantRequestLimit = chatGPTTenantRequestLimit;
    }, [chatGPTTenantRequestLimit]);

    useEffect(() => {
        meetsTimelineList = meetsTimelineList.filter((meetsLog: any) => meetsLog.report.length > 0);

        groupedObject = groupBy(meetsTimelineList, "reportDate");
        setMeetTimeLine(groupedObject);

        setCount(meetsTimelineList.length);
        setCountLength = meetsTimelineList.length;


    }, [meetsTimelineList])


    useEffect(() => {
        if (postObject.memberIds.length != 0 && (postObject.prompts.length != 0 || props.isProfile) && postObject.fromDate && postObject.toDate) {
            (async () => {
                await dispatch(clearMeetTimelineData([]));
                await dispatch(clearMeetSummaryData([]));
                await dispatch(clearMeetUnReportedSummaryData([]));
                setCosolidateSummary(false);
                postObject.takeAfter = '0';
                // await getMeetsLog();
            })();
        }
    }, [isAllFilterSet, postObject.fromDate, postObject.toDate, error])

    const getMeetsLog = async () => {
        
        
        await dispatch(clearMeetTimelineData([]));
        setIsLoading(true);
        postObject.loginUserId = userDetails.userAdObjectId;
        postObject.tenantId = userDetails.tenantId;
        postObject.timeZone = props.isProfile ? props.timeZone : meetsTimeZoneList ? meetsTimeZoneList.timeZone : meetsData.meetsTrigger.timezone;
        postObject.timeZoneDescription = meetsTimeZoneList ? meetsTimeZoneList.timeZoneDescription : "";
        postObject.membersAllChecked = !allMemberLogViewPermission ? false : postObject.membersAllChecked;
        postObject.role = !selectedRole.value ? selectedRole.role : '';
        postObject.summaryType = selectedType.value;
        postObject.reportType = selectedReportType.value;
        postObject.customPrompt = customPrompt;
        if (postObject.timeZone == "User's local time zone") {
            postObject.timeZone = "Users LocalTimeZone";
        }
        await dispatch(fetchMeetsTimelineList(postObject));
       
        // else
        // {
        //     await dispatch(clearMeetUnReportedSummaryData([]));
        // }

        if (setCountLength && setCountLength > 0 && postObject.takeAfter == '0' && isSummary) {
            if ((chatGPTMeetRequestCount < chatGPTMeetRequestLimit || chatGPTMeetRequestLimit == 0) && (TenantRequestCount < TenantRequestLimit || TenantRequestLimit == 0)) {
                setCosolidateSummary(false);
                await dispatch(fetchMeetsTimelineSummary(postObject));
            }
            else {

                dispatch(clearMeetSummaryData([]));
                
                setCosolidateSummary(false);
            }
            let IsMeetUnderLimit = (chatGPTMeetRequestCount < chatGPTMeetRequestLimit || chatGPTMeetRequestLimit == 0);
            let IsTenantUnderLimit = (TenantRequestCount < TenantRequestLimit || TenantRequestLimit == 0);
            setMeetRequestLimitValidated(IsMeetUnderLimit);
            setRequestLimitValidated(IsTenantUnderLimit);

        }

        setIsLoading(false);
    }
    const showdata = () => {


        postObject.takeAfter = '0';
        if (isDateCountValidated || !isSummary)
            getMeetsLog();
        if (isUnReportedSummary) {
            dispatch(fetchMeetsUnReportedCounts(postObject));
        }
        else{
            dispatch(clearMeetUnReportedSummaryData([]));
        }
    }
    useEffect(() => {


        setPostObject((existingValues: any) => {
            let prePostObject = { ...existingValues };
            if (!props.isProfile) {
                prePostObject.prompts = formQuestionList();
                prePostObject.memberIds = formMembersList();
                prePostObject.meetsId = meetsId;
            }
            else {
                prePostObject.memberIds = [userDetails.userAdObjectId];
                prePostObject.prompts = [];
                prePostObject.meetsId = "";
                prePostObject.teamsId = props.teamsId;
                prePostObject.membersAllChecked = false;
            }
            if (userDetails.userAdObjectId && prePostObject.memberIds.length > 0 && (prePostObject.prompts.length > 0 || props.isProfile) && (prePostObject.meetsId || prePostObject.teamsId))
                setIsAllFilterSet(true);
            return prePostObject;
        })

    }, [memberList, promptList, meetsId, userDetails.userAdObjectId, objectiveList])

    const dateRangeFilter = async (dateRange: any) => {

        const { endDate, startDate } = dateRange;
        const datediff = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1;

        setDateCountValidated(datediff <= meetsSummaryDateCount);
        setPostObject((existingValues: any) => {

            let prePostObject = { ...existingValues };
            prePostObject.fromDate = format(startDate, "yyyy-MM-dd");
            prePostObject.toDate = format(endDate, "yyyy-MM-dd");

            return prePostObject;
        })
    }

    const getPromptsFilterValue = (event: any) => {
        const { checked, value } = event.target;
        let isPromptError = false;
        if (value === 'all') {
            if (checked)
                postObject.prompts = formQuestionList();
            else {
                setMeetTimeLine([])
                postObject.prompts = []
                isPromptError = true;
            }
        }
        else {
            if (checked)
                postObject.prompts.push(value);
            else {
                let index = postObject.prompts.indexOf(value);
                if (index != -1)
                    postObject.prompts.splice(index, 1);
                postObject.prompts.length == 0 ? isPromptError = true : isPromptError = false;
            }
        }

        postObject.prompts.length != promptList.length ? postObject.promptsAllChecked = false : postObject.promptsAllChecked = true;
        setError({
            ...error,
            IsPromptError: isPromptError
        })

        setPostObject(existingValues => ({
            ...existingValues,
            prompts: postObject.prompts,
            promptsAllChecked: postObject.promptsAllChecked
        }))

        if (postObject.prompts && postObject.prompts.length === 0) {
            dispatch(clearMeetTimelineData([]));
            dispatch(clearMeetSummaryData([]));
            dispatch(clearMeetUnReportedSummaryData([]));
            setCosolidateSummary(false);
        }
    }

    const getMemebersFilterValue = (event: any) => {
        const { checked, value } = event.target;
        let IsMemberError = false;
        if (value === 'all') {
            if (checked) {
                postObject.memberIds = formMembersList();
            }
            else {
                setMeetTimeLine([])
                postObject.memberIds = []
                IsMemberError = true;
            }
        }
        else {
            if (checked)
                postObject.memberIds.push(value);
            else {
                let index = postObject.memberIds.indexOf(value);
                if (index != -1)
                    postObject.memberIds.splice(index, 1);
                postObject.memberIds.length == 0 ? IsMemberError = true : IsMemberError = false;
            }
        }
        (postObject.memberIds.length != memberList.length || !allMemberLogViewPermission) ? postObject.membersAllChecked = false : postObject.membersAllChecked = true;
        setError({
            ...error,
            IsMemberError: IsMemberError
        })

        setPostObject(existingValues => ({
            ...existingValues,
            memberIds: postObject.memberIds,
            membersAllChecked: postObject.membersAllChecked
        }))

        if (postObject.memberIds && postObject.memberIds.length === 0) {
            dispatch(clearMeetTimelineData([]));
            dispatch(clearMeetSummaryData([]));
             dispatch(clearMeetUnReportedSummaryData([]));
            setCosolidateSummary(false);
        }

        setMemberCountValidated(postObject.memberIds.length <= meetsSummaryMemberCount);
    }

    const formMembersList = () => {

        let memberIds: string[] = [];
        let allMemberLogViewPermission = true;
        const loggedInMember = memberList.filter((member: any) => member.aadObjectId === userDetails.userAdObjectId);
        if (loggedInMember.length > 0 && !loggedInMember[0].meetsPermissions.report && !loggedInMember[0].meetsPermissions.manage) {
            allMemberLogViewPermission = false;
        }

        if (allMemberLogViewPermission) {
            setmeetsLogMembers(memberList);
            memberList.map((member: any) => {
                memberIds.push(member.aadObjectId)
            });

        }
        else {
            setmeetsLogMembers(loggedInMember);
            memberIds.push(userDetails.userAdObjectId);
        }
        setallMemberLogViewPermission(allMemberLogViewPermission);

        setMemberCountValidated(memberIds.length <= meetsSummaryMemberCount);
        return memberIds;
    }

    const formQuestionList = () => {
        let prompts: string[] = [];
        promptList.map((prompt: any) => {
            prompts.push(prompt.question)
        });
        return prompts;
    }

    const IsMemeberSelected = (memberID: any) => {

        const IsSelected = postObject.memberIds.filter((member: any) => member === memberID)[0];
        return IsSelected ? true : false;
    }

    const IsQuestionSelected = (questionName: string) => {
        const IsSelected = postObject.prompts.filter((prompt: any) => prompt === questionName)[0];
        return IsSelected ? true : false;
    }

    const fetchMoreData = async () => {
        postObject.takeAfter = (parseInt(postObject.takeAfter) + 10).toString();
        await getMeetsLog();
    }

    useEffect(() => {
        setHasMore(hasMoreTimelineData)
    }, [hasMoreTimelineData])
    useEffect(() => {
        setmeetsTimeZoneDetail(meetsTimeZoneDetail);
    }, [meetsTimeZoneDetail])

    useEffect(() => {
        return () => { // works on page leave
            dispatch(clearMeetTimelineData([]));
            dispatch(clearMeetSummaryData([]));
            dispatch(clearMeetUnReportedSummaryData([]));
            setCosolidateSummary(false);
        };
    }, []);
    const consolidateCheckedChange = async (checked: boolean) => {
        if (checked) {
            setIsConsolidateSummaryLoading(true)
            await dispatch(fetchMeetsTimelineConsolidateSummary(postObject));
            setCosolidateSummary(true);
            setIsConsolidateSummaryLoading(false)
            return;
        }
        setCosolidateSummary(false);
    }
    const summaryCheckedChange = async (checked: boolean) => {
        setSummary(checked);
    }
    const unReportedsummaryCheckedChange = async (checked: boolean) => {
        setUnReportedSummary(checked);
    }
    const customCheckedChange = async (checked: boolean) => {
        setCustom(checked);
    }
    const getObjectives = (event: any) => {
        const { checked, value } = event.target;
        if (checked)
            postObject.objectives.push(value);
        else {
            let index = postObject.objectives.indexOf(value);
            if (index != -1)
                postObject.objectives.splice(index, 1);

        }
        setPostObject(existingValues => ({
            ...existingValues,
            objectives: postObject.objectives
        }))
    }
    const types = [
        { value: 'Detailed', label: 'Detailed' },
        { value: 'Brief', label: 'Brief' }
    ];
    const reportTypes = [
        { value: 'BulletedList', label: 'Bulleted List' },
        { value: 'Paragraph', label: 'Paragraph' },
        { value: 'Datewise', label: 'Datewise' }

    ];

    const [selectedRole, setRole] = useState({ label: 'Select', value: '', role: '' });
    const [selectedType, setType] = useState({ label: 'Select', value: '' });
    const [selectedReportType, setReportType] = useState({ label: 'Bulleted List', value: 'BulletedList' });
    const handleSelectChange = (selectedOption: any) => {
        setRole(selectedOption);
    };
    const [customPrompt, setPrompt] = useState('');
    const updatePrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value);
    };
    const handleTypeChange = (selectedOption: any) => {
        setType(selectedOption);
    };
    const handleReportTypeChange = (selectedOption: any) => {
        setReportType(selectedOption);
    };

    let chartData = {
        options: {
            chart: {
                id: "basic-bar",
               
            },
            colors: colors,
            dataLabels: {
                enabled: true,

                style: {
                    fontSize: '13px',
                    colors: ["#fff"]
                }
            },
            xaxis: {
                categories:Object.keys(unReportedCounts).map(key => key.split(" ")[0]),
                labels: {
                    rotate: -45,
                    style:
                    {
                        fontFamily: 'system-ui',
                    }
                  }
               
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    distributed: true,
                    horizontal:false,
                 //   borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            responsive: [
                {
                  breakpoint: 500,
                  options: {
                    plotOptions: {
                      bar: {
                        horizontal: true
                      }
                    }
                  }
                },
                {
                 breakpoint: 1000,
                  options: {
                    plotOptions: {
                      bar: {
                        horizontal: true
                      }
                    }
                  }
                },
                {breakpoint: 1600,
                  options: {
                    plotOptions: {
                      bar: {
                        horizontal: false
                      }
                    }
                  }
                },
                {breakpoint: 2000,
                    options: {
                      plotOptions: {
                        bar: {
                          horizontal: false
                        }
                      }
                    }
                  },
              ]
        },
        series: [
            {
                name: 'Unreported Counts',
                data: Object.values(unReportedCounts) as number[],

            }
        ]
       
    };
    const hasUnreportedCounts = unReportedCounts &&
        typeof unReportedCounts === 'object' &&
        Object.values(unReportedCounts).some((count: any) => typeof count === 'number' && count > 0);

    return (
        <>
            <div className="container my-3" style={{ padding: '0px' }}>

                <div className="row timeline-heading minites-meeting-heading">

                    <h3 className="h3 fw-bold">Meets Log</h3>
                    <div className="col-lg-4 col-md-4 my-2">
                        <h3 className="h3" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Date Range<span style={{ fontSize: "14px" }}> - {props.isProfile ? props.timeZone : (meetsTimeZoneList ? meetsTimeZoneList.timeZone : meetsData.meetsTrigger.timezone)}</span></h3>
                        <DaterangePicker dateRange={dateRangeFilter} />
                    </div>
                    {!props.isProfile &&
                        <>

                            <div className="col-lg-4 col-md-4 my-2">
                                <h3 className="h3">Members</h3>
                                <div className="position-relative time-line">
                                    <button type="button" className={"form-control form-control-lg focus-prompt position-relative text-start " + (error.IsMemberError ? 'btn_required_validation' : '')}> <span>Members</span>
                                        <ul className="dropdown-menu border-white shadow timeline-prompts mt-2 timeline-height" aria-labelledby="dropdownMenuButton1">
                                            <li className="mt-1 d-flex align-items-center">
                                                <label htmlFor="click">All Members</label>
                                                <div className="ms-auto d-flex align-items-center">
                                                    <input checked={postObject.memberIds.length === meetsLogMembers.length} type="checkbox" id="click"
                                                        onChange={(event) => getMemebersFilterValue(event)} value="all" className="form-check-input mt-0" />
                                                </div>
                                            </li>
                                            {meetsLogMembers.map((members: any, index: any) =>
                                                <li key={"members" + index} className="mt-1 d-flex align-items-center">
                                                    <label htmlFor={`Member${index}`}>{members.name}</label>
                                                    <div key={"member" + index} className="ms-auto d-flex align-items-center">
                                                        <input type="checkbox" checked={IsMemeberSelected(members.aadObjectId)} id={`Member${index}`}
                                                            value={members.aadObjectId} className="form-check-input mt-0" onChange={(event) => getMemebersFilterValue(event)} />
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </button>
                                    {error.IsMemberError && <ErrorMessage validationType='required' fieldName="Members" />}
                                    {!isMemberCountValidated && isSummary && <span className="error_message" >Count of members should not exceed 10 while showing the summary</span>}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 my-2">

                                <h3 className="h3 d-flex align-items-center">Prompts</h3>
                                <div className="position-relative time-line">
                                    <button type="button" className={"form-control form-control-lg focus-prompt position-relative text-start " + (error.IsPromptError ? 'btn_required_validation' : '')}> <span>Prompts</span>
                                        <ul className="dropdown-menu border-white shadow timeline-prompts mt-2 timeline-height" aria-labelledby="dropdownMenuButton1">
                                            <li className="mt-1 d-flex align-items-center"><label htmlFor="clicked">All</label>
                                                <div className="ms-auto d-flex align-items-center">
                                                    <input type="checkbox" checked={postObject.prompts.length === promptList.length} id="clicked" className="form-check-input mt-0" value="all" onChange={(event) => getPromptsFilterValue(event)} /></div>
                                            </li>
                                            {
                                                promptList.map((prompts: any, index: any) =>
                                                    <li key={"questions" + index} className="mt-1 d-flex align-items-center">
                                                        <label htmlFor={`Question${index}`}>{prompts.question}</label>
                                                        <div key={"question" + index} className="ms-auto d-flex align-items-center">
                                                            <input type="checkbox" checked={IsQuestionSelected(prompts.question)} id={`Question${index}`}
                                                                value={prompts.question}
                                                                className="form-check-input mt-0" onChange={(event) => getPromptsFilterValue(event)} />
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </button>
                                    {error.IsPromptError && <ErrorMessage validationType='required' fieldName="Prompts" />}
                                </div>
                            </div>
                        </>
                    }
                </div>
                {!isDateCountValidated && isSummary && <span className="error_message" >Date Range should not exceed 10 while showing the summary</span>}
                <div className="user-based-prompts-toggle">
                    <div className="d-flex align-items-center">
                        <h3 className="h3 ms-2 mt-2 fw-bolder">Summary</h3>
                        <div className="ms-auto">
                            <input type="checkbox" className='checkbox-ms-custom' id="isSummary" checked={isSummary} style={{ display: 'none' }}

                                onChange={(event) => summaryCheckedChange(event.currentTarget.checked)} />
                            <label htmlFor="isSummary" className="toggle"><span></span></label>
                        </div>
                    </div>
                </div>
                <div className="user-based-prompts-toggle">
                    <div className="d-flex align-items-center">
                        <h3 className="h3 ms-2 mt-2 fw-bolder">Unreported Summary</h3>
                        <div className="ms-auto">
                            <input type="checkbox" className='checkbox-ms-custom' id="isUnReportedSummary" checked={isUnReportedSummary} style={{ display: 'none' }}

                                onChange={(event) => unReportedsummaryCheckedChange(event.currentTarget.checked)} />
                            <label htmlFor="isUnReportedSummary" className="toggle"><span></span></label>
                        </div>
                    </div>
                </div>
                {!isMeetRequestLimitValidate && isSummary && <span className="error_message" >The current meet reached the chat GPT quota limit.({chatGPTMeetRequestLimit})</span>}
                {!isRequestLimitValidate && isSummary && <span className="error_message" >You have reached the chat GPT quota limit.({chatGPTTenantRequestLimit})</span>}
                {isSummary &&
                    <div>
                        {!isCustom &&
                            <div className="row timeline-heading custom-header-panel" >

                                <div className="col-lg-3 col-md-6 my-2">
                                    <h3 className="h3" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Role</h3>
                                    <Select
                                        options={RoleList}
                                        placeholder="Select a role"
                                        className="form-select-lg px-0 add-member-select-color"
                                        classNamePrefix="add-members-select"
                                        onChange={handleSelectChange}
                                        getOptionLabel={(option: any) => `${option.role}`}
                                        getOptionValue={(option: any) => `${option.role}`}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 my-2">
                                    <h3 className="h3">Objectives</h3>
                                    <div className="position-relative time-line objectives-panel">
                                        <button type="button" className={"form-control form-control-lg focus-prompt position-relative text-start " + (error.IsMemberError ? 'btn_required_validation' : '')}> <span>Objectives</span>
                                            <ul className="dropdown-menu border-white shadow timeline-prompts mt-2 timeline-height" aria-labelledby="dropdownMenuButton1">
                                                {
                                                    objectiveList && objectiveList.map((objectives: any, index: any) =>
                                                        <li key={"objective" + index} className="mt-1 d-flex align-items-center">
                                                            <label htmlFor={`Questions` + { index }}>{objectives.objective}</label>
                                                            <div key={"objective" + { index }} className="ms-auto d-flex align-items-center">
                                                                <input type="checkbox" id={`objective` + { index }}
                                                                    value={objectives.objective} className="form-check-input mt-0" onChange={(event) => getObjectives(event)} />
                                                            </div>
                                                        </li>
                                                    )}
                                            </ul>
                                        </button>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 my-2">

                                    <h3 className="h3 d-flex align-items-center">Summary Length</h3>
                                    <Select value={selectedType}
                                        onChange={handleTypeChange}
                                        options={types}
                                        placeholder="Select a type"
                                        className="form-select-lg px-0 add-member-select-color"
                                        classNamePrefix="add-members-select"
                                    ></Select>
                                </div>
                                <div className="col-lg-3 col-md-6 my-2">
                                    <h3 className="h3" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Summary Group</h3>
                                    <Select
                                        value={selectedReportType}
                                        onChange={handleReportTypeChange}
                                        options={reportTypes}
                                        placeholder="Select a Report Type"
                                        className="form-select-lg px-0 add-member-select-color"
                                        classNamePrefix="add-members-select"
                                    />
                                </div>
                            </div>
                        }
                        <div className="user-based-prompts-toggle">
                            <div className="d-flex align-items-center">
                                <h3 className="h3 ms-2 mt-2 fw-bolder">Custom Message</h3>
                                <div className="ms-auto">
                                    <input type="checkbox" className='checkbox-ms-custom' id="isCustom" checked={isCustom} style={{ display: 'none' }}
                                        onChange={(event) => customCheckedChange(event.currentTarget.checked)} />
                                    <label htmlFor="isCustom" className="toggle"><span></span></label>
                                </div>
                            </div>
                        </div>
                        {isCustom &&
                            <div className="row timeline-heading">
                                <div className="col-lg-12 col-md-12 my-2">
                                    <textarea className="form-control form-control-lg"
                                        onChange={(event: any) => updatePrompt(event)}></textarea>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="ms-auto my-auto">
                    <button className="float-end fw-bold newstandup-button shadow" onClick={showdata}
                    >Show</button>
                </div>
                <InfiniteScroll
                    dataLength={count}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={false}
                    scrollThreshold='200px'
                >
                    {IsLoading && <MeetsTimeLineSkeletonLoader />}
                    {
                        !IsLoading &&
                        <div>
                            {isUnReportedSummary && hasUnreportedCounts &&
                                <div className="container my-3 chartdiv">                                   
                                    <div>

                                        <div  id="html-dist"><Chart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="bar"
                                            width="100%"
                                            height="400" 
                                        /></div>
                                    </div>
                                </div>
                            }
                            {meetsTimeSummary && meetsTimeSummary.length > 0 &&
                                <div>


                                    <div className="col-12"><h5 className="h5 ms-2 mt-2 fw-bolder">Meets Log- Summary View</h5></div>
                                    {meetsTimeSummary.map((item: any, index: any) =>
                                        <div key={'Summary' + index}>
                                            <div className="p-2">
                                                <h4 className="h5">{item.name}</h4>
                                                {/* <h5>Chat Summary</h5> */}
                                                {/* <p>{item.chatSummary}</p> */}
                                                <div className="chatsummarylist" dangerouslySetInnerHTML={{ __html: item.chatSummary }}></div>
                                            </div>
                                        </div>)
                                    }
                                    <div className="user-based-prompts-toggle">
                                        <div className="d-flex align-items-center">
                                            <h5 className="h5 ms-2 mt-2 fw-bolder">Meets Log Consolidate View</h5>
                                            <div className="ms-auto">
                                                <input type="checkbox" className='checkbox-ms-custom' id="isConsolidateSummary" style={{ display: "none" }} checked={isConsolidateSummary}
                                                    disabled={IsConsolidateSummaryLoading}
                                                    onChange={(event) => consolidateCheckedChange(event.currentTarget.checked)} />
                                                <label htmlFor="isConsolidateSummary" className="toggle"><span></span></label>
                                            </div>
                                        </div>
                                    </div>
                                    {IsConsolidateSummaryLoading && <MeetsTimeLineSkeletonLoader />}
                                    {isConsolidateSummary &&
                                        <div className="p-2">
                                            <div dangerouslySetInnerHTML={{ __html: meetsTimeConsolidateSummary }}></div>
                                        </div>
                                    }
                                </div>
                            }
                            {meetTimeLine && meetTimeLine.length > 0 &&

                                <div className="col-12">
                                    <h5 className="h5 ms-2 mt-2 fw-bolder">Meets Log- Detailed View</h5>
                                </div>
                            }

                            {meetTimeLine.map((TimeLine: any, timelineIndex: any) =>
                                <div className="row mt-4 mx-0" key={'meetTimeLine' + timelineIndex}>
                                    <div className="col-12">
                                        <div className="p-2">
                                            <h5 className="h5 text-center">{TimeLine.meetDate}</h5>
                                            <div className="row time-left-border">
                                                {
                                                    //User Loop
                                                    TimeLine.meetList.map((list: any, meetsIndex: any) =>
                                                        <div className="col-lg-6 col-md-6 px-3 mt-2" key={'meetsList' + meetsIndex}>
                                                            <div className="d-flex align-items-center mt-2 timeline-profile">
                                                                <div className="d-flex">
                                                                    <div className="teamMember-curve">{list.name.slice(0, 1)}</div>
                                                                    <div className="ms-2 py-1">
                                                                        <h6 className="h6 ms-2 mt-2 fw-bolder">{list.name}</h6>
                                                                        <p className="p my-0 ms-2">{list.reportTime}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">

                                                                {   //Question loop
                                                                    list.report.map((question: any, reportIndex: any) =>

                                                                        <div className="time-line-left mt-3" key={'report' + reportIndex}>
                                                                            <div className="time-line-border">
                                                                                <h6 className="h6 fw-bolder">{question.prompt}</h6>
                                                                                <div className="p mt-2 mb-0">
                                                                                    <div dangerouslySetInnerHTML={{ __html: question.answer }}></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>)}
                        </div>
                    }

                </InfiniteScroll>
                {
                    (!IsLoading && meetTimeLine && meetTimeLine.length === 0 && !isUnReportedSummary) &&
                    <div className="container" style={{ textAlign: "center", padding: "20px" }}>
                        <img src={require('../../Assets/Images/norecords.png')} width="100%" alt="UserIcon" />

                    </div>
                }
            </div>
        </>
    )
}

export default MeetsLog; 