 import { useSelector, useDispatch } from "react-redux";
import { updateMeetsData } from "../../Store/manageSlice";
import { requiredFields } from "../../Types/MeetsType";
import ErrorMessage from "./ErrorMessage";
import { findHelpText } from "../../Utils/CommonUtils"
import { updateBlockerData } from "../../Store/commonSlice";
import Select from 'react-select'
import { Controller } from "react-hook-form";


function MeetsChannels(props: requiredFields) {

  const { errors, control } = props;
  const dispatch = useDispatch<any>();

  const channelList = useSelector(
    (state: any) => state.manageReducer.channelList
  );

  const updateMeetsChannnel = (selectedChannelList : any) => {
    dispatch(updateMeetsData({ name: "channels", value: selectedChannelList }));
    dispatch(updateBlockerData({ isModified: true }));
  };

  const meetsData = useSelector(
    (state: any) => state.manageReducer.meetsData
  );

  const isCheckSelectedChannels = () => {
    return meetsData.channels.length === 0 ? false : true;
  }

  return (
    <div className="col-lg-6">
      <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Channels<div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("channel")}>i</span></div></h2>
      <div className={"position-relative multi-select-dropdown" + (errors.channelName ? 'active_validation' : '')}>
     
      <Controller
          name="channelName"
          control={control}
          rules={{
            validate: {
              isChannels: () => isCheckSelectedChannels(),
            }
          }}
          render={({
            field: { name },
            formState: { errors }
          }) => (
            <>
            <Select
          defaultValue={meetsData.channels}
          isMulti
          name="channels"
          options={channelList}
          getOptionLabel={x => x.channelName}
          getOptionValue={x => x.channelId}
          className="form-select-lg px-0 add-member-select-color"
          classNamePrefix="select"
          onChange={updateMeetsChannnel}
        />
              {errors && errors[name] && errors[name]?.type === "isChannels" && meetsData.channels.length === 0 && (
                <ErrorMessage validationType="required" fieldName="Channel"
                />
              )}
            </>
          )}
        />
        
        
      </div>
    </div>
  );
}

export default MeetsChannels;


