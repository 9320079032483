import React from 'react';

function MeetsTimeLineSkeletonLoader() {
    return (
        <div>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="skeleton skeleton-Card">
                            <div><h5 className="skeleton p-3 mx-auto" style={{ width: '200px' }}></h5></div>
                            <div className="row">
                                <div className="col-lg-6 px-3 mt-2">
                                    <div style={{
                                        display: 'flex', border: '1px solid #dddbddb9', borderRadius: '5px',
                                        padding: '10px', marginBottom: '10px'
                                    }}>
                                        <div className="p-4 skeleton"></div>
                                        <div style={{ width: '100%', marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                            <div className="p-2 mb-3 skeleton"></div>
                                            <div className="p-2 skeleton" style={{ width: '25%' }}></div>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 px-3 mt-2">
                                    <div style={{
                                        display: 'flex', border: '1px solid #dddbddb9', borderRadius: '5px',
                                        padding: '10px', marginBottom: '10px'
                                    }}>
                                        <div className="p-4 skeleton"></div>
                                        <div style={{ width: '100%', marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                            <div className="p-2 mb-3 skeleton"></div>
                                            <div className="p-2 skeleton" style={{ width: '25%' }}></div>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                    <div style={{ border: '1px solid #dddbddb9', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                                        <div className="p-2 mb-3 skeleton rounded-3" style={{ width: '75%' }}></div>
                                        <div className="p-2 skeleton rounded-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MeetsTimeLineSkeletonLoader;