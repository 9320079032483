import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ErrorMessage from './ErrorMessage';
import { schedulePeriods, timeZone, requiredFields } from '../../Types/MeetsType';
import { meetPeriod, weekList } from '../../Constant/ApplicationConstant';
import DatePicker from 'react-date-picker';
import { updateMeetData, updateMeetDays } from '../../Store/manageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { hoursList, minutesList } from "../../Constant/ApplicationConstant";
import { formatDate, findHelpText, timeConversion } from '../../Utils/CommonUtils';
import { updateBlockerData } from '../../Store/commonSlice';
import { DatePickerAdditionalProps } from '../../Types/MeetsType';
import {
  fetchHolidayTemplateByTeamsId,
  setHolidaysFromMeets,
  clearHolidayTemplateData
} from "../../Store/holidayTemplateSlice";
import Holidays from '../../Components/Templates/holidays';
import CustomOffcanvas from '../CustomOffcanvas';
import HolidayTemplateDetails from '../../Pages/Templates/HolidayTemplateDetails';


function MeetsTriggerTime(props: any) {
  const dispatch = useDispatch<any>();
  const { register, errors, control, resetField, reset, holidaysCheckeUpdate } = props;
  const currentDay = weekList[new Date().getDay()];
  const [dayName, setDayName] = useState(currentDay.day);
  const [isHolidayChecked, setisHolidayChecked] = useState(false);
  const [showHoliday, setShowHoliday] = useState(false);
  const [templateId, setTemplateId] = useState(null); 
  const { meetsId } = useParams<"meetsId">();
  const [show, setShow] = useState(false);

  const meetsData = useSelector(
    (state: any) => state.manageReducer.meetsData
  );

  const meetsTrigger = useSelector(
    (state: any) => state.manageReducer.meetsData.meetsTrigger
  )

  const teamsList = useSelector(
    (state: any) => state.meetsReducer.teamsListData
  );

  const teamsHolidayTemplateList = useSelector(
    (state: any) => state.holidayTemplateReducer.teamsHolidayTemplateList
  );

  useEffect(()  => {
    (async () => {
      if (meetsData.holidays.length > 0) {
        if (!teamsHolidayTemplateList) {
          const teamsId = teamsList.map((item: any) => item.teamsId).join(",");
          await dispatch(fetchHolidayTemplateByTeamsId(teamsId));
        }
        
        let holidays = (JSON.parse(JSON.stringify(meetsData.holidays)));
        updateHolidayTemplateMonthAndDate(holidays);
        dispatch(setHolidaysFromMeets({ holidays: holidays }));
        setisHolidayChecked(true);
        holidaysCheckeUpdate(true);
        setShowHoliday(true);
      }
    })();

}, [meetsData.holidays]);


  const updateHours = async (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
    let meridian = "";
    let meetsTriggerTime = timeConversion(meetsTrigger.time);
    let selectedValue = meetsTriggerTime.split(' ')[0];
    let time = "";
    if (option !== "meridian") {
      time = option === "hours" ? event.target.value + ":" + selectedValue.split(':')[1] : selectedValue.split(':')[0] + ":" + event.target.value;
      meridian = " " + meetsTriggerTime.split(' ')[1]
    }
    else {
      time = selectedValue;
      meridian = " " + event.target.value;
    }
    await convertTime12to24(time + meridian);
    dispatch(updateBlockerData({ isModified: true }))
  }

  const convertTime12to24 = async (data: string) => {
    const [time, modifier] = data.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = (parseInt(hours, 10) + 12).toString();

    }
    hours = hours.length > 1 ? hours : "0" + hours;
    minutes = minutes.length > 1 ? minutes : "0" + minutes;
    await dispatch(updateMeetData({ value: `${hours}:${minutes}`, name: "time" }));
  };

  let hours = "";
  let minutes = "";
  let meridians = "";
  const getTriggerTime = () => {
    const time = timeConversion(meetsTrigger.time);
    let splithours = time.split(' ');
    let data = splithours[0].split(':');
    hours = data[0].length > 1 ? data[0] : "0" + data[0];
    minutes = data[1].length > 1 ? data[1] : "0" + data[1];
    meridians = splithours[1];
  }

  if (meetsTrigger && meetsTrigger.time) {
    getTriggerTime();
  }

  const timeZoneList = useSelector(
    (state: any) => state.manageReducer.timeZoneList
  )

  useEffect(() => {
    reset({
      period: meetsTrigger.period,
      days: meetsTrigger.days,
      meetDate: meetsTrigger.meetDate,
      timezone: meetsTrigger.timezone,
      time: meetsTrigger.time
    }, {
      keepErrors: true,
      keepDirty: true,
    });
  }, [reset, timeZoneList, meetsTrigger]);

  const changeMeetData = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = event.target;
    if (name === 'period')
      value === 'weekly' ? resetField('days') : resetField('meetDate');
    dispatch(updateMeetData({ value: value, name: name }));
    dispatch(updateBlockerData({ isModified: true }))
  }

  const changeMeetDays = (event: any) => {
    dispatch(updateMeetDays({ value: event.target.value, name: event.target.name, checked: event.target.checked }))
    dispatch(updateBlockerData({ isModified: true }))
  }

  const changeMeetDateTime = (dateTime: any, fieldName: string) => {
    dispatch(updateMeetData({ value: dateTime, name: fieldName }));
    dispatch(updateBlockerData({ isModified: true }))
  }

  const IsSelectedDay = (day: string) => {
    const IsSelected = meetsTrigger.days.filter((dayName: string) => dayName === day)[0];
    return IsSelected ? true : false;
  }

  const CustomDatePicker = (props: any & DatePickerAdditionalProps) => {
    const newProps = { ...props };
    return <DatePicker {...newProps} />;
  }

  const holidaysCheckChange = async (checked : boolean) => {
    if(checked){
        const teamsId = teamsList.map((item: any) => item.teamsId).join(",");
        await dispatch(fetchHolidayTemplateByTeamsId(teamsId));
    }
    setisHolidayChecked(checked);
    holidaysCheckeUpdate(checked);
    dispatch(updateBlockerData({ isModified: true }));
}

const updateHolidayTemplate = async (event: React.ChangeEvent<HTMLInputElement>) => {
  updateTemplateByTemplateId(event.target.value);
};

  const updateTemplateByTemplateId = async (templateId: string) => {
    setShowHoliday(false);
    dispatch(clearHolidayTemplateData());
    let template = teamsHolidayTemplateList.find((holidayTemplate: any) => holidayTemplate.templateId === templateId);
    let holidays = (JSON.parse(JSON.stringify(template.holidays)));
    await updateHolidayTemplateMonthAndDate(holidays);
    dispatch(setHolidaysFromMeets({ holidays: holidays, templateId: template.templateId }));
    dispatch(updateBlockerData({ isModified: true }));
    setShowHoliday(true);
  }

  const updateHolidayTemplateMonthAndDate = async (holidays: any) =>{
    holidays.forEach((element: any, index: number) => {
        element.holidayIndex = index + 1;
        const [month, date] = element.date.split("/");
        element.holidayMonth = month;
        element.holidayDate = date;
      });
  }

  const handleOverlayClose = (payload:any) => {
    setShow(false);
    console.log(payload)
    if(payload){
      holidaysCheckChange(true);
      setTemplateId(templateId);
     // updateTemplateByTemplateId(payload);
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mb-3">
      <h1 className="h1 mt-2 mb-2 d-flex align-items-center">Trigger Time <div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("periodAndDays")}>i</span></div></h1>

      <div className="d-flex row mb-3">

        <div className={"col-lg-7 " + (errors.period ? 'active_validation' : '')}>
          <h2 className="h2 mt-4 mb-2">Period</h2>
          <select className="form-select form-select-lg" id="period" name="period"
            {...register('period', {
              required: true,
              onChange: changeMeetData,
              value: meetsTrigger.period
            })}
            defaultValue={meetsTrigger.period}>
            <option value="">Select Period</option>
            {meetPeriod.map((period: schedulePeriods, index: any) => <option className=" form-control"
              key={period.name} value={period.value}>{period.name}</option>)}
          </select>
          {errors.period && <ErrorMessage validationType={errors.period.type} fieldName="Period" />}
        </div>

        {meetsTrigger.period.toLowerCase() === 'weekly' &&
          <div className="col-lg-5">
            <h2 className="h2 mt-4 mb-2">Day</h2>
            <div className="d-flex justify-content-between">
              {
                weekList.map((week: any) =>
                  <div key={week.value} className={dayName === week.day ? 'today-highlight position-relative' : ''} >
                    <div className="daylist action schedule-week" >
                      <label>
                        <input type="checkbox" {...register('days', {
                          required: true
                        })} defaultChecked={IsSelectedDay(week.value)} name="days" value={week.value} onClick={changeMeetDays} /><span>{week.value}</span>
                      </label>
                    </div>
                    {
                      dayName === week.day && <span className="fw-bold p span-today" style={{ display: "block" }}>Today</span>
                    }
                  </div>
                )
              }
            </div>
            {errors.days && <ErrorMessage validationType={errors.days.type} fieldName="Day" />}
          </div>
        }

        {meetsTrigger.period.toLowerCase() === 'one time' &&
          <div className="col-lg-5">
            <h2 className="h2 mt-4 mb-2">Pick Date</h2>
            <div className={"date-picker-panel " + (errors.meetDate ? 'active_validation' : '')}>
              <Controller
                control={control}
                name="meetDate"
                rules={{ required: true }}
                defaultValue={meetsTrigger.meetDate}
                render={({
                  field: { onChange, name, value },
                  formState: { errors }
                }) => (
                  <>
                    <p className='date-picker-placeholder'>{meetsTrigger.meetDate}</p>
                    <CustomDatePicker value={typeof value === "string" ? new Date(value) : value}
                      format='y/MM/dd'
                      minDate={new Date()}
                      clearIcon={null}
                      onChange={(date: any) => {
                        onChange(date);
                        changeMeetDateTime(formatDate(date), 'meetDate');
                      }}
                      className="form-control form-control-lg" />
                    {errors && errors[name] && errors[name]?.type === "required" && (
                      <span className="error_message">Date is Required</span>
                    )}

                  </>
                )}
              />
            </div>
          </div>

        }
      </div>

      <div className="d-flex row mt-4">

        <div className="col-lg-7 mt-2">
          <h2 className="h2 mb-2">Time</h2>
          <div className="d-flex">
            <div className="w-100 me-3">
              <select className="form-select form-select-lg" id="hours" name="hours"
                {...register('hours', {
                  required: true,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => updateHours(event, "hours")
                })} defaultValue={hours} >
                {hoursList && hoursList.map((data: any) =>

                  <option className=" form-control"
                    key={data.value} value={data.value}>{data.value}</option>)}
              </select>
              {errors.hours && <ErrorMessage validationType={errors.hours.type} fieldName="hours" />}
            </div>
            <div className="w-100 me-3">
              <select className="form-select form-select-lg" id="minutes" name="minutes"
                {...register('minutes', {
                  required: true,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => updateHours(event, "minutes")
                })} defaultValue={minutes} >
                {minutesList && minutesList.map((data: any) => <option className=" form-control"
                  key={data.value} value={data.value}>{data.value}</option>)}
              </select>
              {errors.minutes && <ErrorMessage validationType={errors.minutes.type} fieldName="minutes" />}
            </div>
            <div className="w-100">
              <select className="form-select form-select-lg" id="meridian" name="meridians"
                {...register('meridians', {
                  required: true,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => updateHours(event, "meridian")
                })} defaultValue={meridians} >
                <option className=" form-control"
                  key="AM" >AM</option>
                <option className=" form-control"
                  key="PM" >PM</option>
              </select>
              {errors.meridians && <ErrorMessage validationType={errors.meridians.type} fieldName="meridians" />}
            </div>
          </div>
        </div>

        <div className="col-lg-5 mt-2">
          <h2 className="h2 mb-2">Time Zone</h2>
          <div className={" " + (errors.timezone ? 'active_validation' : '')}>
            <select className="form-select form-select-lg" name="timezone" id="timezone"
              {...register('timezone', {
                required: true,
                onChange: changeMeetData,
                value: meetsTrigger.timezone
              })}
              defaultValue={meetsTrigger.timezone}>
              <option key='0' value="">Select Timezone</option>
              {
                timeZoneList && timeZoneList.length > 0 && timeZoneList.map((timezone: timeZone) => <option key={timezone.id} value={timezone.value} >{timezone.text}</option>)
              }
            </select>
          </div>
          {errors.timezone && <ErrorMessage validationType={errors.timezone.type} fieldName="Time Zone" />}
        </div>

      </div>
      <div className="user-based-prompts-toggle" style={{ margin: '15px 6px' }}>
        <div className="d-flex align-items-center">

        <h1 className="h1 mt-2 mb-2 d-flex align-items-center">Holidays 
        <div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("holidayTemplates")}>i</span>
        </div>
        </h1>

          <div className="ms-auto d-flex gap-2"> 
            <div>
              <input type="checkbox" className='checkbox-ms-custom' id="isHolidayChecked" style={{ display: "none" }} checked={isHolidayChecked}
                onChange={(event) => holidaysCheckChange(event.currentTarget.checked)} />
              <label htmlFor="isHolidayChecked" className="toggle"><span></span></label>
            </div>
          </div>

        </div>
        {isHolidayChecked && 
          <div className="d-flex  justify-content-end">
              <a className='hyper_link' onClick={handleShow}>+ Add New Holiday Templates</a>
          </div>
        }
      </div>

      {isHolidayChecked &&
        <div>
          {(!meetsId || meetsData.holidays.length === 0) && teamsHolidayTemplateList.length > 0 &&
            <div className={"position-relative mt-3"}>
              <select className="form-select form-select-lg" id="holidayTemplate"
                {...register('holidayTemplate', {
                  onChange: updateHolidayTemplate,
                })}
              >
                <option value="">Select Holiday Template</option>
                {teamsHolidayTemplateList && teamsHolidayTemplateList.map((data: any) => <option className=" form-control"
                  key={data.templateName} value={data.templateId}>{data.templateName}</option>)}
              </select>
            </div>
          } 

          {showHoliday &&
            <Holidays register={register}
              errors={errors} />
          }

        </div>
      }
      {isHolidayChecked && teamsHolidayTemplateList.length === 0 && !showHoliday &&
        <p>You don't have any holiday templates.</p>
      }

      <CustomOffcanvas show={show} handleClose={handleClose} title="Template">
        <HolidayTemplateDetails isOverlay={true} onOverlayClose={handleOverlayClose}/>
      </CustomOffcanvas>

    </div>

    
  );
}

export default MeetsTriggerTime;