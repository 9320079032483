import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
  auth: {
    clientId: (process.env.REACT_APP_API_CLIENT_ID as string),
    authority: (process.env.REACT_APP_AAD_AUTHORITY as string),
    redirectUri: (process.env.REACT_APP_BASE_URL as string),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [process.env.REACT_APP_AAD_API_SCOPE as string],
};