import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getInitials } from "../../Utils/CommonUtils";
import { timeZone, userProfile } from '../../Types/MeetsType';
import { fetchTimeZoneList } from "../../Store/manageSlice";
import { fetchUserData, updateUserData, updateuserTimelineData, updateuserTimeZone } from "../../Store/profileSlice";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Components/Meets/ErrorMessage";
import MeetsLog from '../../Components/Meets/MeetsLog';
import swal from "sweetalert";
import MinutesOfMeetsSkeleton from '../../Components/SkeletonLoader/MinutesOfMeetsSkeleton';
import { showAndHidePageLoader } from "../../Store/manageSlice";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from '../../Components/Layout/Shared/Breadcrumbs';


let postObj: userProfile = {
    memberName: '',
    email: '',
    aadObjectId: '',
    timeZone: ''
}

function ProfileComponent() {
    const dispatch = useDispatch<any>();

    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    }

    const loggedInUser = useSelector(
        (state: any) => state.authReducer.userDetails
    );

    const userTimeZone = useSelector(
        (state: any) => state.profileReducer.meetsLogFilterTimeZone
    );

    const timeZoneList = useSelector(
        (state: any) => state.manageReducer.timeZoneList
    );

    const userData = useSelector(
        (state: any) => state.profileReducer.userData
    );

    const pageLoader = useSelector(
        (state: any) => state.manageReducer.pageLoader
    );

    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );


    useEffect(() => {
        (async () => {
            if (loggedInUser.userAdObjectId) {
                dispatch(showAndHidePageLoader({ value: true }));
                await dispatch(fetchUserData(loggedInUser.userAdObjectId))
                if (timeZoneList && timeZoneList.length === 0) {
                    await dispatch(fetchTimeZoneList());
                }
            }
            dispatch(showAndHidePageLoader({ value: false }));
        })();
    }, [loggedInUser]);

    const onSubmit = async () => {
        (async () => {
            dispatch(showAndHidePageLoader({ value: true }));
            postObj.email = loggedInUser.email;
            postObj.memberName = loggedInUser.name;
            postObj.aadObjectId = loggedInUser.userAdObjectId;
            postObj.timeZone = userData.timeZone;
            await dispatch(updateUserData(postObj));
            await swal({ title: "Success", text: `The profile updated successfully`, icon: "success", timer: 3000, buttons: [false] })
                .then((value) => {
                    dispatch(updateuserTimeZone(userData.timeZone));
                    dispatch(showAndHidePageLoader({ value: false }));
                });
        })();
    }

    const onError = (errors: any, event: any) => {
        console.log(errors, event);
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm(
        {
            mode: "all",
        }
    );

    const updateTimezone = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event);
        dispatch(updateuserTimelineData(event.target.value));
    }

    return (
        <div className="container-fluid px-0">
            <div className="container-fluid bg-light px-0">
                <div className="container py-4">
                    <div className="d-flex meeting-panel">
                        <div className="">
                            <h1 className="h1 my-0 fw-bold">My Profile</h1>
                            <Breadcrumbs currentPage="My Profile"  hideTeams={true}/>
                        </div>
                        <div className="ms-auto my-auto minutes-button-back">
                            <button className="float-end fw-bold newstandup-button shadow" onClick={() => routeChange("/")}>Back To Home</button>
                        </div>
                    </div>
                </div>
            </div>
            {pageLoader && <MinutesOfMeetsSkeleton />}
            {(!pageLoader && userData) &&
                <div>
                    <div className="container mt-4" >
                        <div className="row">
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="col-lg-12 position-relative">
                                    <div className="position-relative" style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                                        <div className="minutes-card-main">
                                            <div className="minutes-card-main-header" style={{ background: "#F2F2F2", height: "115px", borderRadius: "12px 12px 0px 0px" }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center p-3 ms-4">
                                                        <div className="shadow border mt-1 minutes-name-profile" style={{ width: "120px", height: "120px", background: "#fff", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <div className="minute-card" style={{ width: "105px", height: "105px", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <span style={{ fontSize: "35px", fontWeight: "bolder", color: "#fff" }}>
                                                                    {getInitials(loggedInUser.name)}
                                                                </span></div>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h4 className="h4 lh-sm fw-bolder mb-0 minutesmeets-name">{loggedInUser.name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto me-3">
                                                        <button type='submit' className="newstandup-button mb-4 mt-3 mx-0 fw-bolder">
                                                            <span>Save</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="py-4 w-50 px-4 timezone-panel">
                                                <h2 className="h2 mb-2">Time Zone</h2>
                                                {timeZoneList && timeZoneList.length > 0 && <div className={"" + (errors.userTimezone ? 'active_validation' : '')}>
                                                    <select className="form-select form-select-lg"
                                                        {...register('userTimezone', {
                                                            required: true,
                                                            onChange: updateTimezone,
                                                            value: userData.timeZone
                                                        })}
                                                        defaultValue={userData.timeZone}
                                                        name="userTimezone">
                                                        <option key='0' value="">Select Timezone</option>
                                                        {
                                                            timeZoneList && timeZoneList.length > 0 && timeZoneList.slice(1).map((timezone: timeZone, index: number) =>
                                                                <option key={timezone.id + 'timeZoneList'} value={timezone.value}>
                                                                    {timezone.text}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                }
                                                {errors?.userTimezone && <ErrorMessage validationType="required" fieldName="Time Zone" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                    {userTimeZone && defaultTeamsId &&
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                                        <div className="row mt-3 mx-0 minites-meeting-heading">
                                            <div className="row timeline-heading mx-0 mt-3 px-3">
                                                <MeetsLog isProfile={true} teamsId={defaultTeamsId} timeZone={userTimeZone} key={userTimeZone} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    );
}

export default ProfileComponent;