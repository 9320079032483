import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { meetsList, meetsUpdate } from '../Types/MeetsType';
import { getMeetsList, deleteMeeting, pauseMeeting, getTeamList,checkManagePermission } from "../Services/Api/meetsService";
import { trackException } from '../Utils/TrackExceptionUtility';
import { sortRecords } from '../Utils/CommonUtils';

const initialState: meetsList = {
  meetsListData: [],
  pageLoader: false
};
export const fetchTeams = createAsyncThunk(
  "meetsList/fetchTeams",
  async (args: any, thunkAPI) => {
    try {
      const response = await getTeamList(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchMeetsList = createAsyncThunk(
  "meetsList/fetchMeetsList",
  async (args: any, thunkAPI) => {
    try {
      const response = await getMeetsList(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const haveManagePermission = createAsyncThunk(
  "meetsList/haveManagePermission",
  async (args: any, thunkAPI) => {
    try {
      const { defaultTeamsId, userId } = args; ;
      const response = await checkManagePermission(defaultTeamsId,userId);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteMeets = createAsyncThunk(
  "meetsList/deleteMeets",
  async (args: any, thunkAPI) => {
    try {
      const response = await deleteMeeting(args.meetsId, args.isDeleted);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const pauseMeets = createAsyncThunk(
  "meetsList/pauseMeets",
  async (args: any, thunkAPI) => {
    try {
      const response = await pauseMeeting(args.meetsId, args.isPaused);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const meetsSlice = createSlice({
  name: "meetsReducer",
  initialState: initialState,
  reducers: {
    updatePauseMeets: (state: any, action: PayloadAction<meetsUpdate>) => {
      state.meetsListData[action.payload.index].isPaused = action.payload.flag
    },
    showAndHideButtonLoader: (state: any, action: PayloadAction<any>) => {
      state.meetsListData.map((x: any) => {
        if (action.payload.meetsId === x.meetsId) {
          x[action.payload.property] = action.payload.value
        }
        return x;
      })
    },
    showAndHidePageLoader: (state: any, action: PayloadAction<any>) => {
      state.pageLoader = action.payload.value
    },
    updateTeams: (state: any, action: PayloadAction<any>) => {
      state.defaultTeamsId = action.payload.value;
      state.defaultTeamsName = action.payload.name;

      localStorage.setItem('TeamName', state.defaultTeamsName);
      localStorage.setItem('TeamId', state.defaultTeamsId);
    },
    clearMeetsList: (state:any) => {
      state.meetsListData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase("meetsList/fetchTeams/fulfilled", (state: any = initialState, action: any) => {
        state.teamsListData = sortRecords(action.payload, 'isBotInstalled', false);
        // action.payload;
        if(state.teamsListData && state.teamsListData.length === 0) {
          state.meetsListData = []
          state.pageLoader = false;
        }
        else {
          if (!localStorage.getItem('TeamName') && !localStorage.getItem('TeamId')) {
            state.defaultTeamsId =  state.teamsListData[0].teamsId;
            state.defaultTeamsName =  state.teamsListData[0].teamsName;
            localStorage.setItem('TeamName', state.defaultTeamsName);
            localStorage.setItem('TeamId', state.defaultTeamsId);
          } else {
            state.defaultTeamsId = localStorage.getItem('TeamId');
            state.defaultTeamsName = localStorage.getItem('TeamName');
          }
        }        
      })
      .addCase("meetsList/fetchTeams/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching teams list.", action.payload.message);
      })
      .addCase("meetsList/fetchMeetsList/fulfilled", (state: any = initialState, action: any) => {
        action.payload.map((x: any) => {
          x.pauseLoader = false
          x.deleteLoader = false
          return x;
        })
        state.meetsListData = action.payload.filter(
          (x: any) => x.isDeleted === false
        );
        state.meetsListData.forEach((x: any, index: number) => {
          x.teamsId = state.defaultTeamsId;
          x.teamsName = state.defaultTeamsName;
        });
        state.pageLoader = false;
      })
      .addCase("meetsList/fetchMeetsList/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching meets list.", action.payload.message);
        state.pageLoader = false;
      })
      .addCase("meetsList/deleteMeets/rejected", (state: any, action: any) => {
        trackException("An error occurred while delete meets.", action.payload.message);
      })
      .addCase("meetsList/pauseMeets/rejected", (state: any, action: any) => {
        trackException("An error occurred while pause meets.", action.payload.message);
      })
      .addCase("meetsList/haveManagePermission/fulfilled", (state: any = initialState, action: any) => {
        state.isManagePermission = action.payload;
      })
      .addCase("meetsList/haveManagePermission/rejected", (state: any, action: any) => {
        if (action.payload) {
          trackException("An error occurred while get haveManagePermission .", action.payload.errorMessage);
        } else {
          trackException("An error occurred while get haveManagePermission.", action.error.message);
        }
      })
  },


});
export const {
  updatePauseMeets,
  showAndHideButtonLoader,
  showAndHidePageLoader,
  updateTeams,
  clearMeetsList
} = meetsSlice.actions;
export default meetsSlice.reducer;
