import React, { useState, useEffect } from 'react';
import HeaderComponent from '../Layout/AppHeader';
import FooterComponent from '../Layout/AppFooter';
import { Outlet, unstable_useBlocker as useBlocker } from "react-router-dom";
import AppInsights from '../../Services/appInsights';
import { useDispatch, useSelector } from 'react-redux';
import swal from "sweetalert";
import { updateBlockerData } from '../../Store/commonSlice';
import ScrollToTop from "./Shared/ScrollToTop";
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from "react-router-dom";
import LandingPageFooter from '../Layout/LandingPageFooter';

function AppLayout() {
  const dispatch = useDispatch<any>();

  const isBlocked = useSelector(
    (state: any) => state.commonReducer.isBlocked
  );

  let blocker = useBlocker(isBlocked);

  // Reset the blocker if the user cleans the form
  React.useEffect(() => {
    if (blocker.state === "blocked" && !isBlocked) {
      blocker.reset();
    }
  }, [isBlocked, blocker]);

  const showAlert = () => {
    swal({
      title: "You have unsaved changes!",
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: ["Stay on this Page", 'Discard & Leave'],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(updateBlockerData({ isBlocked: false }))
          blocker.proceed?.()
        } else {
          blocker.reset?.()
        }
      });
  }

  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  let location = useLocation();

  const navigate = useNavigate();

  const routeChange = (path: string) => {
    navigate(path);
  };

  const [isPageLoader, setIsPageLoader] = useState(true);

  useEffect(() => {
    var validHashValues = ["#home", "#about"];
    if(!validHashValues.includes(location.hash)) {
      setIsPageLoader(true);
      setTimeout(() => {
        setIsPageLoader(false);
      }, 2000);
    } else {
      setIsPageLoader(false);
    }    
  }, [location]);

  return (
    <div>
      {isAuthenticated ?
        <div className="header-footer-flex">
          <ScrollToTop />
          <>{blocker && blocker.state === "blocked" && showAlert()}</>
          <HeaderComponent />
          <Outlet />
          <FooterComponent />
        </div>
        :
        <div className="pre-loader">
          {isPageLoader &&
            <div>
              <div id="logocontainer" >
                <div id="pelogo"><img className="loader-image" src={require('../../Assets/Images/loader.png')} alt="Logo"  /></div>
                <div className="loader loader1" ></div>
                <div className="loader loader2" ></div>
                <div className="loader loader3"></div>
                <div className="loader loader4"></div>
              </div>
            </div>
          }
          {!isPageLoader &&
            <div>
              <ScrollToTop />
              {location.pathname !== '/' &&
                <div className="container">
                  <div className="row py-3">
                    <div className="d-flex header-logo-responsive">
                      <div className="my-auto cursor-pointer">
                        <img className="img-fluid" src={require('../../Assets/Images/logo.png')} alt="Logo" onClick={() => routeChange("/")} />
                        {/* <img className="img-fluid responsive-view-icon" src={require('../../Assets/Images/logoicon.png')} alt="Logo" onClick={() => routeChange("/")} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              }
              <Outlet />
              <div>
                <LandingPageFooter />
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}
export default AppInsights(AppLayout)