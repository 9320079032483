import React from 'react';

function HolidayTemplateDetailsSkeletonLoader() {
    return (
        <div>
        <div className="container-fluid">
                <div className="container py-4">
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="skeleton-heading skeleton py-2"></div>
                                <div className="skeleton-heading skeleton py-4 mt-3"></div>
                            </div>
                        </div>
                        <div className="col m-auto">
                            <div className="skeleton-standup-edit-btn skeleton float-end mx-0"></div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="container mt-3 mb-3">
            <div className="row">
                <div className="col-lg-6 my-2">
                    <div>
                        <div className="skeleton-heading skeleton py-2"></div>
                        <div className="mt-3 skeleton py-4 rounded"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="nav-align-top mt-1 mb-4">
                        <div className="tab-content mt-3">
                            <div className="tab-pane fade show active mb-3" id="prompt-tab" role="tabpanel">
                                <div>
                                    <div className="skeleton rounded mt-3">
                                        <div className='row templatedetails-padding'>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="skeleton rounded  mt-3">
                                        <div className='row templatedetails-padding'>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5  mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="skeleton rounded  mt-3">
                                        <div className='row templatedetails-padding'>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="skeleton rounded  mt-3">
                                        <div className='row templatedetails-padding'>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="skeleton rounded  mt-3">
                                        <div className='row templatedetails-padding'>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                            <div className=" col-3">
                                                <div className="skeleton py-2 mt-2 px-5 mb-2 holiday-templatedetails-firstline"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default HolidayTemplateDetailsSkeletonLoader;