import { useMapWeekDaysHook } from "../../Hooks/UseMapWeekDaysHook";
import { timeConversion } from "../../Utils/CommonUtils";
import { meetScheduleProps } from "../../Types/MeetsType";


function MeetsSchedule(props: meetScheduleProps) {

    const { period, meetDate, time, days, timezone } = props.meetSchedule;
    const { mapWeekDays } = useMapWeekDaysHook();


    return (
        <div className="my-auto">
            {period.toLowerCase() === "weekly" && <span>{mapWeekDays(days)} </span>}
            {period.toLowerCase() === "one time" && <span>{meetDate} </span>} at 
            <span> {timeConversion(time)} </span> in {timezone} 
        </div>
    )
}

export default MeetsSchedule;