import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useEffect } from "react";
import {
    updateMeetsData,
    fetchPlaceHolderList
} from "../../Store/manageSlice";
import { placeHolderType, placeHolderTypeSymbol } from "../../Constant/ApplicationConstant"


function PlaceHolder(props: any) {
    const dispatch = useDispatch<any>();

    const meets = useSelector(
        (state: any) => state.manageReducer.meetsData
    );
    const placeHolder = useSelector(
        (state: any) => state.manageReducer.placeHolder
    );

    const addContent = async (value: string) => {
        let previousMessage = props.type === placeHolderType.welcomeMessage ? meets.welcomeMessage : meets.thankyouMessage;
        let message = previousMessage + " " + placeHolderTypeSymbol + value;
        await dispatch(updateMeetsData({ name: props.type, value: message }))
    }

    let placeHolderData: any;
    useEffect(() => {
        dispatch(fetchPlaceHolderList())
    }, [])

    if (placeHolder) {
        placeHolderData = placeHolder.filter((data: any) => data.type === props.type)
    }
    return (
        <>  {placeHolderData && <div className="my-auto position-relative header-user">
            <div className="dropdown position-absolute" style={{ right: "13px", top: "-65px" }}>
                <span className="dropdown-ellipsis"><i className="fa-solid fa-ellipsis"></i></span>
                <div className="dropdown-content">
                    {placeHolderData.map((data: any, index: number) =>
                        <div key={"addContent" + index}>
                            <div className="dropdown-box" onClick={() => addContent(data.placeHolder)}>
                                <p className="dropdown-text">{data.placeHolder} </p>
                                <p className="dropdown-variable">{data.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        }
        </>
    )
}
export default PlaceHolder;