import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMeetsList,
  deleteMeets, pauseMeets,
  showAndHideButtonLoader,
  showAndHidePageLoader
} from "../../Store/meetsSlice";
import { getInitials } from "../../Utils/CommonUtils";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import DashboardSkeletonLoader from '../../Components/SkeletonLoader/DashboardSkeletonLoader';
import MeetsSchedule from "../../Components/Meets/MeetsSchedule";
import Breadcrumbs from "../../Components/Layout/Shared/Breadcrumbs";


function DashboardLayout() {

  const dispatch = useDispatch<any>();

  const meetsListData = useSelector(
    (state: any) => state.meetsReducer.meetsListData
  );

  const userAdObjectId = useSelector(
    (state: any) => state.authReducer.userDetails.userAdObjectId
  );

  const pageLoader = useSelector(
    (state: any) => state.meetsReducer.pageLoader
  );

  const defaultTeamsId = useSelector(
    (state: any) => state.meetsReducer.defaultTeamsId
  );

  useEffect(() => {
    (async () => {
      dispatch(showAndHidePageLoader({ value: true }));
      if (defaultTeamsId) {
        await dispatch(fetchMeetsList({ teamsId :defaultTeamsId, userAdObjectId : userAdObjectId} ));
      }
    })();
  }, [defaultTeamsId]);

  const navigate = useNavigate();

  const routeChange = (path: string) => {
    navigate(path);
  }

  const pauseMeeting = async (meetings: any) => {
    dispatch(showAndHideButtonLoader({ meetsId: meetings.meetsId, property: "pauseLoader", value: true }))
    let response = await dispatch(pauseMeets({ meetsId: meetings.meetsId, isPaused: !meetings.isPaused }))
    if (response.payload === 200) {
      if (defaultTeamsId) {
        await dispatch(fetchMeetsList({ teamsId :defaultTeamsId, userAdObjectId : userAdObjectId} ));
      }
    }
    dispatch(showAndHideButtonLoader({ meetsId: meetings.meetsId, property: "pauseLoader", value: false }))
  };

  const deleteMeeting = async (meetings: any) => {
    dispatch(showAndHideButtonLoader({ meetsId: meetings.meetsId, property: "deleteLoader", value: true }))
    await swal({
      title: "Warning", text: "Are you sure that you want to delete this meets?", icon: "warning",
      dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
    }).then(async willDelete => {
      if (willDelete) {
        let response = await dispatch(deleteMeets({ meetsId: meetings.meetsId, isDeleted: !meetings.isDeleted }))
        if (response.payload === 200) {
          swal({ title: "Success", text: "The selected meets has been deleted successfully", icon: "success", timer: 3000, buttons: [false] });
          if (defaultTeamsId) {
            await dispatch(fetchMeetsList({ teamsId :defaultTeamsId, userAdObjectId : userAdObjectId} ));
          }
        }
      }
    });
    dispatch(showAndHideButtonLoader({ meetsId: meetings.meetsId, property: "deleteLoader", value: false }))
  }

  const teamList = useSelector(
    (state: any) => state.meetsReducer.teamsListData
  );

  useEffect(() => {
    let teams = teamList && teamList.filter((teams: any) => teams.isBotInstalled === true);
    if (teams && teams.length === 0) {
      localStorage.setItem('TeamName', "");
      localStorage.setItem('TeamId', "");
      window.location.replace(process.env.REACT_APP_TEAMS_REDIRECT_URL as string);
    }
  });

  const manageUser = (index : number) => {
    return meetsListData && meetsListData[index].meetMembers.filter((e: any) => e.meetsPermissions.manage === true && e.aadObjectId == userAdObjectId).length > 0;
  }

  return (
    <>
      {pageLoader && <DashboardSkeletonLoader />}
      {!pageLoader && <div>
        <div className="container-fluid bg-light">
          <div className="container py-4">
            <div className="d-flex meeting-panel">
              <div className="">
                <h1 className="h1 my-0 fw-bold">My Meets</h1>
                <Breadcrumbs />
              </div>
              <div className="ms-auto my-auto">
                <button className="float-end fw-bold newstandup-button shadow" onClick={() => routeChange("/create/meets")}
                disabled={teamList && teamList.length === 0}>+ New Meets</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {(meetsListData && meetsListData.length === 0) && <div style={{ textAlign: "center", padding: "20px",position:"relative" }}><img src={require('../../Assets/Images/nomeets.png')} width="100%" alt="UserIcon" />
          <div className="position-absolute newmetting-panel" style={{ left: "0px",right: "260px",margin: "auto",display: "flex",top: "171px",bottom: "0"}}><button className="float-end fw-bold newstandup-button shadow m-auto" onClick={() => routeChange("/create/meets")}
                disabled={teamList && teamList.length === 0}>+ New Meets</button></div>
          </div>}
          {meetsListData && meetsListData.map((meets: any, index: any) => {
            return (
              <div key={"meets_" + index} className="profile-card js-profile-card">
                <div className="profile-card__cnt mt-5">
                  <div className="row">
                    <div className="col-lg-8 position-relative">
                      <div className="profile-card__panel"
                        onClick={() => routeChange('/minutesofmeets/' + meets.meetsId)} >
                        <div className="profile-card__name" title={meets.meetsName}>
                          {meets.meetsName}
                        </div>
                      </div>
                      <div className="profile-card__txt">
                        <MeetsSchedule meetSchedule={meets.meetsTrigger} />
                      </div>
                    </div>
                    <div className="col-lg-4 channel-list">
                      {meets.channels.map((channel: any) =>
                        <button className="channel-name"><i className="fa-solid fa-tags"></i><span className="ms-2">{channel.channelName}</span></button>
                      )}
                    </div>
                  </div>

                  <div className="profile-card-social">
                    {meets.meetMembers.slice(0, 6).map((member: any, index: any) => {
                      return (
                        <div className="profile-card-social__item facebook" key={member.name + index} title={member.name}>
                          {getInitials(member.name)}</div>
                      );
                    })}
                  </div>

                  <div className="profile-card-ctr">
                  <button className="profile-card__button profile-card__button-view"
                      onClick={() => routeChange('/minutesofmeets/' + meets.meetsId)}>
                      <span className="meeting-button-show">View</span><i className="fa fa-clipboard meeting-button-hide"></i>
                    </button>
                    {manageUser(index) && <button className="profile-card__button profile-card__button-edit"
                      onClick={() => routeChange('/manage/meets/' + meets.meetsId)}>
                      <span className="meeting-button-show">Edit</span><i className="fa fa-pen meeting-button-hide"></i>
                    </button>}
                    {manageUser(index) && <button className={"profile-card__button profile-card__button-pause " + (!meets.isPaused ? '' : 'profilecard-resume')} onClick={() => pauseMeeting(meets)}>
                      <span className="meeting-button-show">{meets.pauseLoader ? <span className="button-loader"></span> : !meets.isPaused ? "Pause" : "Resume"}</span>
                      <span className="meeting-button-hide">{meets.pauseLoader ? <span className="button-loader"></span> : !meets.isPaused ? <i className='fa-solid fa-pause'></i> : <i className="fa-solid fa-play"></i>}</span>
                    </button>}
                      {manageUser(index) && <button className="profile-card__button profile-card__button-delete" onClick={() => deleteMeeting(meets)}>
                      <span className="meeting-button-show">{meets.deleteLoader ? <span className="button-loader"></span> : 'Delete'}
                      </span><i className="fa-solid fa-trash meeting-button-hide"></i>
                    </button>}
                   
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>}
    </>
  );
}

export default DashboardLayout;