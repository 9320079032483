import { useSelector, useDispatch } from "react-redux";
import { requiredFields } from "../../Types/MeetsType";
import ErrorMessage from "../Meets/ErrorMessage";
import {
  updateHolidayTemplateData
} from "../../Store/holidayTemplateSlice";
import { updateBlockerData } from "../../Store/commonSlice";
import { findHelpText } from "../../Utils/CommonUtils"


function HolidayTemplateName(props: requiredFields) {

  const { register, errors } = props;
  const dispatch = useDispatch<any>();

  const holidayTemplate = useSelector(
    (state: any) => state.holidayTemplateReducer.holidayTemplate
  );
  
  const updateHolidayTemplateStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateHolidayTemplateData({ name: event.target.name, value: event.target.value }));
    dispatch(updateBlockerData({ isModified: true }))
  };


  return (
      <div className="col-lg-6">
        <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Template Name <div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("holidayTemplateName")}>i</span></div></h2>
        <div className={"position-relative mt-3" + (errors.templateName ? 'active_validation' : '')}>
          <input type="text" className="form-control form-control-lg" id="templateName"
            name="templateName" placeholder="Enter the Template Name" defaultValue={holidayTemplate.templateName}
            {...register('templateName', {
              required: true,
              maxLength: 80,
              minLength: 3,
              onChange: updateHolidayTemplateStore,

            })}
          />
        {errors.templateName && <span className="position-absolute"><ErrorMessage validationType={errors.templateName.type} fieldName="Template Name"
          minLength="3" maxLength="80" /></span>}
        </div>
      </div>
  );
}

export default HolidayTemplateName;