import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useMemo } from 'react';
import { findHelpText } from "../Utils/CommonUtils"
import {  useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {updateTenantData,fetchTenantDetails,updateTenantDetails} from "../Store/manageSlice";

import swal from "sweetalert";
import Breadcrumbs from "../Components/Layout/Shared/Breadcrumbs";

function Configuration() {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const userDetails = useSelector(
        (state: any) => state.authReducer.userDetails
    );
    const tenant = useSelector(
        (state: any) => state.manageReducer.tenantData
    );      
    const routeChange = (path: string) => {
        navigate(path);
    }
    const [isShowChatGPTApiKey, setShowChatGPTApiKey] = useState(true)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm(
        {
            mode: "all",
        }
    );

    useEffect(() => {
        if (userDetails.tenantId) {
            dispatch(fetchTenantDetails(userDetails.tenantId));
        }
    }, [userDetails.tenantId]);

    const onSubmit = async () => {
         if (tenant !=null) 
         {
            let tenantPostData = JSON.parse(JSON.stringify(tenant));  
                if(!tenantPostData.tenantChatGPTAPIRequestLimit)
                {
                    tenantPostData.tenantChatGPTAPIRequestLimit =0;
                }
                if(!tenantPostData.meetChatGPTAPIRequestLimit)
                {
                    tenantPostData.meetChatGPTAPIRequestLimit =0;
                }
                let response = await dispatch(updateTenantData({tenantPostData,isShowChatGPTApiKey}));
                setShowChatGPTApiKey(false);
                if (response.payload) 
                {
                    await swal({ title: "Success", text: `Configuration updated successfully`, icon: "success", timer: 3000, buttons: [false] })
                        .then((value) => {
                            routeChange("/")
                           
                        });
                 }
        }
    }

    const onError = (errors: any, event: any) => {
        console.log(errors, event);
    }

    const updateData = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value

        if(event.target.name != 'chatGPTApiKey')
        {
            value = value.replace(/\D/g, '')
        }

        dispatch(updateTenantDetails({ name: event.target.name, value}))
      };
   
      const clearChatGPTValue = () => {
        setShowChatGPTApiKey(false);
        dispatch(updateTenantDetails({ name: 'chatGPTApiKey', value: ''}))
      }

    return (
   
        <form onSubmit={handleSubmit(onSubmit, onError)}>
           
            <div className="container-fluid px-0 template-page">
               
                <div className="container-fluid bg-light px-0">
                    <div className="container py-4">
                        <div className="d-flex meeting-panel">
                            <div className="">
                                <h1 className="h1 my-0 fw-bold">Tenant Configuration</h1>
                                <Breadcrumbs currentPage="Configuration" hideTeams={true} />
                            </div>
                            <div className="ms-auto my-auto">
                                <button type="submit" className="btn float-end fw-bold newstandup-button"><i className="fa-solid fa-check me-2"></i>Save</button>
                                <button type="button" className="btn float-end fw-bold newstandup_cancel_button mx-2" onClick={() => routeChange("/")}><i className="fa-solid fa-xmark me-2"></i>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-6">
                            <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Chat GPT API Secret <div className="question-icon ms-2 position-relative">
                                <span className="tooltips" data-tool={findHelpText("chatGPTApiKey")}>i</span></div></h2>
                            <div className="position-relative mt-3">
                            <input type= {isShowChatGPTApiKey == true?"text":"password"} className="form-control form-control-lg pe-5" id="chatGPTApiKey"
                                    name="chatGPTApiKey" placeholder="Enter the API Key" maxLength={100} onChange={(event: any) => updateData(event)}
                                    value={tenant.chatGPTApiKey} disabled={isShowChatGPTApiKey}
                                />
                                {isShowChatGPTApiKey &&
                                    <div className="config-eye-panel" 
                                        onClick={clearChatGPTValue}>
                                        <i className="fa-regular fa-pen-to-square" title="Show ChatGPT Key"></i>
                                    </div>
                                }
                            </div>
                            
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-lg-6">
                            <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Tenant API Request Limit <div className="question-icon ms-2 position-relative">
                                <span className="tooltips" data-tool={findHelpText("TenantLimit")}>i</span></div></h2>
                                <div className="position-relative mt-3">
          <input type="text" className="form-control form-control-lg" id="tenantChatGPTAPIRequestLimit"
            name="tenantChatGPTAPIRequestLimit" placeholder="Enter the Tenant API Request Limit" maxLength={9}
            value={tenant.tenantChatGPTAPIRequestLimit} onChange={(event: any) => updateData(event)}
            
          />
         
        </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-6">
                            <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Meet API Request Limit <div className="question-icon ms-2 position-relative">
                                <span className="tooltips" data-tool={findHelpText("MeetLimit")}>i</span></div></h2>
                                <div className="position-relative mt-3">
          <input type="text" className="form-control form-control-lg" id="meetChatGPTAPIRequestLimit"
            name="meetChatGPTAPIRequestLimit" placeholder="Enter the Meet API Request Limit" maxLength={9}
            value={tenant.meetChatGPTAPIRequestLimit} onChange={(event: any) => updateData(event)}
         
          />
         
        </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
  );
}

export default Configuration;