import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import TermsAndConditions from '../Pages/Shared/TermsAndConditions';
import PrivacyPolicy from '../Pages/Shared/PrivacyPolicy';
import LandingPage from '../Pages/LandingPage/LandingPage';
import AppLayout from '../Components/Layout/AppLayout';
import NoMatch from '../Components/NoMatch';
import ContactUs from '../Pages/LandingPage/ContactUs';
import Support from '../Pages/LandingPage/Support';
import EndUserLicenseAgreement from '../Pages/Shared/EndUserLicenseAgreement';

export default createBrowserRouter([    
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <LandingPage />,
            },
            {
                path: "/termsandconditions",
                element: <TermsAndConditions  />,
            },
            {
                path: "/privacypolicy",
                element: <PrivacyPolicy  />,
            },
            {
                path: "/contactus",
                element: <ContactUs  />,
            },
            {
                path: "/support",
                element: <Support  />,
            },
            {
                path: "/enduserlicenseagreement",
                element: <EndUserLicenseAgreement  />,
            },
        ],
    },
    { path: "*", element: <NoMatch /> },
]);