import React from 'react';
import { Offcanvas } from 'react-bootstrap';

const CustomOffcanvas = ({ show, handleClose, title, children }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" className="canvas-panel" style={{ width: '75%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><h1 className="h1 my-0 fw-bold">{title}</h1></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div>
                    {children}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CustomOffcanvas;
