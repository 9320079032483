import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { deleteHolidayTemplateById, fetchUserHolidayTemplateList, showAndHidePageLoader } from "../../Store/holidayTemplateSlice";
import HolidayTemplatesSkeletonLoader from "../../Components/SkeletonLoader/HolidayTemplatesSkeletonLoader";
import swal from "sweetalert";
import Breadcrumbs from "../../Components/Layout/Shared/Breadcrumbs";

function HolidayTemplateComponent() {

    const navigate = useNavigate();

    const dispatch = useDispatch<any>();

    const userHolidayTemplates = useSelector(
        (state: any) => state.holidayTemplateReducer.userHolidayTemplateList
    );

    const loggedInUser = useSelector(
        (state: any) => state.authReducer.userDetails
    );

    const pageLoader = useSelector(
        (state: any) => state.holidayTemplateReducer.pageLoader
    );

    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );

    const routeChange = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        (async () => {
            if (loggedInUser.userAdObjectId && defaultTeamsId) {
                dispatch(showAndHidePageLoader({ value: true }));
                await dispatch(fetchUserHolidayTemplateList({ teamsId :defaultTeamsId, userId : loggedInUser.userAdObjectId} ));
            }
            dispatch(showAndHidePageLoader({ value: false }));
        })();
    }, [loggedInUser, defaultTeamsId]);

    const deleteHolidayTemplate = async (templateId: any) => {
        await swal({
            title: "Warning", text: "Are you sure that you want to delete this template", icon: "warning",
            dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
        }).then(async willConfirm => {
            if (willConfirm) {
                let response = await dispatch(deleteHolidayTemplateById({ templateId: templateId, isDeleted: true }));
                if (response.payload.status === 200) {
                    swal({ title: "Success", text: "The selected template has been deleted successfully", icon: "success", timer: 3000, buttons: [false] });
                    if (loggedInUser.userAdObjectId && defaultTeamsId) {
                        await dispatch(fetchUserHolidayTemplateList({ teamsId :defaultTeamsId, userId : loggedInUser.userAdObjectId}));
                    }
                }
            }
        });
    }

    return (
        <div className="container-fluid px-0 template-page">
            <div className="container-fluid bg-light px-0">
                <div className="container py-4">
                    <div className="d-flex">
                        <div className="">
                            <h1 className="h1 my-0 fw-bold">Templates</h1>
                            <Breadcrumbs currentPage="Templates"  hideTeams={true}/>
                        </div>
                        <div className="ms-auto my-auto minutes-button-back">
                            <button className="float-end fw-bold newstandup-button shadow" onClick={() => routeChange("/holidaytemplatedetails")}>Add New </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {pageLoader && <HolidayTemplatesSkeletonLoader />}
                {!pageLoader &&
                    <div className='container mt-5'>
                        {(userHolidayTemplates && userHolidayTemplates.length === 0) && <div style={{ textAlign: "center", padding: "20px", position: "relative" }}><img src={require('../../Assets/Images/noholidaytemplates.png')} width="100%" alt="UserIcon" />
                            <div className="position-absolute newmetting-panel" style={{ left: "0px", right: "260px", margin: "auto", display: "flex", top: "171px", bottom: "0" }}><button className="float-end fw-bold newstandup-button shadow m-auto" onClick={() => routeChange("/holidaytemplatedetails")}
                            >+ New Template</button></div>
                        </div>}
                        <div className='row'>
                            {userHolidayTemplates && userHolidayTemplates.map((holidayTemplate: any, index: any) => {
                                return (
                                    <div key={"holidayTemplate_" + index} className='col-lg-4 col-md-6 cursor-pointer'>
                                        <a className="data-card">
                                            <div className="position-relative data-header">
                                                <h3 className="col-11" title={holidayTemplate.templateName}>{holidayTemplate.templateName}</h3>
                                                <button type="button" className="trash-button" onClick={() => deleteHolidayTemplate(holidayTemplate.templateId)} key={index + "remove"}>
                                                    <i className="fa-solid fa-trash"></i></button>
                                            </div>
                                            <span className="link-text" onClick={() => routeChange('/updateholidaytemplatedetails/' + holidayTemplate.templateId)}>
                                                View Template
                                                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default HolidayTemplateComponent;
