import { schedulePeriods } from "../Types/MeetsType";
import { meetsTrigger } from "../Types/MeetsType";
import { formatDate } from "../Utils/CommonUtils";


export const weekList = [
    { day: "Sunday", value: "Sun", key: 1 },
    { day: "Monday", value: "Mon", key: 2 },
    { day: "Tuesday", value: "Tue", key: 3 },
    { day: "Wednesday", value: "Wed", key: 4 },
    { day: "Thursday", value: "Thu", key: 5 },
    { day: "Friday", value: "Fri", key: 6 },
    { day: "Saturday", value: "Sat", key: 7 },
]

export const defaultPromptsList = [
    { question: "How are you feeling today?" },
    { question: "Do you have work today?" },
    { question: "If you have any blocking issue let me know?" },
]

export const meetPeriod: schedulePeriods[] = [
    { name: 'Custom', value: 'weekly' },
    { name: 'One Time', value: 'one time' }
]

export const defaultScheduleTiming: meetsTrigger = {
    period: 'weekly',
    days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    time: '10:00',
    timezone: 'Etc/UTC',
    meetDate: formatDate(new Date()),
}

export const helpText = [
    { fieldName: "name", fieldHelpText: "Enter a name that describes the purpose of the meets." },
    { fieldName: "channel", fieldHelpText: "Meets channels will display for you." },
    { fieldName: "introMessage", fieldHelpText: "Personalise meets welcome message." },
    { fieldName: "prompts", fieldHelpText: "We have prepared a set of prompts for you." },
    { fieldName: "outroMessage", fieldHelpText: "Personalise meets thanks message." },
    { fieldName: "members", fieldHelpText: "This meet will be attended by the members you choose." },
    { fieldName: "periodAndDays", fieldHelpText: "Select the reporting period, days, and times." },
    { fieldName: "usersPrompts", fieldHelpText: "We have prepared a set of prompts based on users." },
    { fieldName: "holidayTemplates", fieldHelpText: "Choose from the available holiday templates." },
    { fieldName: "holidayTemplateName", fieldHelpText: "Enter a name that describes holiday templates." },
    { fieldName: "chatGPTApiKey", fieldHelpText: "ChatGPT API Key to fetch meet summary report." },
    { fieldName: "MeetLimit", fieldHelpText: "Maximum  summary requests per meet." },
    { fieldName: "TenantLimit", fieldHelpText: "Maximum summary requests for the Organization." },
    { fieldName: "Objectives", fieldHelpText: "Specify the objectives for generating the meet summary report." },
    { fieldName: "Roles", fieldHelpText: " Specify the roles utilized in generating the meet summary report." }
]

export const hoursList = [
    { key: 1, value: "01" },
    { key: 2, value: "02" },
    { key: 3, value: "03" },
    { key: 4, value: "04" },
    { key: 5, value: "05" },
    { key: 6, value: "06" },
    { key: 7, value: "07" },
    { key: 8, value: "08" },
    { key: 9, value: "09" },
    { key: 10, value: "10" },
    { key: 11, value: "11" },
    { key: 12, value: "12" }
]

export const minutesList = [
    { key: 1, value: "00" },
    { key: 2, value: "02" },
    { key: 3, value: "04" },
    { key: 4, value: "06" },
    { key: 5, value: "08" },
    { key: 6, value: "10" },
    { key: 7, value: "12" },
    { key: 8, value: "14" },
    { key: 9, value: "16" },
    { key: 10, value: "18" },
    { key: 11, value: "20" },
    { key: 12, value: "22" },
    { key: 13, value: "24" },
    { key: 14, value: "26" },
    { key: 15, value: "28" },
    { key: 16, value: "30" },
    { key: 17, value: "32" },
    { key: 18, value: "34" },
    { key: 19, value: "36" },
    { key: 20, value: "38" },
    { key: 21, value: "40" },
    { key: 22, value: "42" },
    { key: 23, value: "44" },
    { key: 24, value: "46" },
    { key: 25, value: "48" },
    { key: 26, value: "50" },
    { key: 27, value: "52" },
    { key: 28, value: "54" },
    { key: 29, value: "56" },
    { key: 30, value: "58" },

]

export const monthList = [
    { key: 1, value: "01", label:"January" },
    { key: 2, value: "02", label:"February" },
    { key: 3, value: "03", label:"March" },
    { key: 4, value: "04", label:"April" },
    { key: 5, value: "05", label:"May" },
    { key: 6, value: "06", label:"June" },
    { key: 7, value: "07", label:"July" },
    { key: 8, value: "08", label:"August" },
    { key: 9, value: "09", label:"September" },
    { key: 10, value: "10", label:"October"  },
    { key: 11, value: "11", label:"November"  },
    { key: 12, value: "12", label:"December"  }
]

export const userLocalTimeZone = "User's local time zone";
export const meetsLogPageSize = 10;
export const  placeHolderType= {welcomeMessage: "welcomeMessage", thankyouMessage:"thankyouMessage" }
export const  placeHolderTypeSymbol = "@"
export const meetsSummaryMemberCount = 10;
export const meetsSummaryDateCount = 10;
// export const chatGPTMeetRequestLimit = 10;
// export const chatGPTTenantRequestLimit = 100;