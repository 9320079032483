import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

function SupportComponent() {
    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    }

    const [selectedSupportType, setselectedSupportType] = useState('reportSupport');

    return (
        <div>
            <div className="container-fluid bg-light px-0">
                <div className="container py-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h1 className="h1 my-0 fw-bold">Support</h1>
                        </div>
                        <img className="img-fluid first-flow" src={require('../../Assets/Images/support.png')} alt="logo" />
                    </div>
                </div>
            </div>
            <div className='container mt-4 '>
                <h2 className="mt-3">Hi there</h2>
                <h2 className="mt-3">How can we help?</h2>
                <div className="tabs mt-4 support-tab-menu">
                    <input type="radio" id="radio-1" name="tabs" checked={selectedSupportType === 'reportSupport'} onClick={() => setselectedSupportType('reportSupport')} />
                    <label className="tab" htmlFor="radio-1">How Do I Report</label>
                    <input type="radio" id="radio-2" name="tabs" checked={selectedSupportType === 'installMeetstandApp'} onClick={() => setselectedSupportType('installMeetstandApp')} />
                    <label className="tab" htmlFor="radio-2">Install the Meetstand App</label>
                    <input type="radio" id="radio-3" name="tabs" checked={selectedSupportType === 'firstWorkflow'} onClick={() => setselectedSupportType('firstWorkflow')} />
                    <label className="tab" htmlFor="radio-3">Create First Workflow</label>
                    <input type="radio" id="radio-4" name="tabs" checked={selectedSupportType === 'botCommand'} onClick={() => setselectedSupportType('botCommand')} />
                    <label className="tab" htmlFor="radio-4">Bot commands</label>
                    <span className="glider"></span>
                </div>
                <div className="custom-select mt-3 support-dropdown-menu">
                    <select className='support-link' onChange={(e:any) => setselectedSupportType(e.target.value)}>
                        <option value="reportSupport">How Do I Report</option>
                        <option value="installMeetstandApp">Install the Meetstand App</option>
                        <option value="firstWorkflow">Create First Workflow</option>
                        <option value="botCommand">Bot commands</option>
                    </select>
                </div>
                <div className='support-panel' style={{ display: selectedSupportType === 'reportSupport' ? 'block' : 'none' }}>
                    <h3 className='mt-4'>How Do I Report</h3>
                    <h5 className='mt-3'><b>Login to Microsoft Teams and add a new team </b></h5>
                    <h6 className='mt-3'>1. Open the Microsoft Teams App and log in with your credentials</h6>                    
                    <h6 className='mt-3'>2. In the Microsoft Teams app create a New Team </h6>
                    <h6 className='mt-3 ms-5'>a. Click on the Teams menu and click on “Join or create a team”</h6>
                    <h6 className='mt-3 ms-5'>b. Click on the “Create a Team” button </h6>
                    
					 <img className="img-fluid mt-4 show-desktop first-flow" src={require('../../Assets/Images/support/report-1.png')} alt="report-1" />
                     <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/report-1.png')} alt="report-1" />
                    {/* <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/report-mobile-1.png')} alt="report-1" /> */}
                    <h6 className='mt-3 ms-5'>c. Click on From Scratch and click on Private</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/report-2.png')} alt="report-2" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/report-mobile-2.png')} alt="report-2" />
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/report-3.png')} alt="report-3" />  
                    <img className="img-fluid mt-4  show-phone" src={require('../../Assets/Images/support/report-3-phone.png')} alt="report-3" />                   
                    <h6 className='mt-3 ms-5'>d. Enter a new team name for example: <b>“Purple Team” </b></h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/report-4.png')} alt="report-4" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/report-4-phone_.png')} alt="report-4" />
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/report5.png')} alt="report-6" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/report5-phone.png')} alt="report-6"/>
                   
                    {/* <img className="img-fluid mt-4 first-flow" src={require('../../Assets/Images/support/report-5.png')} alt="report-5" /> */}
                    
                </div>

                <div className='support-panel' style={{ display: selectedSupportType === 'installMeetstandApp' ? 'block' : 'none' }}>
                    <h3 className='mt-3'><b> Meetstand bot Installation</b></h3>
                    <h6 className='mt-3'>1. In Microsoft Teams click on the Apps menu</h6>
                    <h6 className='mt-3'>2. Click on Build for your org and click on the Meetstand bot app</h6>
                    <img className="img-fluid mt-3 first-flow show-desktop" src={require('../../Assets/Images/support/install-app-1.png')} alt="install-app-1" />
                    <img className="img-fluid mt-3  show-phone" src={require('../../Assets/Images/support/install-app-1-phone.png')} alt="install-app-1" />
                    <h6 className='mt-3'>3. Click on <b>Add button</b> to install the <b>Meetstand bot app </b>on the Microsoft Teams </h6>
                    <img className="img-fluid mt-3 first-flow show-desktop" src={require('../../Assets/Images/support/install-app-2.png')} alt="install-app-2" />   
                    <img className="img-fluid mt-3 show-phone" src={require('../../Assets/Images/support/install-app-2-phone.png')} alt="install-app-2" />                    
                    <h6 className='mt-3'>1. In Microsoft Teams click on the Apps menu </h6>
                    <h6 className='mt-3'>2. Click on Build for your org and click on the Meetstand bot app</h6>
                    <h6 className='mt-3'>3. Add a Purple Team on the Meetstand bot app  </h6>
                    <h6 className='mt-3 ms-5'>a. Click on the open dropdown and click on Add to a team</h6>
                    <img className="img-fluid mt-3 first-flow show-desktop" src={require('../../Assets/Images/support/install-app-3.png')} alt="install-app-3"/>
                    <img className="img-fluid mt-3 show-phone" src={require('../../Assets/Images/support/install-app-3-phone.png')} alt="install-app-3"/>
                    <h5 className='mt-3 ms-5'>In the search textbox select the created Team and click on the <b>“Set up a bot button” to set up a team with the Meetstand bot</b></h5>
                    <img className="img-fluid mt-3 first-flow show-desktop" src={require('../../Assets/Images/support/install-app-4.png')} alt="install-app-4"/>
                    <img className="img-fluid mt-3  show-phone" src={require('../../Assets/Images/support/install-app-4-phone.png')} alt="install-app-4"/>
                    <img className="img-fluid mt-3 first-flow show-desktop" src={require('../../Assets/Images/support/install-app-5.png')} alt="install-app-5" />
                    <img className="img-fluid mt-3  show-phone" src={require('../../Assets/Images/support/install-app-5-phone.png')} alt="install-app-5" />
                </div>

                <div className='support-panel' style={{ display: selectedSupportType === 'firstWorkflow' ? 'block' : 'none' }}>
                    <h3 className='mt-3'><b>Login to the Meetstand web application </b></h3>
                    <h3 className='mt-4'>How to create your first workflow?</h3>
                    <h6 className='mt-3'>1. Login to the Meetstand web application with Your credentials</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/first-workflow-1.png')} alt="first-workflow-1" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/first-workflow-1-phone.png')} alt="first-workflow-1" />                   
                    <h5 className='mt-3'><b>Set up a Meetstand bot app by adding the team </b></h5>
                    <h5 className='mt-3'><b>New Meets Creation </b></h5>                    
                    <h6 className='mt-4'>1. On the Meets board page click on the New Meets button</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/first-workflow-2.png')} alt="first-workflow-2" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/first-workflow-2.phone.png')} alt="first-workflow-2" />
                    <h6 className='mt-3'>2. In the create meets page, enter the meets information and click on the save button</h6>
                    <h6 className='mt-3  ms-5'>a. Enter Meets name in the meets name field</h6>
                    <h6 className='mt-3 ms-5'>b. Select Channel in the channels field</h6>
                    <h6 className='mt-3 ms-5'>c. Select Trigger Time as “Custom” with a valid trigger time and timezone </h6>
                    <h6 className='mt-3 ms-5'>d. Click on the save button </h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/first-workflow-3.png')} alt="first-workflow-3" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/first-workflow-3-phone.png')} alt="first-workflow-3" />
                    <h6 className='mt-3 ms-5'>e. The Meets created successfully and the created meets should be displayed on the Meets Board page </h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/first-workflow-4.png')} alt="first-workflow-4" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/first-workflow-4-phone.png')} alt="first-workflow-4" />
                </div>

                <div className='support-panel' style={{ display: selectedSupportType === 'botCommand' ? 'block' : 'none' }}>
                    <h3 className='mt-4'>Bot commands</h3>
                    <h5 className='mt-3'><b>Report command to start the Meets </b></h5>
                    <h6 className='mt-3'>1. Open the Microsoft Teams App and log in with Your credentials</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/bot-commands-1.png')} alt="bot-commands-1" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/bot-commands-1.png')} alt="bot-commands-1" />
                    <h6 className='mt-3'>2. In the chat window, click on Meetstand chat and enter the keyword <b> “Report” </b></h6>
                    <h6 className='mt-3'>3. The Meetstand bot replied, <b> “Which Meets would you like to report now?”</b> with dropdown options </h6>
                    <h6 className='mt-3'>4. Select <b> Scrum Meets </b>and click on the Start button </h6>
                    <h6 className='mt-3 ms-5'>a. The bot trigger 1st prompt for the user, by entering an answer to the question</h6>
                    <h6 className='mt-3 ms-5'>b. Next the bot trigger 2nd prompt to the user by entering an answer to the question</h6>
                    <h6 className='mt-3 ms-5'>c. Similarly, the bot will trigger all the questions to the user </h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/bot2.png')} alt="bot-commands-2" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/bot-phone.png')} alt="bot-commands-2" />
                    <h5 className='mt-3'><b>Help command</b></h5>
                    <h6 className='mt-3'>1. In the chat window, click on Meetstand chat and enter the keyword <b>“Help”</b></h6>
                    <h6 className='mt-3'>2. The bot will reply to the supported commands for the user</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/helpcommand.png')} alt="bot-commands-3" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/helpcommand-phone.png')} alt="bot-commands-3" />
                    <h5 className='mt-3'><b>Dashboard command </b></h5>
                    <h6 className='mt-3'>1. In the chat window, click on Meetstand chat and enter the keyword<b> “Dashboard"</b></h6>
                    <h6 className='mt-3'>2. The bot will reply Dashboard link, clicking on the Dashboard button opens the Meetstand web application in the browser</h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/dashboard-command.png')} alt="bot-commands-4" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/dashboard-command.png')} alt="bot-commands-4" />
                    <h5 className='mt-4'><b>Unsupported commands </b></h5>
                    <h6 className='mt-3'>1. In the chat window, click on Meetstand chat and enter the unsupported command as <b>“Hi”</b></h6>
                    <h6 className='mt-3'>2. The bot will reply<b> “Sorry, I didn't get that. What would you like to do?” </b>and the bot will display the supported command keywords in the dropdown as <b>“Report, Dashboard and Help”</b></h6>
                    <img className="img-fluid mt-4 first-flow show-desktop" src={require('../../Assets/Images/support/command-unsupported.png')} alt="bot-commands-5" />
                    <img className="img-fluid mt-4 show-phone" src={require('../../Assets/Images/support/command-supported-phone.png')} alt="bot-commands-5" />
                </div>
            </div>
            <section className="we-offer-area text-center bg-gray mt-5">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12">
                        </div>
                    </div>
                    <div className="row our-offer-items less-carousel">
                        <div className="col-12 equal-height">
                            <div className="item">
                                <h1>Keep everyone in sync </h1>
                                <p>
                                    Run asynchronous standups, retors,and more workflows
                                </p>
                                <button type="button" className="callout--button" onClick={() => routeChange("/contactus")}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default SupportComponent;
