import React from 'react';


function DashboardSkeletonLoader() {
    return (
        <div>
            <div className="container-fluid">
                <div className="container py-4">
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="skeleton-heading skeleton py-2"></div>
                                <div className="skeleton-heading skeleton py-4 mt-3"></div>
                            </div>
                        </div>
                        <div className="col m-auto">
                            <div className="skeleton-standup-edit-btn mx-0 skeleton float-end"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div>
                    <div className="profile-card__cnt skeleton skeleton-Card">
                        <div className="row">
                            <div className="col-lg-5">
                                <div>
                                    <div className="mb-2 skeleton-heading skeleton py-2"></div>
                                    <div className="mb-2 skeleton py-2"></div>
                                    <button className="skeleton skeleton-standup-btn py-3 px-5"></button>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="profile-card-inf">
                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>

                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>

                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-card-social">
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                        </div>
                        <div className="profile-card-ctr">
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="profile-card__cnt skeleton skeleton-Card mt-5">
                        <div className="row">
                            <div className="col-lg-5">
                                <div>
                                    <div className="mb-2 skeleton-heading skeleton py-2"></div>
                                    <div className="mb-2 skeleton py-2"></div>
                                    <button className="skeleton skeleton-standup-btn py-3 px-5"></button>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="profile-card-inf">
                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>
                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>
                                    <div className="profile-card-inf__item">
                                        <div className="skeleton py-3"></div>
                                        <div className="skeleton py-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-card-social">
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                            <div className="skeleton-user-circle skeleton"></div>
                        </div>
                        <div className="profile-card-ctr">
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                            <button className="skeleton skeleton-standup-edit-btn"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-auto">
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center py-3">
                            <div className="skeleton skeleton-footer py-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardSkeletonLoader;