import { format } from "date-fns";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DateRangePicker } from 'react-date-range';

let oldStartDate = new Date();
let oldEndDate = new Date();
    
function DaterangePicker({ dateRange }: any) {

    const [dateRangeOptions, setDateRangeOptions] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    const [showDatePicker, setShowDatePicker] = useState(false);

    const showDateRangePicker = () => {
        setShowDatePicker(showDatePicker ? false : true);
    }

    const updateDateRange = (ranges: any) => {
        setDateRangeOptions(ranges.selection);
    }

    const applyDate = () => {
        setShowDatePicker(showDatePicker ? false : true);
        dateRange(dateRangeOptions, true);          
        oldStartDate = dateRangeOptions.startDate;
        oldEndDate = dateRangeOptions.endDate;      
    }

    const clearDate = () => {
        setDateRangeOptions({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setShowDatePicker(showDatePicker ? false : true);
        let tempDateRange:any = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        };
        dateRange(tempDateRange , true);
    }

    const closeDateRangePicker = () => {   
        if(dateRangeOptions.startDate.toLocaleDateString() !== oldStartDate.toLocaleDateString() || 
            dateRangeOptions.endDate.toLocaleDateString() !== oldEndDate.toLocaleDateString()){ 
                setDateRangeOptions({
                    startDate: oldStartDate,
                    endDate: oldEndDate,
                    key: 'selection',
                });
        }
        setShowDatePicker(false);
    }

    useEffect(() => {
        dateRange(dateRangeOptions, false);
        oldStartDate = new Date();
        oldEndDate = new Date();
    }, [])

    return (
        <>
            <OutsideClickHandler onOutsideClick={closeDateRangePicker}>
                <div className="daterange_picker_main">
                    <button type="button" className="form-control form-control-lg focus-prompt position-relative text-start" onClick={showDateRangePicker}>
                        <span>{format(dateRangeOptions.startDate, "MM/dd/yyyy")} - {format(dateRangeOptions.endDate, "MM/dd/yyyy")}</span>
                    </button>
                    {
                        showDatePicker &&
                        <div className="daterange_picker">
                            <DateRangePicker
                                ranges={[dateRangeOptions]}
                                onChange={updateDateRange}
                                rangeColors={['#8A1BBA']}
                                showDateDisplay={false}
                                inputRanges={[]}
                            />
                            <div className="daterange_picker_action">
                                <button type="button" className="btn rdr_apply_btn btn-sm float-end" onClick={applyDate}>Apply</button>
                                <button type="button" className="btn rdr_apply_btn btn-sm float-end me-2" onClick={clearDate}>Clear</button>
                            </div>
                        </div>
                    }
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default DaterangePicker;