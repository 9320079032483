import { combineReducers } from "redux";
import authSlice from "./authSlice";
import meetsSlice from "./meetsSlice";
import manageSlice from "./manageSlice";
import commonSlice from "./commonSlice";
import profileSlice from "./profileSlice";
import holidayTemplateSlice from "./holidayTemplateSlice";
import contactUsSlice from "./contactUsSlice";

const rootReducer = combineReducers({
  authReducer: authSlice,
  meetsReducer: meetsSlice,
  manageReducer: manageSlice,
  commonReducer: commonSlice,
  profileReducer: profileSlice,
  holidayTemplateReducer: holidayTemplateSlice,
  contactUsReducer: contactUsSlice
});

export default rootReducer;