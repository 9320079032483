import React from 'react';

function MinutesOfMeetsSkeleton() {
    return (
        <div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-12 position-relative">
                        <div className="position-relative skeleton skeleton-Card p-0" style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                            <div className="minutes-card-main">
                                <div className="skeleton" style={{ height: "115px", borderRadius: "12px 12px 0px 0px" }}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center p-3 ms-4">
                                            <div className="shadow border mt-1 minutes-name-profile skeleton skeleton-Card" style={{ width: "120px", height: "120px", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            </div>
                                            <div className="ms-3">
                                                <h4 style={{ width: "150px" }} className="h4 lh-sm fw-bolder mb-0 minutesmeets-name skeleton skeleton-Card p-2"></h4>
                                            </div>
                                        </div>
                                        <div className="float-end ms-auto me-2 minutes-button">
                                            <button className="my-0 mx-2 skeleton skeleton-Card p-1 profile-card__button profile-card__button-edit shadow"></button>
                                            <button className="my-0 mx-2 skeleton skeleton-Card p-1 profile-card__button profile-card__button-clone shadow"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row px-4">
                                <div className="col-lg-4 col-md-4 col-sm-4 minutes-channel mt-4">
                                    <div>
                                        <h6 className="h6 mt-2 fw-bolder skeleton skeleton-heading p-2"></h6>
                                        <h6 className="h6 mt-2 lh-sm skeleton skeleton-heading p-2"></h6>
                                    </div>
                                    <div className="mt-3">
                                        <h6 className="h6 mt-2 fw-bolder skeleton skeleton-heading p-2"></h6>
                                        <h6 className="h6 mt-2 lh-sm skeleton p-2"></h6>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 mt-4">
                                    <div className="pe-2">
                                        <h6 className="h6 fw-bolder skeleton skeleton-heading p-2"></h6>
                                        <div className="minutes-meet-prompts">
                                            <h6 className="h6 mb-3 skeleton skeleton-prompts"></h6>
                                            <h6 className="h6 mb-3 skeleton skeleton-prompts"></h6>
                                            <h6 className="h6 mb-3 skeleton skeleton-prompts"></h6>
                                            <h6 className="h6 mb-3 skeleton skeleton-prompts"></h6>
                                            <h6 className="h6 mb-3 skeleton skeleton-prompts"></h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mx-3">
                                <div className="col-lg-12">
                                    <div className="position-relative minutes-member">
                                        <h6 className="h6 my-2 fw-bolder position-absolute skeleton skeleton-heading p-2" style={{ top: "-40px", height: "20px" }}></h6>
                                        <div className="d-flex flex-wrap position-relative mb-2 minutes-card-profile-main">
                                            <div className="mx-1 skeleton skeleton-user-circle"></div>
                                            <div className="mx-1 skeleton skeleton-user-circle"></div>
                                            <div className="mx-1 skeleton skeleton-user-circle"></div>
                                            <div className="mx-1 skeleton skeleton-user-circle"></div>
                                            <div className="mx-1 skeleton skeleton-user-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="skeleton skeleton-Card p-0" style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                            <div className="row mt-3 mx-0">
                                <div className="col-lg-12 px-0">
                                    <div className="nav-align-top mt-1 pt-3">
                                        <ul className="nav px-3">
                                            <li className="nav-item nav-tab px-3 h5 mb-0 py-3 skeleton skeleton-heading"></li>
                                            <li className="nav-item nav-tab px-3 h5 mb-0 ms-3 py-3 skeleton skeleton-heading"></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row timeline-heading mx-0 mt-3 px-3">
                                    <h3 className="h3 fw-bold skeleton skeleton-heading" style={{ height: "20px" }}></h3>
                                    <div className="clear-both"></div>
                                    <div className="col-lg-4">
                                        <h4 className="h4 skeleton skeleton-heading" style={{ height: "20px" }}></h4>
                                        <div className="position-relative time-line">
                                            <button className="form-control form-control-lg focus-prompt position-relative text-start skeleton"></button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <h4 className="h4 skeleton skeleton-heading" style={{ height: "20px" }}></h4>
                                        <div className="position-relative time-line">
                                            <button className="form-control form-control-lg focus-prompt position-relative text-start skeleton"></button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <h4 className="h4 d-flex align-items-center skeleton skeleton-heading" style={{ height: "20px" }}></h4>
                                        <div className="position-relative time-line">
                                            <button className="form-control form-control-lg focus-prompt position-relative text-start skeleton"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2 mx-0">
                                <div className="col-lg-12 px-4">
                                    <div className="Time-line-border-bottom">
                                        <div className="d-flex justify-content-center">
                                            <h5 className="h5 text-center mt-3 skeleton skeleton-heading p-3"></h5>
                                        </div>
                                        <div className="row time-left-border">
                                            <div className="col-lg-6 px-3 mt-2">
                                                <div className="d-flex align-items-center mt-2 timeline-profile">
                                                    <div className="d-flex  align-items-center">
                                                        <div className="skeleton skeleton-user-circle m-0"></div>
                                                        <div className="ms-2 py-1">
                                                            <h6 className="h6 ms-2 mt-2 fw-bolder skeleton skeleton-heading p-2"></h6>
                                                            <p className="p my-0 ms-2 skeleton skeleton-heading p-2"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="time-line-left mt-3 border-white px-0">
                                                        <div className="skeleton rounded-0 p-3 mb-3">
                                                            <h6 className="h6 fw-bolder skeleton skeleton-Card p-2"></h6>
                                                            <p className="p mt-2 mb-0 skeleton skeleton-Card skeleton-heading p-2"></p>
                                                        </div>
                                                        <div className="skeleton rounded-0 p-3 mb-3">
                                                            <h6 className="h6 fw-bolder skeleton skeleton-Card p-2"></h6>
                                                            <p className="p mt-2 mb-0 skeleton skeleton-Card skeleton-heading p-2"></p>
                                                        </div>
                                                        <div className="skeleton rounded-0 p-3 mb-3">
                                                            <h6 className="h6 fw-bolder skeleton skeleton-Card  p-2"></h6>
                                                            <p className="p mt-2 mb-0 skeleton skeleton-Card skeleton-heading p-2"></p>
                                                        </div>
                                                        <div className="skeleton rounded-0 p-3 mb-3">
                                                            <h6 className="h6 fw-bolder skeleton skeleton-Card  p-2"></h6>
                                                            <p className="p mt-2 mb-0 skeleton skeleton-Card skeleton-heading p-2"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MinutesOfMeetsSkeleton;