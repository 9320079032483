import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requiredFields } from "../../Types/MeetsType";
import ErrorMessage from "./ErrorMessage";
import { updateMeetsData } from "../../Store/manageSlice";
import { findHelpText } from "../../Utils/CommonUtils"
import { fetchMeetsList } from "../../Store/meetsSlice";
import { updateBlockerData } from "../../Store/commonSlice";


function MeetsName(props: requiredFields) {

  const { register, errors, reset } = props;
  const dispatch = useDispatch<any>();

  const meetsData = useSelector(
    (state: any) => state.manageReducer.meetsData
  );

  const meetsListData = useSelector(
    (state: any) => state.meetsReducer.meetsListData
  );

  const defaultTeamsId = useSelector(
    (state: any) => state.meetsReducer.defaultTeamsId
  );

  useEffect(() => {
    (async () => {
      if (meetsListData && meetsListData.length === 0 && defaultTeamsId) {
        await dispatch(fetchMeetsList(defaultTeamsId));
      }
      reset()
    })();
  }, [reset, defaultTeamsId]);

  const updateMeetsName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateMeetsData({ name: event.target.name, value: event.target.value }))
    dispatch(updateBlockerData({ isModified: true }))
  };

  const validateUniqueMeetsName = (meetsName: string) => {
    let filteredMeetsList = [];
    if (!meetsData.meetsId) {
      filteredMeetsList = meetsListData.filter((meet: any) => meet.meetsName.toLowerCase().trim() === meetsName.toLowerCase().trim());
    }
    else {
      filteredMeetsList = meetsListData.filter((meet: any) => meet.meetsName.toLowerCase().trim() === meetsName.toLowerCase().trim() && meet.meetsId !== meetsData.meetsId)
    }
    if (filteredMeetsList && filteredMeetsList.length > 0) { return false; }
    return true;
  }


  return (
    <div className="col-lg-6">
      <h2 className="h2 mt-4 d-flex align-items-center fw-bold">Meets Name<div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("name")}>i</span></div></h2>
      <div className={"position-relative mt-3 " + (errors.meetsName ? 'active_validation' : '')}>
        <input type="text" className="form-control form-control-lg" id="meetsName"
          name="meetsName" placeholder="Enter the meets name" defaultValue={meetsData.meetsName}
          {...register('meetsName', {
            required: true,
            maxLength: 80,
            minLength: 3,
            onChange: updateMeetsName,
            validate: {
              uniqueMeetsName: (meetsName: string) => validateUniqueMeetsName(meetsName),
            },
          })} />
        {errors.meetsName && <ErrorMessage validationType={errors.meetsName.type} fieldName="Meets Name"
          minLength="3" maxLength="80" />}
      </div>
    </div>
  );
}

export default MeetsName;