import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contactUsDetails } from '../Types/ContactUsType';
import { saveContactUsData } from "../Services/Api/contactService";
import { trackException } from "../Utils/TrackExceptionUtility";


const initialState: contactUsDetails = {
    id: "0",
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: ""
};

export const saveContactUs = createAsyncThunk(
  "contactUs/saveContactUs",
  async (args: any, thunkAPI) => {
    try {
      const response = await saveContactUsData(args);
      return response;
    } catch (err) {
      let error: any = err
      if (error.response && error.response.status) {
        throw error.response.data ? error.response.data : error.response.statusText
      }
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUsReducer",
  initialState: initialState,
  reducers: {
    updateContactUsDetails: (state: any, action: PayloadAction<any>) => {
      state[action.payload.name] = action.payload.value
    },
    clearContactUsDetails: (state:any) => {
      state.id= "0"
      state.name= "";
      state.email= "";
      state.phoneNumber= "";
      state.subject= "";
      state.message= ""
    }
  },extraReducers: (builder) => {
    builder      
      .addCase("contactUs/saveContactUs/rejected", (state: any, action: any) => {
        trackException("An error occurred while contact details meets.", action.error.message);
      })
    }
});
export const {
  updateContactUsDetails,
  clearContactUsDetails
} = contactUsSlice.actions;
export default contactUsSlice.reducer;
