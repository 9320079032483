import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userInfo } from '../Types/AuthType';
import { createTenant, getTenant } from "../Services/Api/meetsService";
import { trackException } from "../Utils/TrackExceptionUtility";


const initialState: userInfo = {
  userDetails: {
    name: "",
    email: "",
    userAdObjectId: "",
    tenantId: ""
  }
};

export const createTenantStatus = createAsyncThunk(
  "userInfo/createTenantStatus",
  async (tenantId: string, thunkAPI) => {
    try {
      const response = await createTenant(tenantId);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const authSlice = createSlice({
  name: "authReducer",
  initialState: initialState,
  reducers: {
    updateUserInfo: (state: any, action: PayloadAction<any>) => {
      action.payload.accounts.map((account: any) => {
        state.userDetails.name = account.name
        state.userDetails.email = account.username
        state.userDetails.userAdObjectId = account.localAccountId
        state.userDetails.tenantId = account.tenantId
        return account
      })
    },
  },
 
});
export const {
  updateUserInfo,
} = authSlice.actions;
export default authSlice.reducer;
