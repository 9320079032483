import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import AppLayout from '../Components/Layout/AppLayout';
import NoMatch from '../Components/NoMatch';
import CreateAndManageMeets from '../Pages/Meets/CreateAndManageMeets';
import Dashboard from '../Pages/Dashboard/MeetsDashboard';
import MinutesOfMeets from '../Pages/MinutesOfMeets/MinutesOfMeets';
import Profile from '../Pages/Profile/Profile';
import HolidayTemplates from '../Pages/Templates/HolidayTemplates';
import HolidayTemplateDetails from '../Pages/Templates/HolidayTemplateDetails';
import TermsAndConditions from '../Pages/Shared/TermsAndConditions';
import PrivacyPolicy from '../Pages/Shared/PrivacyPolicy';
import ContactUs from '../Pages/LandingPage/ContactUs';
import Support from '../Pages/LandingPage/Support';
import Configuration from '../Pages/Configuration';

export default createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "/create/meets",
                element: <CreateAndManageMeets />,
            },
            {
                path: "/manage/meets/:meetsId",
                element: <CreateAndManageMeets />,
            },
            {
                path: "/clone/meets/:meetsId",
                element: <CreateAndManageMeets />,
            },
            {
                path: "/minutesofmeets/:meetsId",
                element: <MinutesOfMeets  />,
            },
            {
                path: "/profile",
                element: <Profile  />,
            },
            {
                path: "/templates",
                element: <HolidayTemplates  />,
            },
            {
                path: "/configuration",
                element: <Configuration  />,
            },
            {
                path: "/holidaytemplatedetails",
                element: <HolidayTemplateDetails  />,
            },
            {
                path: "/updateholidaytemplatedetails/:templateId",
                element: <HolidayTemplateDetails  />,
            },
            {
                path: "/termsandconditions",
                element: <TermsAndConditions  />,
            },
            {
                path: "/privacypolicy",
                element: <PrivacyPolicy  />,
            },
            {
                path: "/contactus",
                element: <ContactUs  />,
            },
            {
                path: "/support",
                element: <Support  />,
            },
        ],
    },
    { path: "*", element: <NoMatch /> },
]);