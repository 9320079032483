import axios from "axios";
import { holidayTemplateInput } from "../../Types/templateType";

export async function getHolidayTemplatesByUserId(postObj: any) {
    return await axios.get("HolidayTemplate?userId="+postObj.userId+"&teamsIds="+postObj.teamsId);
}

export async function getHolidayTemplateByTemplate(templateId: any) {
    return await axios.get("HolidayTemplate?templateId="+templateId);
}

export async function upsertHolidayTemplate(holidayTemplateInput: holidayTemplateInput) {
    return await axios.post("HolidayTemplate/", holidayTemplateInput);
}

export async function getHolidayTemplateByTeamsId(teamsIds: any) {
    return await axios.get("HolidayTemplate?teamsIds="+teamsIds);
}

export async function deleteHolidayTemplate(templateId: string, isDeleted: boolean) {
    return await axios.delete("HolidayTemplate?templateId=" + templateId + "&isDeleted=" + isDeleted);
}