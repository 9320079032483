import { helpText } from "../Constant/ApplicationConstant";


export const timeConversion = function (hours: string) {
  return new Date('1970-01-01T' + hours + 'Z')
    .toLocaleTimeString('en-US',
      { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
    );
}

export const getInitials = function (name: string) {
  const formatName = name.replace(/\W*(\w)\w*/g, '$1').toUpperCase()
  return formatName.slice(0, 2);
}

export const sortRecords = function (origArray: [], column: string, isAscending: Boolean, columnCaseInsensitive: Boolean = false) {
  var valueArray = JSON.parse(JSON.stringify(origArray))
  return valueArray.sort(function (a: any, b: any) {
    var fieldValueA = a[column];
    var fieldValueB = b[column];
    if (!columnCaseInsensitive) {
      fieldValueA = fieldValueA.toString().toLowerCase();
      fieldValueB = fieldValueB.toString().toLowerCase();
    }
    if (isAscending) {
      return fieldValueA > fieldValueB ? 1 : fieldValueB > fieldValueA ? -1 : 0;
    }
    else {
      return fieldValueB > fieldValueA ? 1 : fieldValueA > fieldValueB ? -1 : 0;
    }
  });
}

export function formatDate(date: Date) {
 
  const m =date.getMonth();
  const d=  date.getDate();
  const formatDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  
  return [ formatMonth,formatDate,date.getFullYear()].join('/')
}

export const findHelpText = function (fieldName: string) {
  let filterHelpText = helpText.find(x => x.fieldName === fieldName);
  return filterHelpText ? filterHelpText.fieldHelpText : '';
}

interface check  {
  meetDate: string,
  meetList: any[]
};

export function groupBy(list: any, property: string) {
  let groupedArray: any = [];
  let uniquePropertyList: any = [];
  list.forEach((item: any) => {
    if (uniquePropertyList.indexOf(item[property]) === -1) {
      uniquePropertyList.push(item[property]);
    }
  });
  uniquePropertyList.map(function (meetDate: string) {
    let obj: check = {
      meetDate: meetDate,
      meetList: []
    };
    obj.meetList.push(...list.filter((row: any) => row[property] === meetDate));
    groupedArray.push(obj);
  }, {});
  return groupedArray;
}