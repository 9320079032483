import React from 'react';
import { useNavigate } from "react-router-dom";

function LandingPageFooterComponent() {

  const navigate = useNavigate();

  const routeChange = (path: string) => {
    navigate(path);
  }

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="mt-auto">
      <div className="container-fluid bg-dark mt-5 footer-margin-top px-0 footor-top-section">
        <div className="container">
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-4 mt-3 mb-3'>
                <img className="img-fluid" src={require('../../Assets/Images/bot.png')} alt="logo" />
              </div>
              <div className='col-lg-4 col-md-3 col-sm-4 mt-3 mb-3'>
                <h3>Help</h3>
                <span onClick={() => routeChange("/support")} className='footer-link' > Support </span> <span className='footer-line'> | </span>                
                <span onClick={() => routeChange("/contactus")} className='footer-link mt-2'> Contact Us</span>
              </div>
              <div className='col-lg-4 col-md-5 col-sm-4 mt-3 mb-3'>
                <h3>Quick Links</h3>
                <span onClick={() => openInNewTab("termsandconditions")} className='footer-link' > Terms and Conditions</span>  <span className='footer-line'> | </span>  
                <span onClick={() => openInNewTab("privacypolicy")} className='footer-link mt-2'> Privacy Policy</span> <span className='footer-line'> | </span> <br/>
                <span onClick={() => openInNewTab("enduserlicenseagreement")} className='footer-link mt-2'> End User License Agreement</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark px-0">
        <div className="container px-0 pt-3 pb-3">
          <h5 className="h5 text-center py-2 my-0 text-white footer-text">©2024 Meetstand, All rights reserved
          </h5>
        </div>
      </div>
    </div>
  );
}

export default LandingPageFooterComponent;