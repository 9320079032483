import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userProfile } from '../Types/MeetsType';
import { getUserData, updateUserDetails } from "../Services/Api/meetsService";
import { trackException } from '../Utils/TrackExceptionUtility';


const initialState: userProfile = {
  memberName: "",
  aadObjectId:"",
  email:"",
  timeZone:""
};

export const fetchUserData = createAsyncThunk(
  "profile/GetUser",
  async (args: string, thunkAPI) => {
    try {
      const response = await getUserData(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }

);
export const updateUserData = createAsyncThunk(
  "profile/updateUserDetails",
  async (args: any, thunkAPI) => {
    try {
      const response = await updateUserDetails(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  });

const profileSlice = createSlice({
  name: "profileReducer",
  initialState: initialState,
  reducers: {   
    updateuserTimelineData: (state: any, action: PayloadAction<any>) => {
      state.userData.timeZone = action.payload;
    },
    updateuserTimeZone: (state: any, action: PayloadAction<any>) => {
      state.meetsLogFilterTimeZone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("profile/GetUser/fulfilled", (state: any = initialState, action: any) => {
        state.userData = action.payload;
        state.meetsLogFilterTimeZone = action.payload.timeZone;
      })
      .addCase("profile/GetUser/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching user data.", action.payload.errorMessage);
      })
      .addCase("profile/updateUserDetails/fulfilled", (state: any = initialState, action: any) => {
      })
      .addCase("profile/updateUserDetails/rejected", (state: any, action: any) => {
        if (action.payload) {
          trackException("An error occurred while update profile.", action.payload.errorMessage);
        } else {
          trackException("An error occurred while update profile.", action.error.message);
        }
      })
  },
});

export const {
  updateuserTimelineData,
  updateuserTimeZone

} = profileSlice.actions;
export default profileSlice.reducer;
