import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import {
  updateMeetsData,
  updatePromptQuestions,
  addPromptQuestion,
  removePromptQuestion,
  updatePrompts,
  AddOrUpdateUserBasedPrompts,
  updateUserBasedPrompts
} from "../../Store/manageSlice";
import { requiredFields, Sortable_additionalProps } from "../../Types/MeetsType";
import swal from "sweetalert";
import { findHelpText } from "../../Utils/CommonUtils"
import ErrorMessage from "./ErrorMessage";
import { updateBlockerData } from "../../Store/commonSlice";
import { SortableContainer, SortableContainerProps, SortableElement, SortableElementProps } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import PlaceHolder from "../../Components/Meets/PlaceHolder"
import { getInitials } from "../../Utils/CommonUtils";
import { useParams } from "react-router-dom";

const SortableList:
  React.ComponentClass<SortableContainerProps & Sortable_additionalProps, any>
  = SortableContainer(({ items, register, updatePromptQuestionCallBack, removeQuestionCallBack, errors, aadObjectId, promptName }: Sortable_additionalProps) => {
    return (
      <div>
        {items.map((value: any, index: any) => (
          <SortableItem
            key={`item-${value.questionIndex}`} index={index} value={value} collection={aadObjectId}
            register={register} errors={errors} updatePromptQuestionCallBack={updatePromptQuestionCallBack}
            removeQuestionCallBack={removeQuestionCallBack} aadObjectId={aadObjectId} promptName={promptName} />
        ))}
      </div>
    );
  });

const SortableItem:
  React.ComponentClass<SortableElementProps & Sortable_additionalProps, any> =
  SortableElement(({ value, register, errors, updatePromptQuestionCallBack, removeQuestionCallBack, aadObjectId, promptName }: Sortable_additionalProps) => {
    return (
      <div style={{ margin: '15px 6px' }}>
        <div className={"position-relative " + (!!errors?.question?.[value.questionIndex] ? 'active_validation' : '')}
          key={value.questionIndex}>
          <input type="text" className="form-control form-control-lg" placeholder="Enter a prompt" value={value.question}
            name={`question.${value.questionIndex}` as const} id={`question.${value.questionIndex}` as const}
            {...register(`question.${value.questionIndex}` as const, {
              required: true, maxLength: 150,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => updatePromptQuestionCallBack(event, { questionIndex: value.questionIndex, aadObjectId, promptName })
            })} />
          <div className="d-flex position-absolute" style={{ top: "1px", right: "1px" }} onClick={() => { removeQuestionCallBack({ promptName: promptName, aadObjectId: aadObjectId, questionIndex: value.questionIndex }) }}>
            <button type="button" className="fa-solid fa-trash" style={{ border: 'none', color: '#324e63', background: "#EEEEEE", padding: "15px", borderRight: "1px solid #ced4da" }}></button>
            <span title="Drag" style={{ background: "#EEEEEE", padding: "10px", borderRadius: "0px 4px 4px 0px", color: '#324e63' }}><img src={require('../../Assets/Images/Draggable.png')} width='20' alt="Drag Icon" /></span>
          </div>
        </div>
        {!!errors?.question?.[value.questionIndex] &&
          <ErrorMessage validationType={errors.question?.[value.questionIndex].type} fieldName="Prompt" maxLength="150" />}
      </div>
    )
  })


function MeetsPrompts(props: any) {

  const { register, errors, resetField, userbasedPromptsCheckUpdate } = props;
  const dispatch = useDispatch<any>();
  const meets = useSelector(
    (state: any) => state.manageReducer.meetsData
  );
  const { meetsId } = useParams<"meetsId">();
  const updateMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateMeetsData({ name: event.target.name, value: event.target.value }))
  };

  const updatePromptQuestionCallBack = (event: React.ChangeEvent<HTMLInputElement>, prompts: any) => {
    dispatch(updatePromptQuestions({ question: event.target.value, prompts }))
    dispatch(updateBlockerData({ isModified: true }))
  };

  const addNewPrompt = async (payload: any) => {
    await dispatch(addPromptQuestion(payload));
  }
  const removeQuestionCallBack = (payload: any) => {
    swal({
      title: "Warning", text: "Are you sure that you want to delete this prompt?", icon: "warning",
      dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
    }).then((willDelete: any) => {
      if (willDelete) {
        swal({ title: "Success", text: "The selected prompt has been deleted successfully", icon: "success", timer: 1000, buttons: [false] })
        dispatch(removePromptQuestion(payload));
        resetField(`question.${payload.questionIndex}`);
      }
    });
  }
  const isDisableAddPrompt = (payload: any) => {
    if (payload.promptName === 'defaultPrompt') {
      let maxQuestionIndex = Math.max(...meets.questions.map((questions: any) => questions.questionIndex));
      let IsValidQuestion = meets.questions.find((questions: any) => questions.questionIndex === maxQuestionIndex);
      return (IsValidQuestion?.question === '' || (meets.questions.length.toString() === process.env.REACT_APP_MAX_PROMPTS_LENGTH)) ? true : false;
    } else {
      let userBasedQuestion = meets.userBasedPrompts.filter((user: any) => user.aadObjectId == payload.aadObjectId)[0];
      if (userBasedQuestion) {
        let maxQuestionIndex = Math.max(...userBasedQuestion.prompts.map((questions: any) => questions.questionIndex));
        let IsValidQuestion = userBasedQuestion.prompts.find((questions: any) => questions.questionIndex === maxQuestionIndex);
        return (IsValidQuestion?.question === '' || (userBasedQuestion.prompts.length.toString() === process.env.REACT_APP_MAX_PROMPTS_LENGTH)) ? true : false;
      }
      else {
        return false;
      }
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    dispatch(updatePrompts(arrayMoveImmutable(meets.questions, oldIndex, newIndex)));
    dispatch(updateBlockerData({ isModified: true }))
  }
  const onSortEndForUserBased = ({ oldIndex, newIndex, collection }: any) => {
    let userBasedPrompts = JSON.parse(JSON.stringify(meets.userBasedPrompts));
    let userPromptsPrompts = userBasedPrompts.find((x: any) => x.aadObjectId === collection);
    let updatedPrompts = arrayMoveImmutable(userPromptsPrompts.prompts, oldIndex, newIndex);
    userPromptsPrompts.prompts = updatedPrompts;

    dispatch(updateUserBasedPrompts(userBasedPrompts));
    dispatch(updateBlockerData({ isModified: true }));
  }

  const [isUserPromptChecked, setIsUserPromptChecked] = useState(false);

  const [selectedUserPrompt, setselectedUserPrompt] = useState();

  let memberList = useSelector(
    (state: any) => state.manageReducer.meetsData.meetMembers
  )
  
  useEffect(() => {
    if (meetsId && meets.userBasedPrompts && meets.userBasedPrompts.length > 0) {
      setIsUserPromptChecked(true);
      userbasedPromptsCheckUpdate(true); 
      let filtersMembers = meets.userBasedPrompts && memberList.filter((user: any) => meets.userBasedPrompts.find((filter: any) => filter.aadObjectId === user.aadObjectId));
      if(filtersMembers && filtersMembers.length > 0) {
        setselectedUserPrompt(filtersMembers[0].aadObjectId)
      }
    }
  },[meetsId])

  const membersAdd = (event: any) => {
    dispatch(AddOrUpdateUserBasedPrompts({aadObjectId:event.target.value, isChecked:event.target.checked }));
  }

  const userBasedPromptsCheckChange = async (checked : boolean) => {
    setIsUserPromptChecked(checked);
    userbasedPromptsCheckUpdate(checked); 
}


  return (
    <div className="mb-3">
      <div>
        <h2 className="h2 mt-4 d-flex align-items-center">Welcome Message<div className="question-icon ms-2 position-relative">
          <span className="tooltips" data-tool={findHelpText("introMessage")}>i</span></div></h2>
        <div className={"position-relative mt-3 " + (errors.welcomeMessage ? 'active_validation' : '')}>
          <textarea className="form-control form-control-lg mt-2" id="welcomeMessage" value={meets.welcomeMessage}
            name="welcomeMessage" placeholder="Type your welcome message or leave blank for default..."
            {...register('welcomeMessage', {
              maxLength: 250,
              onChange: (event: any) => updateMessage(event),
            })}
          ></textarea>
          {errors.welcomeMessage && <ErrorMessage validationType={errors.welcomeMessage.type} fieldName="Welcome Message" maxLength="250" />}
        </div>
        <div>
        <PlaceHolder type="welcomeMessage" />
        </div>
      </div>

      <div className="mt-3">
        <h2 className="h2 mt-12 d-flex align-items-center">Prompts

          <div className="question-icon ms-2 position-relative">
            <span className="tooltips" data-tool={findHelpText("prompts")}>i</span></div>
          <button type="button" className="prompt-form-add ms-2" style={{ opacity: isDisableAddPrompt({ aadObjectId: '', promptName: "defaultPrompt" }) ? '0.6' : '1' }} disabled={isDisableAddPrompt({ aadObjectId: '', promptName: "defaultPrompt" })}
            onClick={() => addNewPrompt({ aadObjectId: '', promptName: "defaultPrompt" })}>Add prompt
            <i className="fa-sharp fa-solid fa-plus ms-1"></i></button>
        </h2>

        {meets.questions && meets.questions.length === 0 && <div style={{ textAlign: 'center', margin: '10px auto' }}>No Prompts</div>}

        <SortableList
          items={meets.questions} onSortEnd={onSortEnd}
          register={register} errors={errors}
          updatePromptQuestionCallBack={updatePromptQuestionCallBack} removeQuestionCallBack={removeQuestionCallBack}
          aadObjectId={''} promptName={"defaultPrompt"}
          transitionDuration={700} helperClass="question_draggable"
        />

        {meets.questions.length === 0 && <ErrorMessage validationType='required' fieldName="Prompt" />}
        {meets.questions.length === 10 && <span className="error_message">Maximum {process.env.REACT_APP_MAX_PROMPTS_LENGTH} prompts allowed.</span>}
      </div>

      <div className="user-based-prompts-toggle mt-3" style={{margin:'15px 3px 0px'}}>
        <div className="d-flex align-items-center">
          <h2 className="h2 mt-12 d-flex align-items-center">User's Prompts
          <div className="question-icon ms-2 position-relative">
            <span className="tooltips" data-tool={findHelpText("usersPrompts")}>i</span></div>
            </h2>
          <div className="ms-auto">
              <input type="checkbox" className='checkbox-ms-custom' id="isUserPromptChecked" style={{display:"none"}} checked={isUserPromptChecked} 
                onChange={(event) => userBasedPromptsCheckChange(event.currentTarget.checked)}/>
              <label htmlFor="isUserPromptChecked" className="toggle"><span></span></label>       
            </div>
        </div>
        
        {isUserPromptChecked && 
          <div className="user-based-prompt-tab">
            {memberList.map((member: any, index: any) =>
              <div key={`item-${index}`} style={{ display: "flex", flexDirection:"column", margin: "0 10px 10px 0" }}>
                <div className={"user-based-prompt-tile " + (member.isUserBased && selectedUserPrompt === member.aadObjectId ? 'user-based-prompt-active' : '')}
                  onClick={()=>setselectedUserPrompt(member.aadObjectId)}>
                  <div className="custom-checkbox">
                    <input type="checkbox" value={member.aadObjectId} checked={member.isUserBased} onChange={(event) => membersAdd(event)} />
                    <svg viewBox="0 0 35.6 35.6">
                      <circle className="background" cx="17.8" cy="17.8" r="17.8"></circle>
                      <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                      <polyline className="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                    </svg>
                  </div>
                  <div className="user-based-prompt-name" title={member.name} data-name={getInitials(member.name)}></div>
                </div>
                <div className='user-based-user-name' title={member.name}>{member.isUserBased && selectedUserPrompt === member.aadObjectId ? member.name : ''}</div>
              </div>
            )}
          </div>    
        }
      </div>

      {isUserPromptChecked && meets.userBasedPrompts.map((userBasedPrompt: any, index: any) =>
        <div className="user-based-questions-panel" style={{ display: selectedUserPrompt ===  userBasedPrompt.aadObjectId ? 'block' : 'none' }} key={'userBasedQuestions'+index}>
          <h5 className="h4 d-flex align-items-center">Prompts
            <div className="question-icon ms-2 position-relative">
              <span className="tooltips" data-tool={findHelpText("prompts")}>i</span></div>
            <button type="button" className="prompt-form-add ms-2" style={{ opacity: isDisableAddPrompt({ aadObjectId: userBasedPrompt.aadObjectId, promptName: "userBasedPrompt" }) ? '0.6' : '1' }} disabled={isDisableAddPrompt({ aadObjectId: userBasedPrompt.aadObjectId, promptName: "userBasedPrompt" })}
              onClick={() => addNewPrompt({ aadObjectId: userBasedPrompt.aadObjectId, promptName: "userBasedPrompt" })}>Add prompt
              <i className="fa-sharp fa-solid fa-plus ms-1"></i></button></h5>
          {userBasedPrompt.prompts && userBasedPrompt.prompts.length === 0 && <div style={{ textAlign: 'center', margin: '10px auto' }}>No Prompts</div>}
          <SortableList
            items={userBasedPrompt.prompts} onSortEnd={onSortEndForUserBased}
            register={register} errors={errors}
            updatePromptQuestionCallBack={updatePromptQuestionCallBack} removeQuestionCallBack={removeQuestionCallBack}
            aadObjectId={userBasedPrompt.aadObjectId} promptName={"userBasedPrompt"}
            transitionDuration={700} helperClass="question_draggable"
          />
          {userBasedPrompt.prompts.length === 0 && <ErrorMessage validationType='required' fieldName="Prompt" />}
          {userBasedPrompt.prompts.length === 10 && <span className="error_message">Maximum {process.env.REACT_APP_MAX_PROMPTS_LENGTH} prompts allowed.</span>}
        </div>
      )}
      

      <div>
        <h2 className="h2 mt-4 d-flex align-items-center">Thank You Message<div className="question-icon ms-2 position-relative">
          <span className="tooltips" data-tool={findHelpText("outroMessage")}>i</span></div></h2>
        <div className={"position-relative mt-3 " + (errors.thankyouMessage ? 'active_validation' : '')}>
          <textarea className="form-control form-control-lg mt-2  position-relative" id="thankyouMessage" value={meets.thankyouMessage}
            name="thankyouMessage" placeholder="Type your thank you message or leave blank for default..."
            {...register('thankyouMessage', {
              maxLength: 250,
              onChange: (event: any) => updateMessage(event)
            })}></textarea>
          {errors.thankyouMessage && <ErrorMessage validationType={errors.thankyouMessage.type} fieldName="Thankyou Message" maxLength="250" />}
        </div>
        <PlaceHolder type="thankyouMessage" />
      </div>

    </div>
  );
}

export default MeetsPrompts;