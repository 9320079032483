import React from 'react';
function EndUserLicenseAgreementComponent() {
  return (
    <div className="mt-auto">
     <div className="container-fluid bg-light px-0">
        <div className="container py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h1 className="h1 my-0 fw-bold">End User License Agreement</h1>
            </div>
            <img className="img-fluid" src={require('../../Assets/Images/TermsAndConditions.png')} alt="logo" style={{width:"35%"}} />
          </div>
        </div>
        <div className="container py-4 privacy-and-terms-pages">        
      <h1 className="h1 my-0 fw-bold mb-4">End User License Agreement</h1>  
      <p className="mb-3"><b>THIS END USER LICENSE AGREEMENT</b> (the "Agreement") is entered into by and between<b> Infinire Innovative Software Solutions</b>, a [type of legal entity, e.g., corporation] duly established and existing under the laws of [jurisdiction], having its principal place of business at [address] (hereinafter referred to as the "Licensor"), and the entity or individual installing or using the Software (hereinafter referred to as the "Licensee").</p>
      <p className="mb-2">WHEREAS, the Licensor has developed certain computer software and related explanatory written materials as may be updated from time to time (collectively, the "Software");</p>
      <p className="mb-2">NOW, THEREFORE, in consideration of the mutual promises set forth herein and for other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows:</p>
      <p className="mb-3 mt-3"><b>1. LICENSE GRANT</b> Licensor hereby grants Licensee a non-exclusive, non-transferable right to use the Software as per the terms and conditions outlined in this Agreement.</p>
      <p className="mb-3 mt-3"><b>2. OWNERSHIP</b> All title, ownership rights and intellectual property rights in and to the Software (including but not limited to any images, photographs, animations, video, audio, music, text incorporated into the Software), and any copies of the Software are owned by the Licensor or its suppliers.</p>
      <p className="mb-3 mt-3"><b>3. COPYRIGHT</b> The Software is protected by copyright and other intellectual property laws and treaties. Licensee must treat the Software like any other copyrighted material.</p>
       <p className="mb-3 mt-3"><b>4. PROHIBITED USES</b> Unless expressly permitted by applicable law, Licensee shall not, and shall not permit others to: (i) modify, translate, reverse engineer, decompile, disassemble or create derivative works based on the Software; (ii) circulate or post any copy of the Software on any network or distribute the Software to others; (iii) rent, lease or grant sublicenses or other rights to the Software; or (iv) delete or alter any text, copyright or other proprietary notices within the Software.</p>
       <p className="mb-3 mt-3"><b>5. UPDATES</b> Licensor may, from time to time, update or modify the Software, release new versions of the Software, or create new modules related thereto, each of which may, at Licensor's discretion, be included within the license described herein.</p>
       <p className="mb-3 mt-3"><b>6. TERMINATION</b> This Agreement shall continue until terminated by either party. Licensee may terminate this Agreement at any time by destroying or returning to Licensor all copies of the Software. This Agreement will terminate immediately without notice from Licensor if Licensee fails to comply with any provision of this Agreement.</p>
       <p className="mb-3 mt-3"><b>7. LIMITATION OF LIABILITY</b> In no event will Licensor or its suppliers be liable for any lost profits or other incidental or consequential damages (even if Licensor has been advised of the possibility of such damages), or for any claim by any party other than Licensee.</p>
       <p className="mb-3 mt-3"><b>8. DISCLAIMER OF WARRANTIES</b> The Software is provided on an "AS IS" basis. Licensor expressly disclaims all other warranties, express or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose.</p>
       <p className="mb-3 mt-3"><b>9. GOVERNING LAW AND DISPUTE RESOLUTION</b> This Agreement shall be governed by and construed under the laws of [jurisdiction]. All disputes arising out of or in connection with this Agreement shall be finally settled under the Rules of Arbitration of [arbitration institution] by one or more arbitrators appointed in accordance with the said rules.</p>
       <p className="mb-3 mt-3 text-bold">10. DATA PROTECTION AND PRIVACY</p>
       <p className="mb-3 mt-3"><b>10.1 Collection of Information</b> The Software may collect certain personal information from the Licensee. The type of information collected by the Software may include, but is not limited to:</p>
       <p className="mb-2">(i) Contact details such as name, email address, postal address, and telephone number;</p>
       <p className="mb-2">(ii) Usernames and passwords;</p>
       <p className="mb-2">(iii) Payment information such as credit or debit card number;</p>
       <p className="mb-2">(iv) IP addresses;</p>
       <p className="mb-2">(v) Device information;</p>
       <p className="mb-2">(vi) Information from cookies, pixels, and other technologies.</p>
       <p className="mb-3 mt-3"><b>10.2 Use of Information </b>The Licensor may use the information collected for the following purposes:</p>
       <p className="mb-2">(i) To provide, maintain, protect and improve the Software;</p>
       <p className="mb-2">(ii) To protect the Licensor and the users of the Software;</p>
       <p className="mb-2">(iii) To comply with applicable laws and regulations;</p>
       <p className="mb-2">(iv) To communicate with the Licensee in relation to the Software.</p>
       <p className="mb-3 mt-3"><b>10.3 Disclosure of Information</b> Licensor may disclose Licensee’s personal information to its affiliates, service providers, and other third parties under certain circumstances as described in Licensor’s Privacy Policy.</p>
       <p className="mb-3 mt-3"><b>10.4 Privacy Policy</b>  All personal information that the Licensor collects, uses, and discloses will be handled in accordance with the Licensor's Privacy Policy, which is available at [link to privacy policy]. By accepting this Agreement, the Licensee acknowledges that they have read and understood the Privacy Policy, and agrees to its terms.</p>
       <p className="mb-3 mt-3 text-bold">11. SECURITY</p>
       <p className="mb-3 mt-3"><b>11.1 Security Measures</b> Licensor takes the security of its Software and the privacy of its users very seriously. Licensor employs reasonable security measures designed to protect the security of all information collected through the Software. However, the security of information transmitted through the internet can never be guaranteed. Licensee is responsible for maintaining the security of their own account, including but not limited to the confidentiality of their login credentials.</p>
        <p className="mb-3 mt-3"><b>11.2 Reporting of Security Vulnerabilities</b>  If Licensee discovers a vulnerability in the Software, they are obligated to refrain from exploiting it and to report it immediately to Licensor. Licensor will address any reported vulnerabilities in accordance with its internal security procedures.</p>
        <p className="mb-3">By clicking "I agree" or by installing, copying, or otherwise using the Software, Licensee agrees to be bound by the terms of this Agreement. If Licensee does not agree to the terms of this Agreement, do not install or use the Software.</p>
      </div>
      </div>
    </div>
  );
}

export default EndUserLicenseAgreementComponent;