import React, { useState, ChangeEvent, useRef } from "react";
import "./FileUpload.css";
import { useDispatch } from "react-redux";
import { AddHolidaysFromJson } from "../../../Store/holidayTemplateSlice";
import { updateBlockerData } from "../../../Store/commonSlice";
import swal from "sweetalert";

const FileUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    setSuccessMessage("");
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setErrorMessage("");
      handleUpload(selectedFile);
    } else {
      swal({
        title: "Error",
        text: "Only PDF files are allowed. Please upload a PDF file.",
        icon: "error",
        timer: 4000,
        buttons: [false],
      });
      resetFileInput();
    }
  };

  const dispatch = useDispatch();

  const handleUpload = (file: File) => {
    const formData = new FormData();
    formData.append("pdfFile", file);

    const xhr = new XMLHttpRequest();
    const baseurl = process.env.REACT_APP_API_BASE_URL;
    xhr.open("POST", baseurl + "HolidayTemplate/ExtractHolidays", true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentCompleted);
      }
    };

    xhr.onload = async () => {
      if (xhr.status === 200) {
        
        const resp = JSON.parse(xhr.responseText);
        if (!resp || !resp?.holidays ||  resp?.holidays?.length === 0) {
          swal({
            title: "Warning",
            text: "The file does not contain any holiday information.",
            icon: "warning",
            timer: 5000,
            buttons: [false],
          });
        } else {
          swal({
            title: "Success",
            text: "We have successfully extracted the holidays from the file.",
            icon: "success",
            timer: 3000,
            buttons: [false],
          });
          dispatch(AddHolidaysFromJson({ holidays: resp.holidays }));
          dispatch(updateBlockerData({ isModified: true }));
        }
      } else {
        swal({
          title: "Error",
          text: "We encountered an error while uploading the file. Please try again.",
          icon: "error",
          timer: 3000,
          buttons: [false],
        });
        resetFileInput();
      }
      resetFileInput()
    };

    xhr.onerror = () => {
      swal({
        title: "Error",
        text: "We encountered an error while uploading the file. Please try again.",
        icon: "error",
        timer: 3000,
        buttons: [false],
      });
      resetFileInput();
    };

    xhr.send(formData);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    
    setUploadProgress(0);
    setSuccessMessage('');
    setFile(null);
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="file-upload-Position-top position-absolute"
        style={{ top: "-65px", right: 0 }}
      >
        <input
          type="file"
          id="file-input"
          onChange={handleFileChange}
          accept="application/pdf"
          className="file-input d-none"
          ref={fileInputRef} 
        />
        <label
          htmlFor="file-input"
          className="btn float-end fw-bold newstandup_cancel_button mb-2"
        >
         <span className="show-icon">Upload PDF</span>
         <span className="pdf-icon" ><i className="fa-solid fa-upload"></i></span>
        </label>
      </div>
      {file && (
        <div className="file-upload-container">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {!successMessage && (
            <div className="upload-section">
              <div className="d-flex gap-2 justify-content-between">
                <p className="text-truncate mb-0 w-75 d-flex" title={file.name}>
                  {file.name} 
                  {uploadProgress === 100 &&  (
                  <span className="success-message"> - File Uploaded Successfully, Analyzing Data...</span>
                 )}
                </p>
                <p className="upload-progress mb-1">                
                  {uploadProgress}%
                </p>
              </div>
              <div className="d-flex gap-2">
                <div className="progress-bar-background">
                  <div
                    className="progress-bar mb-0"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
              </div>
            </div>
          )}
          {successMessage && (
            <p className="success-message mb-0">{successMessage}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
