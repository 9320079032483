import { weekList } from "../Constant/ApplicationConstant"


export function useMapWeekDaysHook() {
  function mapWeekDays(weeks: any) {
    let result = "";
    var filteredWeekDays = weekList.filter(week => weeks.includes(week.value));
    if (filteredWeekDays && filteredWeekDays.length > 0) {
      let weekCount = filteredWeekDays.length > 2 ? 3 : filteredWeekDays.length;

      switch (weekCount) {
        case 1:
          result = "Weekly on " + filteredWeekDays[0].day;
          break;
        case 2:
          result = "Weekly on " + filteredWeekDays[0].day + " and " + filteredWeekDays[1].day;
          break;
        case 3:
          if (checkWeekDaysOrder(filteredWeekDays)) {
            result = "Weekly from " + filteredWeekDays[0].day + " to " + filteredWeekDays[filteredWeekDays.length - 1].day;
          }
          else {
            filteredWeekDays.forEach((element, index) => {
              if ((index + 1) !== filteredWeekDays.length) {
                result = !result ? element.day : (result + ", " + element.day);
              }
              else {
                result = result + " and " + element.day
              }
            });
            result = "Weekly on " + result;
          }
          break;
        default:
          result = 'Invalid day';
      }

    }
    return result;
  }

  function checkWeekDaysOrder(days: any) {
    let weekOrder = days.map((element: any) => element.key);
    if (weekOrder) {
      let initialCount = days[0]["key"];
      let totalCount = initialCount + weekOrder.length;
      for (let n = initialCount; n < totalCount; n++) {
        if (weekOrder.indexOf(n) === -1)
          return false;
      }
      return true;
    }
  }

  return {
    mapWeekDays
  }
}