import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getHolidayTemplateByTemplate, getHolidayTemplatesByUserId, upsertHolidayTemplate, getHolidayTemplateByTeamsId, deleteHolidayTemplate } from "../Services/Api/templateService";
import { trackException } from '../Utils/TrackExceptionUtility';
import { holidayTemplateInput } from "../Types/templateType";

const loadHolidayTemplate: holidayTemplateInput = {
    templateId: "",
    templateName: "",
    teamsId: "",
    type: "HolidayTemplate",
    holidays: [],
    createdByUserId: ""
}

const initialState: any = {
    holidayTemplate: loadHolidayTemplate
};

export const fetchUserHolidayTemplateList = createAsyncThunk(
    "holidayTemplate/getHolidayTemplatesByUserId",
    async (postObj: any, thunkAPI) => {
        try {
            const response = await getHolidayTemplatesByUserId(postObj);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }

);

export const fetchHolidayTemplate = createAsyncThunk(
    "holidayTemplate/getHolidayTemplate",
    async (templateId: string, thunkAPI) => {
        try {
            const response = await getHolidayTemplateByTemplate(templateId);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }

);

export const fetchHolidayTemplateByTeamsId = createAsyncThunk(
    "holidayTemplate/getHolidayTemplateByTeamsId",
    async (teamsIds: string, thunkAPI) => {
        try {
            const response = await getHolidayTemplateByTeamsId(teamsIds);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }

);

export const createAndUpdateHolidayTemplate = createAsyncThunk(
    "holidayTemplate/upsertHolidayTemplate",
    async (holidayTemplateInput: holidayTemplateInput, thunkAPI) => {
        try {
            const response = await upsertHolidayTemplate(holidayTemplateInput);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });

export const deleteHolidayTemplateById = createAsyncThunk(
    "holidayTemplate/deleteHolidayTemplate",
    async (holidayTemplate: any, thunkAPI) => {
        try {
            const response = await deleteHolidayTemplate(holidayTemplate.templateId, holidayTemplate.isDeleted);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const holidayTemplateSlice = createSlice({
    name: "holidayTemplateReducer",
    initialState: initialState,
    reducers: {

        showAndHidePageLoader: (state: any, action: PayloadAction<any>) => {
            state.pageLoader = action.payload.value
        },
        loadDefaultEmptyHoliday: (state: any) => {
            state.holidayTemplate.holidays = [];
            state.holidayTemplate.holidays.push({ holidayName: "", holidayMonth: "",holidayDate:"", notes: "", holidayIndex: 1 })
        },
        addDefaultEmptyHoliday: (state: any) => {
            let maxIndex = state.holidayTemplate.holidays && state.holidayTemplate.holidays.length > 0 ? Math.max(...state.holidayTemplate.holidays.map((x: any) => x.holidayIndex)) : 0;
            state.holidayTemplate.holidays.push({ holidayName: "", holidayMonth: "",holidayDate:"", notes: "", holidayIndex: maxIndex + 1 })
        },
        updateHolidayTemplateData: (state: any, action: PayloadAction<any>) => {
            state.holidayTemplate[action.payload.name] = action.payload.value
        },
        updateHolidays: (state: any, action: PayloadAction<any>) => {
            let index = state.holidayTemplate.holidays.findIndex((x: any) => x.holidayIndex === action.payload.index);
            if (index !== -1) {
                state.holidayTemplate.holidays[index][action.payload.name] = action.payload.value;
                if (action.payload.name === "holidayMonth" || action.payload.name === "holidayDate") {
                    state.holidayTemplate.holidays[index].date = state.holidayTemplate.holidays[index].holidayMonth + "/" + state.holidayTemplate.holidays[index].holidayDate
                }
            }

        },
        removeHolidays: (state: any, action: PayloadAction<any>) => {
            let index = state.holidayTemplate.holidays.findIndex((x: any) => x.holidayIndex === action.payload.holidayIndex);
            if (index !== -1) {
                state.holidayTemplate.holidays.splice(index, 1);
            }
        },
        clearHolidayTemplateData: (state: any) => {
            state.holidayTemplate = loadHolidayTemplate;
        },
        setHolidaysFromMeets: (state: any, action: PayloadAction<any>) => {
            state.holidayTemplate.holidays = action.payload.holidays;
            state.holidayTemplate.templateId = action.payload.templateId;
        },
        AddHolidaysFromJson: (state, action: PayloadAction<{ holidays: any }>) => {
            if (Array.isArray(action.payload.holidays)) {
                const totalHolidays = state.holidayTemplate.holidays.length;
                let newHolidays = action.payload.holidays.map((holiday, index) => {
                    let [, month, day] = holiday.Date.split(/[-/]/);
                    if( state.holidayTemplate.holidays.find((x: any) =>  x.holidayMonth === month && x.holidayDate === day)){
                        return null;
                    }
                    return {
                        holidayName: holiday.HolidayName,
                        holidayMonth: month,
                        holidayDate: day,
                        date: month +"/"+ day,
                        notes: holiday.Description,
                        holidayIndex: index + 1 + totalHolidays ,
                    }
                });
                newHolidays = newHolidays.filter((x: any) => x && x.holidayName && x.holidayName.length > 0);

                state.holidayTemplate.holidays = [...state.holidayTemplate.holidays.filter((x: any) => x.holidayName.length > 0), ...newHolidays]
                 .sort((a: any, b: any) => { return a.holidayMonth - b.holidayMonth || a.holidayDate - b.holidayDate });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("holidayTemplate/getHolidayTemplatesByUserId/fulfilled", (state: any, action: any) => {
                state.userHolidayTemplateList = action.payload;
            })
            .addCase("holidayTemplate/getHolidayTemplatesByUserId/rejected", (state: any, action: any) => {
                trackException("An error occurred while fetching user holiday template list.", action.payload.errorMessage);
            })
            .addCase("holidayTemplate/getHolidayTemplate/fulfilled", (state: any = initialState, action: any) => {
                action.payload.holidays.forEach((element: any, index: number) => {
                    element.holidayIndex = index + 1;
                    const [month, date] = element.date.split("/");
                    element.holidayMonth = month;
                    element.holidayDate = date;
                });
                state.holidayTemplate = action.payload;
            })
            .addCase("holidayTemplate/getHolidayTemplate/rejected", (state: any, action: any) => {
                trackException("An error occurred while getHolidayTemplate.", action.payload.errorMessage);
            })
            .addCase("holidayTemplate/getHolidayTemplateByTeamsId/fulfilled", (state: any, action: any) => {
                state.teamsHolidayTemplateList = action.payload;
            })
            .addCase("holidayTemplate/getHolidayTemplateByTeamsId/rejected", (state: any, action: any) => {
                trackException("An error occurred while fetching teams holiday template list.", action.payload.errorMessage);
            })
            .addCase("holidayTemplate/upsertHolidayTemplate/fulfilled", (state: any, action: any) => {

            })
            .addCase("holidayTemplate/upsertHolidayTemplate/rejected", (state: any, action: any) => {
                trackException("An error occurred while getHolidayTemplate.", action.payload.errorMessage);
            })
            .addCase("holidayTemplate/deleteHolidayTemplate/rejected", (state: any, action: any) => {
                trackException("An error occurred while delete holiday template.", action.payload.message);
            })
    },
});

export const {
    showAndHidePageLoader,
    loadDefaultEmptyHoliday,
    updateHolidayTemplateData,
    updateHolidays,
    clearHolidayTemplateData,
    removeHolidays,
    addDefaultEmptyHoliday,
    setHolidaysFromMeets,
    AddHolidaysFromJson

} = holidayTemplateSlice.actions;
export default holidayTemplateSlice.reducer;
