import axios from "axios";
import { meetsTimelinePostInput, userProfile,tenant } from "../../Types/MeetsType";
import { boolean } from "yargs";

export async function getMeetsList(userDetail: any) {
    return await axios.get("MeetStandup/GetMeetsList?teamsId=" + userDetail.teamsId + "&userId="+userDetail.userAdObjectId);
}

export async function deleteMeeting(meetsId: string, isDeleted: boolean) {
    return await axios.patch("MeetStandup/DeleteMeets?meetsId=" + meetsId + "&isDeleted=" + isDeleted);
}

export async function pauseMeeting(meetsId: string, isPaused: boolean) {
    return await axios.patch("MeetStandup/PauseMeets?meetsId=" + meetsId + "&isPaused=" + isPaused);
}

export async function getManageMeets() {
    return await axios.get("createAndManageMeets");
}

export async function getMemberList(teamsId: string, timeZone: string) {
    return await axios.get("/Graph/GetMembers?teamsId=" + teamsId+"&timeZone="+timeZone);
}

export async function getTimeZoneList() {
    return await axios.get("Lookup/GetTimeZones");
}

export async function getChannelList(teamsId: string) {
    return await axios.get("Graph/GetChannels?teamsId=" + teamsId);
}

export async function saveMeetsData(request: any) {
    return await axios.post("MeetStandup/UpsertMeets", request);
}

export async function getmeets(meetsId: string) {
    return await axios.get("MeetStandup/GetMeets?meetsId=" + meetsId);
}

export async function getTeamList(userDetail: any) {
    return await axios.get("Graph/GetTeamsList?tenantId="+userDetail.tenantId + "&aadObjectId="+userDetail.userAdObjectId);
}
export async function checkManagePermission(teamsId: string,userId: string) {
    return await axios.get("MeetStandup/CheckManagePermission?teamsId=" + teamsId + "&userId="+userId);
}
export async function getTenant(tenantId: string) {
    return await axios.get("Graph/GetTenant?tenantId="+ tenantId);
}
export async function getTenantDetails(tenantId: string) {
    return await axios.get("Graph/GetTenantDetails?tenantId="+ tenantId);
}
export async function createTenant(tenantId: string) {
    return await axios.get("Graph/CreateTenant?tenantId="+ tenantId);
}
export async function updateTenant(tenantData: tenant, alreadyDecrypted: boolean) {
    return await axios.post("Graph/UpdateTenant",tenantData,{params:{alreadyDecrypted:alreadyDecrypted}});
}
export async function getMeetsTimeLine(meetsInput: meetsTimelinePostInput) {
    return await axios.post("MeetsLog/GetMeetsLog", meetsInput);
}
export async function getMeetsUnReportedSummary(meetsInput: meetsTimelinePostInput) {
    return await axios.post("MeetsLog/GetUnreportedCounts", meetsInput);
}
export async function getUserData(userId: any) {
    return await axios.get("User/GetUser?userId="+userId);
}

export async function updateUserDetails(userInput: userProfile) {
    return await axios.post("User/UpdateUser", userInput);
}

export async function getPlaceHolderList() {
    return await axios.get("Lookup/GetPlaceHolder");
}
export async function getMeetsTimeLineSummary(meetsInput: meetsTimelinePostInput) {
    return await axios.post("MeetsLog/GetChatSummary", meetsInput);
}
export async function getMeetsConsolidateSummary(meetsInput: meetsTimelinePostInput) {
    return await axios.post("MeetsLog/GetConsolidateChatSummary", meetsInput);
}