import React,{useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { updateContactUsDetails, clearContactUsDetails, saveContactUs } from '../../Store/contactUsSlice';

function ContactUsComponent() {

  const contactUsData = useSelector(
    (state: any) => state.contactUsReducer
  );

  const [isShowSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  const dispatch = useDispatch<any>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    setValue,
    reset
} =  useForm(
    {
        mode: "all",
        defaultValues: {
          name: contactUsData.name || '',
          email: contactUsData.email || '',
          phoneNumber: contactUsData.phoneNumber || '',
          subject: contactUsData.subject || '',
          message: contactUsData.message || '',
        },
    }
  );

  const onSubmit = async () => {
    setSubmitButtonLoader(true)
    let response = await dispatch(saveContactUs(contactUsData));
    if (response.payload && response.payload.status === 200) {
      setSubmitButtonLoader(false)
      setShowSuccessMessage(true)
      reset();
      dispatch(clearContactUsDetails());
    }
  }

  const onError = (errors: any, event: any) => {
    console.log(errors, event);
  }
  
  const updateContactUs = (event:any) => { 
  
    dispatch(updateContactUsDetails({ name: event.target.name, value: event.target.value }))  
  }

  const clearContactUs = (event:any) => { 
    reset();
    dispatch(clearContactUsDetails());
  }
  const userDetails = useSelector(
    (state: any) => state.authReducer.userDetails
  );
  useEffect(() => {
    if (userDetails) {
      setValue('name', userDetails.name);
      setValue('email', userDetails.email);
      dispatch(updateContactUsDetails({ name: "name", value: userDetails.name }));
      dispatch(updateContactUsDetails({ name: "email", value: userDetails.email }));
    }
  }, [userDetails.tenantId]);

  return (
    
    <div>
      <div className="container-fluid bg-light px-0">
        <div className="container py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h1 className="h1 my-0 fw-bold">Contact Us</h1>
            </div>
            <img className="img-fluid contact-bot" src={require('../../Assets/Images/contactus.png')} alt="logo" />
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='container'>
          { !isShowSuccessMessage && 
            <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              <h4 className="h1 my-0 fw-bold mb-2 mt-4">Contact Us</h4>
              <div className='col-lg-6  col-md-12'>
                <h4 className="h4 mt-4 d-flex align-items-center fw-bold">Name<span className="text-danger ms-1">*</span></h4>
                <div className={"position-relative mt-1 " + (errors.name ? 'active_validation' : '')}>
                  <input type="text" className="form-control form-control-lg" id="name"
                  placeholder="Enter the Name" 
                  {...register('name', {
                    required: true,
                    maxLength: 50,
                    onChange: updateContactUs,
                  })}/>
                  {errors && errors.name && errors.name?.type === "required" && (
                    <span className="error_message">Name is Required</span>
                  )}
                  {errors && errors.name && errors.name?.type === "maxLength" && (
                    <span className="error_message">Name maximum length should be 50.</span>
                  )}
                </div>
              </div>
              <div className='col-lg-6  col-md-12'>
                <h4 className="h4 mt-4 d-flex align-items-center fw-bold">Email<span className="text-danger ms-1">*</span></h4>
                <div className={"position-relative mt-1 " + (errors.email ? 'active_validation' : '')}>
                  <input type="text" className="form-control form-control-lg" id="email"
                  placeholder="Enter the Email" 
                  {...register('email', {
                    required: true,
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    onChange: updateContactUs,
                  })}/>
                  {errors && errors.email && errors.email?.type === "required" && (
                    <span className="error_message">Email is Required</span>
                  )} 
                  {errors && errors.email && errors.email?.type === "pattern" && (
                    <span className="error_message">Please enter a valid email</span>
                  )}                
                </div>
              </div>
              <div className='col-lg-6  col-md-12'>
                <h4 className="h4 mt-4 d-flex align-items-center fw-bold">Contact Number<span className='optional-text'> (optional)</span></h4>
                <div className={"position-relative mt-1 " + (errors.phoneNumber ? 'active_validation' : '')}>
                  <input type="text" className="form-control form-control-lg" id="phoneNumber"
                  placeholder="Enter the Contact Number" defaultValue={contactUsData.phoneNumber}
                  {...register('phoneNumber', {                   
                    pattern: /^[0-9]{10}$/,
                    onChange: updateContactUs,
                  })}/>
                  {errors && errors.phoneNumber && errors.phoneNumber?.type === "pattern" && (
                      <span className="error_message">Please enter a 10-digit phone number</span>
                  )}                
                </div>
              </div>
              <div className='col-lg-6  col-md-12'>
                <h4 className="h4 mt-4 d-flex align-items-center fw-bold">Subject <span className='optional-text'> (optional)</span></h4>
                <div className={"position-relative mt-1 " + (errors.subject ? 'active_validation' : '')}>
                  <input type="text" className="form-control form-control-lg" id="subject"
                  placeholder="Enter the Subject" defaultValue={contactUsData.subject} 
                  {...register('subject', {
                    maxLength: 50,
                    onChange: updateContactUs,
                  })}/>
                  {errors && errors.subject && errors.subject?.type === "maxLength" && (
                    <span className="error_message">Subject maximum length should be 50.</span>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <h4 className="h4 mt-4 d-flex align-items-center fw-bold">Message <span className='optional-text'> (optional)</span></h4>
                <div className={"position-relative mt-1 " + (errors.message ? 'active_validation' : '')}>
                <textarea className="form-control form-control-lg mt-2" id="message"
                  placeholder="Enter the Message"  defaultValue={contactUsData.message} 
                  {...register('message', {
                    maxLength: 250,
                    onChange: updateContactUs,
                  })}
                ></textarea>
                {errors && errors.message && errors.message?.type === "maxLength" && (
                  <span className="error_message">Message maximum length should be 250.</span>
                )}
                </div>
                <div className="ms-au mt-4">
                  <button type="submit" className="btn float-end fw-bold newstandup-button" disabled={submitButtonLoader} style={{width:'120px'}}>
                  { !submitButtonLoader && <span>
                    <i className="fa-solid fa-check me-2"></i>Save
                    </span>}
                    { submitButtonLoader && <span>
                      <i className="fas fa-spinner fa-pulse"></i>
                    </span>}
                  </button>
                  <button type="button" className="btn float-end fw-bold newstandup_cancel_button mx-2" onClick={clearContactUs}>
                    <i className="fa-solid fa-xmark me-2"></i>Clear</button>
                </div>
              </div>
            </div>
            </form>
          }
          { isShowSuccessMessage && 
            <div className='contact-success-message'>
              <h1 className="pt-4">Thanks for reaching out</h1>
              <h4 className='mt-4 mb-4'>Our team will contact you shortly</h4>
              <img src={require('../../Assets/Images/LandingPage/thank-you.png')} alt="thank-you" className='w-50' />
            </div>}
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-lg-6  col-md-12  contact-padding'>
              <h2 className="h2 my-0 fw-bold">Connect with Support</h2>
              <p className="p3 mt-2">Fellow’s support team is here to help! Whether it’s a technical issue, a feature question, or even just a question about best practices – they’ve got you covered with their wicked fast response times. </p>
            </div>
            <div className='col-lg-6  col-md-12 contact-botmeet'>
              <img src={require('../../Assets/Images/LandingPage/contactus-connect.png')} alt="play.png" className='contactus-meet' />
            </div>


            <div className='col-lg-6  col-md-12 contact-botmeet'>
              <img src={require('../../Assets/Images/LandingPage/contactus-meeting-productivity.png')} alt="play.png" className='contactus-meet' />
            </div>
            <div className='col-lg-6  col-md-12  contact-padding'>
              <h2 className="h2 my-0 fw-bold">Boost your meeting productivity</h2>
              <p className="p3 mt-2">Fellow’s support team is here to help! Whether it’s a technical issue, a feature question, or even just a question about best practices – they’ve got you covered with their wicked fast response times. </p>
            </div>

            <div className='col-lg-6  col-md-12 contact-padding'>
              <h2 className="h2 my-0 fw-bold">Become a Super Manager</h2>
              <p className="p3 mt-2">Fellow’s support team is here to help! Whether it’s a technical issue, a feature question, or even just a question about best practices – they’ve got you covered with their wicked fast response times. </p>
            </div>
            <div className='col-lg-6  col-md-12 contact-botmeets'>
              <img src={require('../../Assets/Images/LandingPage/contactus-supermanager.png')} alt="play.png" className='contactus-meet' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactUsComponent;