import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAndManageMeets, meetsData,tenant } from '../Types/MeetsType';
import { getChannelList, getMemberList, getTimeZoneList, saveMeetsData, getmeets, getMeetsTimeLine, getPlaceHolderList,getMeetsTimeLineSummary,getMeetsConsolidateSummary,updateTenant,getTenantDetails,getMeetsUnReportedSummary} from "../Services/Api/meetsService";
import { trackException } from '../Utils/TrackExceptionUtility';
import { sortRecords } from '../Utils/CommonUtils';
import { defaultPromptsList, defaultScheduleTiming, userLocalTimeZone, meetsLogPageSize } from "../Constant/ApplicationConstant"
import { formatDate } from "../Utils/CommonUtils";
import { meetsTimelinePostInput } from "../Types/MeetsType";
import moment from "moment";
import 'moment-timezone';

const loadMeetsData: meetsData = {
  meetsId: "",
  meetsName: "",
  teamsId: "",
  teamsName: "",
  channels: [],
  objectives: [],
  roles:[],
  isDeleted: false,
  isPaused: false,
  meetMembers: [],
  meetsTrigger: {
    period: "weekly",
    days: [],
    time: "",
    timezone: "",
    meetDate: formatDate(new Date()),
  },
  questions: [],
  holidays: [],
  welcomeMessage: "",
  thankyouMessage: "",
  createdByAadObjectId: "",
  modifiedByAadObjectId: "",
  userBasedPrompts: [],
}

const loadTenantData: tenant = {
    id:'',
    tenantId: '',
    chatGPTApiKey: '',
    tenantChatGPTAPIRequestLimit: 0,
    meetChatGPTAPIRequestLimit: 0
}

const initialState: createAndManageMeets = {
  meetsData: loadMeetsData,
  currentTab: "meetsMembers",
  channels: [],
  memberList: [],
  timeZoneList: [],
  pageLoader: false,
  defaultMemberList: [],
  meestTimeLineList: [],
  hasMoreTimelineData: true,
  membersLogSummary:[],
  membersLogConsolidateSummary:"",
  chatGPTRequestCount:0,
  chatGPTTenantRequestCount:0,
  tenantData: loadTenantData,
  unRepotedCounts:[]
};

export const createMeetsData = createAsyncThunk(
  "createAndManageMeets/createMeets",
  async (args: any, thunkAPI) => {
    ;
    try {
      const response = await saveMeetsData(args);
      return response.data;
    } catch (err) {
      let error: any = err
      if (error.response && error.response.status) {
        throw error.response.data ? error.response.data : error.response.statusText
      }
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMembersList = createAsyncThunk(
  "createAndManageMeets/fetchMembersList",
  async (args: any, thunkAPI) => {
    try {
      const response = await getMemberList(args.teamsId, args.timeZone);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchTenantDetails = createAsyncThunk(
  "createAndManageMeets/fetchTenantDetails",
  async (tenantId: string, thunkAPI) => {
    try {
      const response = await getTenantDetails(tenantId);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchTimeZoneList = createAsyncThunk(
  "createAndManageMeets/fetchTimeZoneList",
  async (args, thunkAPI) => {
    try {
      const response = await getTimeZoneList();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchChannelList = createAsyncThunk(
  "createAndManageMeets/fetchChannelList",
  async (args: string, thunkAPI) => {
    try {
      const response = await getChannelList(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }

);
export const fetchMeets = createAsyncThunk(
  "createAndManageMeets/fetchMeets",
  async (args: any, thunkAPI) => {
    try {
      const response = await getmeets(args);
      
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  });

export const fetchMeetsTimelineList = createAsyncThunk(
  "createAndManageMeets/fetchMeetsTimeLine",
  async (args: meetsTimelinePostInput, thunkAPI) => {
    try {
      const response = await getMeetsTimeLine(args);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  });
  export const fetchMeetsUnReportedCounts = createAsyncThunk(
    "createAndManageMeets/fetchMeetsUnReportedSummary",
    async (args: meetsTimelinePostInput, thunkAPI) => {
      try {
     
        const response = await getMeetsUnReportedSummary(args);
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    });
  export const fetchMeetsTimelineSummary = createAsyncThunk(
    "createAndManageMeets/fetchMeetsTimeSummary",
    async (args: meetsTimelinePostInput, thunkAPI) => {
      try {
        const response = await getMeetsTimeLineSummary(args);
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    });
    export const fetchMeetsTimelineConsolidateSummary = createAsyncThunk(
      "createAndManageMeets/fetchMeetsTimelineConsolidateSummary",
      async (args: meetsTimelinePostInput, thunkAPI) => {
        try {
          const response = await getMeetsConsolidateSummary(args);
          return response.data;
        } catch (err) {
          return thunkAPI.rejectWithValue(err);
        }
      });
  export const fetchPlaceHolderList = createAsyncThunk(
    "createAndManageMeets/fetchPlaceHolderList",
    async (args, thunkAPI) => {
      try {
        const response = await getPlaceHolderList();
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
  export const updateTenantData = createAsyncThunk(
    "createAndManageMeets/updateTenant",
    async (args: any, thunkAPI) => {
      const { tenantPostData, isShowChatGPTApiKey } = args; ;
      try {
        const response = await updateTenant(tenantPostData,isShowChatGPTApiKey);
        return response.data;
      } catch (err) {
        let error: any = err
        if (error.response && error.response.status) {
          throw error.response.data ? error.response.data : error.response.statusText
        }
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
const manageSlice = createSlice({
  name: "manageReducer",
  initialState: initialState,
  reducers: {

    updateMeetsData: (state: any, action: PayloadAction<any>) => {
      state.meetsData[action.payload.name] = action.payload.value
    },   
    updateAddSelectedMember: (state: any, action: any) => {
      let index = state.memberList.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
      if (index !== -1) {
        state.memberList[index].meetsPermissions = { report: true, manage: false };
        state.memberList[index].isUserBased = false;
        state.meetsData.meetMembers.push(state.memberList[index]);
        state.memberList.splice(index, 1);
      }
      state.defaultMemberList.map((m: any) => {
        if (m.aadObjectId === action.payload.aadObjectId)
          m.isSelected = true;
        return m;
      });
      state.defaultMemberList = state.defaultMemberList.sort((a: any, b: any) => b.isSelected - a.isSelected);
    },
    updateRemoveSelectedMember: (state: any, action: any) => {
      let index = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
      if (index !== -1) {
        state.memberList.push(state.meetsData.meetMembers[index]);
        state.memberList = sortRecords(state.memberList, 'name', true);
        state.meetsData.meetMembers.splice(index, 1);
      }
      state.defaultMemberList.map((m: any) => {
        if (m.aadObjectId === action.payload.aadObjectId)
          m.isSelected = false;
        return m;
      });
      state.defaultMemberList = state.defaultMemberList.sort((a: any, b: any) => b.isSelected - a.isSelected);

      let userBasedPromptsIndex = state.meetsData.userBasedPrompts.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
        if (userBasedPromptsIndex !== -1) {
          state.meetsData.userBasedPrompts.splice(userBasedPromptsIndex, 1);
        }
    },
    addPromptQuestion: (state: any, action: any) => {
      if (action.payload.promptName === 'defaultPrompt') {
        let maxQuestionIndex = state.meetsData.questions && state.meetsData.questions.length > 0 ?
          Math.max(...state.meetsData.questions.map((x: any) => x.questionIndex)) : 0;
        state.meetsData.questions.push({ "question": "", questionIndex: maxQuestionIndex + 1 });
      }
      else if (action.payload.promptName === 'userBasedPrompt') {
        let index = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
        let filteredPrompts = state.meetsData.userBasedPrompts.find((x: any) => x.aadObjectId === action.payload.aadObjectId);
        if (filteredPrompts) {
          let maxQuestionIndex = filteredPrompts.prompts && filteredPrompts.prompts.length > 0 ?
            Math.max(...filteredPrompts.prompts.map((x: any) => x.questionIndex)) : 0;
           if(maxQuestionIndex === 0){
            let memberIndex = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
            console.log(state.meetsData.meetMembers)
            let questionCount = process.env.REACT_APP_MAX_PROMPTS_LENGTH as string
            maxQuestionIndex = (memberIndex + 1) * (parseInt(questionCount));
           }
          filteredPrompts.prompts.push({ "question": "", questionIndex: maxQuestionIndex + 1 })
        }
      }
    },
    addObjectives: (state: any, action: any) => {      
     
        let maxObjectiveIndex = state.meetsData.objectives && state.meetsData.objectives.length > 0 ?
          Math.max(...state.meetsData.objectives.map((x: any) => x.objectiveIndex)) : 0;
        state.meetsData.objectives.push({ "obective": "", objectiveIndex: maxObjectiveIndex + 1 });

    },
    updateObjectives: (state: any, action: PayloadAction<any>) => {
      ;
        let index = state.meetsData.objectives.findIndex((x: any) => x.objectiveIndex === action.payload.objectiveIndex);
        if (action.payload.objectiveIndex !== -1) {
          state.meetsData.objectives[action.payload.objectiveIndex].objective = action.payload.obective
        }   
    },
    removeObjectives: (state: any, action: PayloadAction<any>) => {
      
       let index = action.payload.objectiveIndex;
        if (index !== -1) {
          state.meetsData.objectives.splice(index, 1);
        }
    
    },
    addRoles: (state: any, action: any) => {      
     
      let maxRoleIndex = state.meetsData.roles && state.meetsData.roles.length > 0 ?
        Math.max(...state.meetsData.roles.map((x: any) => x.roleIndex)) : 0;
      state.meetsData.roles.push({ "role": "", roleIndex: maxRoleIndex + 1 });

  },
  updateRoles: (state: any, action: PayloadAction<any>) => {
    ;
   
      if (action.payload.roleIndex !== -1) {
        state.meetsData.roles[action.payload.roleIndex].role = action.payload.role
      }   
  },
  removeRoles: (state: any, action: PayloadAction<any>) => {
    
     let index = action.payload.roleIndex;
      if (index !== -1) {
        state.meetsData.roles.splice(index, 1);
      }
  
  },
    removePromptQuestion: (state: any, action: PayloadAction<any>) => {
      if (action.payload.promptName === 'defaultPrompt') {
        let index = state.meetsData.questions.findIndex((x: any) => x.questionIndex === action.payload.questionIndex);
        if (index !== -1) {
          state.meetsData.questions.splice(index, 1);
        }
      }
      else if (action.payload.promptName === 'userBasedPrompt') {
        let filteredPrompts = state.meetsData.userBasedPrompts.find((x: any) => x.aadObjectId === action.payload.aadObjectId);
        if (filteredPrompts) {
          let index = filteredPrompts.prompts.findIndex((x: any) => x.questionIndex === action.payload.questionIndex);
          if (index !== -1) {
            filteredPrompts.prompts.splice(index, 1);
          }
        }
      }
    },
    updatePromptQuestions: (state: any, action: PayloadAction<any>) => {
      if (action.payload.prompts.promptName === 'defaultPrompt') {
        let index = state.meetsData.questions.findIndex((x: any) => x.questionIndex === action.payload.prompts.questionIndex);
        if (index !== -1) {
          state.meetsData.questions[index].question = action.payload.question
        }
      }
      else if (action.payload.prompts.promptName === 'userBasedPrompt') {
        let filteredPrompts = state.meetsData.userBasedPrompts.find((x: any) => x.aadObjectId === action.payload.prompts.aadObjectId);
        if (filteredPrompts) {
          let index = filteredPrompts.prompts.findIndex((x: any) => x.questionIndex === action.payload.prompts.questionIndex);
          if (index !== -1) {
            filteredPrompts.prompts[index].question = action.payload.question
          }
        }
      }
    },
    updateMeetData: (state: any, action: PayloadAction<any>) => {
      if (action.payload.name.toLowerCase() === 'period') {
        if (action.payload.value.toLowerCase() === 'weekly')
          state.meetsData.meetsTrigger.meetDate = defaultScheduleTiming.meetDate;
        else
          state.meetsData.meetsTrigger.days = defaultScheduleTiming.days;
      }
      state.meetsData.meetsTrigger[action.payload.name] = action.payload.value
    },
    updateMeetDays: (state: any, action: PayloadAction<any>) => {
      if (action.payload.checked)
        state.meetsData.meetsTrigger[action.payload.name].push(action.payload.value);
      else {
        const index = state.meetsData.meetsTrigger[action.payload.name].indexOf(action.payload.value);
        state.meetsData.meetsTrigger[action.payload.name].splice(index, 1);
      }
    },
    updateTabDetails: (state: any, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    loadDefaultPrompts: (state: any) => {
      defaultPromptsList.forEach((x: any, index: number) => {
        state.meetsData.questions.push({ question: x.question, questionIndex: index + 1 })
      });
    },
    showAndHidePageLoader: (state: any, action: PayloadAction<any>) => {
      state.pageLoader = action.payload.value
    },
    loadDefaultScheduleTiming: (state: any) => {
      let localTimeZone = moment.tz.guess();
      localTimeZone = localTimeZone.replace("Calcutta", "Kolkata");

      let defaultSchedule = { ...defaultScheduleTiming };
      if (localTimeZone) {
        defaultSchedule.timezone = localTimeZone;
      }
      state.meetsData.meetsTrigger = defaultSchedule;
    },
    clearMeetsData: (state: any) => {
      state.meetsData = loadMeetsData;
      state.currentTab = "meetsMembers";
      state.defaultMemberList = [];
    },
    removeSelectedMember: (state: any) => {
      state.meetsData.meetMembers.forEach((x: any, index: number) => {
        let indexPosition = state.memberList.findIndex((c: any) => c.aadObjectId === x.aadObjectId);
        state.memberList.splice(indexPosition, 1);
      });
    },
    updateClone: (state: any) => {
      state.meetsData.meetsName = "Copy of " + state.meetsData.meetsName;
      state.meetsData.meetsId = "";
    },
    updateTeamsData: (state: any, action: PayloadAction<any>) => {
      state.meetsData.teamsId = action.payload.teamsId;
      state.meetsData.teamsName = action.payload.teamsName;
    },
    updateUserObjectId: (state: any, action: PayloadAction<any>) => {
      state.meetsData.createdByAadObjectId = action.payload.createdByAadObjectId;
      state.meetsData.modifiedByAadObjectId = action.payload.isEdit ? action.payload.createdByAadObjectId : null;
    },
    updatePrompts: (state: any, action: PayloadAction<any>) => {
      state.meetsData.questions = action.payload;
    },
    updateUserBasedPrompts: (state: any, action: PayloadAction<any>) => {
      state.meetsData.userBasedPrompts = action.payload;
    },  
   clearMeetTimelineData : (state: any, action: PayloadAction<any>) => {
      state.hasMoreTimelineData = true;
      state.meestTimeLineList = action.payload; 
    },
    clearMeetSummaryData: (state: any, action: PayloadAction<any>) => {
      state.membersLogSummary = [];
      state.membersLogConsolidateSummary = ""; 
    },
    clearMeetUnReportedSummaryData: (state: any, action: PayloadAction<any>) => {
      state.unRepotedCounts = [];
     
    },
    getLoggedinUser: (state: any, action: PayloadAction<any>) => {
      state.meetsData.meetMembers.push(state.memberList.find((x: any) => x.aadObjectId === action.payload));
      let index = state.defaultMemberList.findIndex((x: any) => x.aadObjectId === action.payload);
      if (index !== -1) {
        state.defaultMemberList[index].isSelected = true;
        state.defaultMemberList = state.defaultMemberList.sort((a: any, b: any) => b.isSelected - a.isSelected);
        state.memberList.splice(index, 1);
      }
      state.meetsData.meetMembers.map((element: any, index: number) => {
        element.meetsPermissions = { manage: true, report: true };
        return element;
      });
    },
    updateTenantDetails: (state: any, action: PayloadAction<any>) => {
      state.tenantData[action.payload.name] = action.payload.value
    },  
    updateMemberPermission: (state: any, action: PayloadAction<any>) => {
      let index = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
      if (index !== -1) {
        state.meetsData.meetMembers[index].meetsPermissions[action.payload.name] = action.payload.value
      }
    },
    AddOrUpdateUserBasedPrompts: (state: any, action: PayloadAction<any>) => {
      let memberIndex = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
      state.meetsData.meetMembers[memberIndex].isUserBased = action.payload.isChecked;
      if (action.payload.isChecked) {
        let prompts: any = []
        let userObject = state.meetsData.userBasedPrompts.filter((data: any) => data.aadObjectId === action.payload.aadObjectId);

        if (userObject && userObject.length === 0) {
          let questionCount = process.env.REACT_APP_MAX_PROMPTS_LENGTH as string
          let questionIndex = 0;
          if(!state.meetsData.meetMembers[memberIndex].isUserBasedChangeCount)
            questionIndex = (memberIndex + 1) * (parseInt(questionCount));
          else
            questionIndex = (memberIndex + (state.meetsData.meetMembers[memberIndex].isUserBasedChangeCount * 21)) * (parseInt(questionCount));

          defaultPromptsList.forEach((x: any, index: number) => {
            prompts.push({ question: x.question, questionIndex: questionIndex + (index + 1) })
          });
          state.meetsData.userBasedPrompts.push({ aadObjectId: action.payload.aadObjectId, prompts: prompts })
        }
      }
      else {
        let index = state.meetsData.userBasedPrompts.findIndex((x: any) => x.aadObjectId === action.payload.aadObjectId);
        if (index !== -1) {
          state.meetsData.userBasedPrompts.splice(index, 1);
        }
        state.meetsData.meetMembers[memberIndex].isUserBasedChangeCount = (!state.meetsData.meetMembers[memberIndex].isUserBasedChangeCount ? 0: state.meetsData.meetMembers[memberIndex].isUserBasedChangeCount) + 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("createAndManageMeets/createMeets/fulfilled", (state: any = initialState, action: any) => {
        state.meets = action.payload;
      })
      .addCase("createAndManageMeets/createMeets/rejected", (state: any, action: any) => {
        if (action.payload) {
          trackException("An error occurred while save meets.", action.payload.errorMessage);
        } else {
          trackException("An error occurred while save meets.", action.error.message);
        }
      })
      .addCase("createAndManageMeets/fetchMembersList/fulfilled", (state: any = initialState, action: any) => {
        action.payload.map((x:any)=> x.isUserBased = false);
        state.memberList = sortRecords(action.payload, 'name', true);
        state.defaultMemberList = sortRecords(action.payload, 'name', true);
        state.defaultMemberList.map((x: any) => x.isSelected = false)
      })
      .addCase("createAndManageMeets/fetchMembersList/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching members list.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchMeetsTimeSummary/fulfilled", (state: any = initialState, action: any) => {
        
        state.membersLogSummary=action.payload;
        

      })      
      .addCase("createAndManageMeets/fetchMeetsTimeSummary/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching MeetsTimeLineSummary.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchMeetsUnReportedSummary/fulfilled", (state: any = initialState, action: any) => {
      
        state.unRepotedCounts=action.payload;
        

      })      
      .addCase("createAndManageMeets/fetchMeetsUnReportedSummary/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching fetchMeetsUnReportedSummary.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchMeetsTimelineConsolidateSummary/fulfilled", (state: any = initialState, action: any) => {
              
        state.membersLogConsolidateSummary=action.payload;

      })
      .addCase("createAndManageMeets/fetchMeetsTimelineConsolidateSummary/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching MeetsTimeLineConsolidatedSummary.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchTimeZoneList/fulfilled", (state: any = initialState, action: any) => {
        let userTimeZone = action.payload.find((x: any) => x.value === userLocalTimeZone);
        action.payload = action.payload.filter((x: any) => x.value !== userLocalTimeZone);
        action.payload.map((x: any, index: any) => {
          x.id = index + 1 + 2;
          return x;
        });
        let sortedTimeZones = sortRecords(action.payload, 'text', true);
        state.timeZoneList = [userTimeZone, ...sortedTimeZones];
      })
      .addCase("createAndManageMeets/fetchTimeZoneList/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching timezone.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchChannelList/fulfilled", (state: any = initialState, action: any) => {
        state.channelList = sortRecords(action.payload, 'channelName', true);
      })
      .addCase("createAndManageMeets/fetchChannelList/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching channels list.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchMeets/fulfilled", (state: any = initialState, action: any) => {
        action.payload.questions.forEach((element: any, index: number) => {
          element.questionIndex = index + 1;
        });
        state.meetsData.meetMembers.map((x:any)=> x.isUserBased = false);
        state.meetsData = action.payload;
        state.meetsData.userBasedPrompts = action.payload.userBasedPrompts === null  ? [] : action.payload.userBasedPrompts;
        state.meetsData.userBasedPrompts.forEach((list: any, parentIndex: number) => {
          let memberIndex = state.meetsData.meetMembers.findIndex((x: any) => x.aadObjectId === list.aadObjectId);
          state.meetsData.meetMembers[memberIndex].isUserBased = true
          let questionCount = process.env.REACT_APP_MAX_PROMPTS_LENGTH as string
          let questionIndex = (memberIndex + 1) * (parseInt(questionCount));
          list.prompts.forEach((x: any, index: number) => {
            x.questionIndex =  questionIndex + (index + 1) ;
          });
        });
      })
      .addCase("createAndManageMeets/fetchMeets/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching meets details.", action.payload.message);
        state.pageLoader = false;
      })
      .addCase("createAndManageMeets/fetchMeetsTimeLine/fulfilled", (state: any = initialState, action: any) => {
       // 
        state.meetsTimeZoneList = { timeZone: action.payload.timeZone, timeZoneDescription: action.payload.timeZoneDescription };
        if (!action.payload || action.payload.logDetails.length == 0) {
          state.hasMoreTimelineData = false;
        }
        else {
          if (action.payload.logDetails.length < meetsLogPageSize)
            state.hasMoreTimelineData = false;
          action.payload.logDetails.map((item: any) => state.meestTimeLineList.push(item))
          state.chatGPTRequestCount = action.payload.chatGPTRequestCount;
          state.chatGPTTenantRequestCount = action.payload.chatGPTTenantRequestCount;
          state.tenantData=action.payload.tenant;
          let timeZone = action.payload.timeZone;
          state.meestTimeLineList.forEach((element: any, index: number) => {
            let zoneConversion = moment.utc(element.createdDate).tz(timeZone);
            let reportDate = zoneConversion.format('MM-DD-YYYY');
            let time = zoneConversion.format('hh:mm A');
            element.reportDate = reportDate;
            element.reportTime = time;
          });
        }
      })
      .addCase("createAndManageMeets/fetchMeetsTimeLine/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching meets log.", action.payload.message);
      })
      .addCase("createAndManageMeets/fetchPlaceHolderList/fulfilled", (state: any = initialState, action: any) => {
        state.placeHolder = action.payload;
      })
      .addCase("createAndManageMeets/fetchPlaceHolderList/rejected", (state: any, action: any) => {
        if (action.payload) {
          trackException("An error occurred while get placeholder .", action.payload.errorMessage);
        } else {
          trackException("An error occurred while get placeholder.", action.error.message);
        }
      })
      .addCase("createAndManageMeets/updateTenantData/fulfilled", (state: any = initialState, action: any) => {
        state.tenant = action.payload;        
      })
      .addCase("createAndManageMeets/updateTenantData/rejected", (state: any, action: any) => {
        if (action.payload) {
          trackException("An error occurred while save configuration.", action.payload.errorMessage);
        } else {
          trackException("An error occurred while save configuration.", action.error.message);
        }
      })
      .addCase("createAndManageMeets/fetchTenantDetails/fulfilled", (state: any = initialState, action: any) => {
        state.tenantData = action.payload;
        state.tenantData.chatGPTApiKey = 
        state.tenantData.chatGPTApiKey  ? '*'.repeat(state.tenantData.chatGPTApiKey.length - 5) + state.tenantData.chatGPTApiKey.slice(-5) : ''; 
        state.tenantData.tenantChatGPTAPIRequestLimit = state.tenantData.tenantChatGPTAPIRequestLimit == 0? '' : state.tenantData.tenantChatGPTAPIRequestLimit;
        state.tenantData.meetChatGPTAPIRequestLimit = state.tenantData.meetChatGPTAPIRequestLimit == 0? '' : state.tenantData.meetChatGPTAPIRequestLimit;
      })
      .addCase("createAndManageMeets/fetchTenantDetails/rejected", (state: any, action: any) => {
        trackException("An error occurred while fetching tenant details.", action.payload.message);
      })
      
  },
});
export const {
  updateMeetData,
  updateMeetDays,
  updateAddSelectedMember,
  updateRemoveSelectedMember,
  updatePromptQuestions,
  updateTabDetails,
  updateMeetsData, 
  addPromptQuestion,
  loadDefaultPrompts,
  removePromptQuestion,
  showAndHidePageLoader,
  loadDefaultScheduleTiming,
  clearMeetsData,
  removeSelectedMember,
  updateClone,
  updateTeamsData,
  updateUserObjectId,
  updatePrompts,
  clearMeetTimelineData,
  clearMeetSummaryData,
  getLoggedinUser,
  updateMemberPermission,
  AddOrUpdateUserBasedPrompts,
  updateUserBasedPrompts,
  addObjectives,
  updateObjectives,
  removeObjectives,
  addRoles,
  updateRoles,
  removeRoles,
  updateTenantDetails,
  clearMeetUnReportedSummaryData

} = manageSlice.actions;
export default manageSlice.reducer;
