import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { getInitials } from "../../Utils/CommonUtils";
import { updateUserInfo } from "../../Store/authSlice";
import { fetchTeams, updateTeams, haveManagePermission } from "../../Store/meetsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { clearMeetsData } from "../../Store/manageSlice";
import { clearMeetsList } from "../../Store/meetsSlice";
import swal from "sweetalert";


function HeaderComponent() {

    const { instance, accounts } = useMsal();
    const dispatch = useDispatch<any>();
    let location = useLocation();

    useEffect(() => {
        (async () => {
            const userAccount = accounts[0];
            dispatch(updateUserInfo({ accounts: accounts as any }));
        })();
    }, [accounts]);

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        localStorage.clear();
    };

    const userDetails = useSelector(
        (state: any) => state.authReducer.userDetails
    );

    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );
    useEffect(() => {
        (async () => {
            const path = location.pathname.toLowerCase();
            if (userDetails.tenantId) {
                dispatch(fetchTeams({ tenantId: userDetails.tenantId, userAdObjectId: userDetails.userAdObjectId }));               
            }
        })();
    }, [userDetails.user, userDetails.name]);

    useEffect(() => {
        (async () => {
         
          if (defaultTeamsId) {
            let userId = userDetails.userAdObjectId;
                 dispatch(haveManagePermission({ defaultTeamsId, userId }))
          }
        })();
      }, [defaultTeamsId]);

    const teamList = useSelector(
        (state: any) => state.meetsReducer.teamsListData
    );
    const isManagePermission = useSelector(
        (state: any) => state.meetsReducer.isManagePermission
    );
    const defaultMeetName = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsName
    );


    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const teamsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let teams = teamList.find((teams: any) => teams.teamsName === event.target.value);
        if (teams && !teams.isBotInstalled) {
            await swal({
                title: "Warning", text: "Meetstand Bot is not installed in the " + teams.teamsName + ". Please install the meetstand to continue the manage meets.Do you need to install  MeetsStand Bot in " + teams.teamsName + "? ", icon: "warning",
                dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
            }).then(async willInstall => {
                if (willInstall) {
                    window.location.replace(process.env.REACT_APP_TEAMS_REDIRECT_URL as string);
                }
                else {
                    event.target.name = defaultMeetName;
                    event.target.value = defaultMeetName;

                }
            });
        }
        else {
            dispatch(updateTeams({ name: event.target.value, value: teams.teamsId }));
            dispatch(clearMeetsData());
            dispatch(clearMeetsList());          
            routeChange("/");
        }
    };


    return (
        <header>           
            <div className="container-fluid">
                <div className="container">
                    <div className="row py-3">
                        <div className="d-flex header-logo-responsive">
                            <div className="my-auto cursor-pointer">
                                <img className="img-fluid web-view-icon" src={require('../../Assets/Images/logo.png')} alt="Logo" onClick={() => routeChange("/")} />
                                <img className="img-fluid responsive-view-icon" src={require('../../Assets/Images/logoicon.png')} alt="Logo" onClick={() => routeChange("/")} />
                            </div>
                            <div className="d-flex ms-auto">
                                <div className="my-auto position-relative header-user">
                                    <div className="btn user-selected px-2 py-2 me-2 border-white bg-white float-end d-flex align-items-center cursor-pointer">
                                    <button className="dropdown-toggle px-2 py-2 shadow header-right float-end d-flex align-items-center cursor-pointer" >
                                        <span className="header-logo-button">{getInitials(userDetails.name)}</span><span className="mx-2 user-logo">{userDetails.name}</span>
                                   </button>
                                    <ul className="dropdown-menu border-white shadow" aria-labelledby="dropdownMenuButton1">
                                        <li className="mt-1"><span className="dropdown-item" onClick={() => routeChange("/profile")}><i className="fa-solid fa-user me-2"></i>My Profile</span></li><li className="mt-1"><span className="dropdown-item" onClick={() => routeChange("/templates")}><i className="fa-solid fa-file-lines me-2"></i>Templates</span></li>
                                        {isManagePermission &&
                                            <li className="mt-1"><span className="dropdown-item" onClick={() => routeChange("/configuration")}><i className="fa-solid fa-gears me-1"></i>Configuration</span></li>
                                        }
                                        <li className="mt-1"><span className="dropdown-item" onClick={handleLogout}><i className="fa-solid fa-right-from-bracket me-2"></i>Log Out</span></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderComponent;