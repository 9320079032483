import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../Meets/ErrorMessage";
import React from "react";
import {
  updateHolidays,
  removeHolidays,
  addDefaultEmptyHoliday,
} from "../../Store/holidayTemplateSlice";
import { monthList } from "../../Constant/ApplicationConstant";
import { updateBlockerData } from "../../Store/commonSlice";
import FileUpload from "../Layout/Shared/FileUpload";
import { useForm } from "react-hook-form";

function Holidays(props: any) {
  const { register, errors, isFromTemplate,resetField } = props;
  const dispatch = useDispatch<any>();
  const holidayTemplate = useSelector(
    (state: any) => state.holidayTemplateReducer.holidayTemplate
  );

  const updateHolidaysStore = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: String,
    index: Number
  ) => {
    dispatch(updateHolidays({ name, value: event.target.value, index: index }));
    dispatch(updateBlockerData({ isModified: true }));
  };

  const addHolidays = () => {
    dispatch(addDefaultEmptyHoliday());
    dispatch(updateBlockerData({ isModified: true }));
  };

  const deleteHolidays = (index: any) => {
    dispatch(removeHolidays({ holidayIndex: index }));
    dispatch(updateBlockerData({ isModified: true }));
    resetField(`holidayName${holidayTemplate.templateId}${index}`);
    resetField(`holidayMonth${holidayTemplate.templateId}${index}`);
    resetField(`holidayDate${holidayTemplate.templateId}${index}`);
    resetField(`notes${holidayTemplate.templateId}${index}`);
  };

  const dates = Array.from({ length: 31 }, (_, i) => ({
    value: `${i + 1}`.padStart(2, "0"),
    label: `${i + 1}`.padStart(2, "0"),
  }));

  return (
    <div>
     { isFromTemplate && <div className="position-relative" >
          <FileUpload />
      </div>
      }
      <div className="table-responsive">
        <table className="table template-details-table hoilday-table">
          <thead className="table-heading">
            <tr>
              <th>Holiday Name</th>
              <th>Month</th>
              <th>Date</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="template-details-table-body">
            {holidayTemplate.holidays &&
              holidayTemplate.holidays.map((holiday: any, index: any) => {
                return (
                  <tr
                    key={
                      "holidays" +
                      holidayTemplate.templateId +
                      holiday.holidayIndex
                    }
                    className="table-row"
                  >
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        id={
                          "holidayName" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                        }
                        placeholder="Enter the Holiday Name"
                        name={
                          "holidayName" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                        }
                        {...register(
                          "holidayName" +
                            holidayTemplate.templateId +
                            holiday.holidayIndex,
                          {
                            required: true,
                            maxLength: 80,
                            minLength: 3,
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              updateHolidaysStore(
                                event,
                                "holidayName",
                                holiday.holidayIndex
                              ),
                            value: holiday.holidayName,
                            defaultValue: holiday.holidayName,
                          }
                        )}
                        value={holiday.holidayName}
                        defaultValue={holiday.holidayName}
                      />
                      {errors[
                        "holidayName" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                      ] && (
                        <ErrorMessage
                          validationType={
                            errors[
                              "holidayName" +
                                holidayTemplate.templateId +
                                holiday.holidayIndex
                            ].type
                          }
                          fieldName="Holiday Name"
                          minLength="3"
                          maxLength="80"
                        />
                      )}
                    </td>
                    <td>
                        <select
                          className="form-select form-select-md"
                          name="holidayMonth"
                          id={
                            "holidayMonth" +
                            holidayTemplate.templateId +
                            holiday.holidayIndex
                          }
                          {...register(
                            "holidayMonth" +
                              holidayTemplate.templateId +
                              holiday.holidayIndex,
                            {
                              required: true,
                              onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                updateHolidaysStore(
                                  event,
                                  "holidayMonth",
                                  holiday.holidayIndex
                                ),
                              value: holiday.holidayMonth,
                              defaultValue: holiday.holidayMonth,
                            }
                          )}
                          defaultValue={holiday.holidayMonth}
                          value={holiday.holidayMonth}
                        >
                          <option key="0" value="">
                            Select Month
                          </option>
                          {monthList.map((month) => (
                            <option key={month.key} value={month.value}>
                              {month.label}
                            </option>
                          ))}
                        </select>
                        
                    
                      {errors[
                        "holidayMonth" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                      ] && (
                        <div>
                          <ErrorMessage
                            validationType="required"
                            fieldName="Month"
                          />
                        
                        </div>
                      )}
                      
                    </td>
                    <td>
                        <select
                          className="form-select form-select-md"
                          name="holidayDate"
                          id={
                            "holidayDate" +
                            holidayTemplate.templateId +
                            holiday.holidayIndex
                          }
                          {...register(
                            "holidayDate" +
                              holidayTemplate.templateId +
                              holiday.holidayIndex,
                            {
                              required: true,
                              value: holiday.holidayDate,
                              defaultValue: holiday.holidayDate,
                              onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                updateHolidaysStore(
                                  event,
                                  "holidayDate",
                                  holiday.holidayIndex
                                ),
                            }
                          )}
                          defaultValue={holiday.holidayDate}
                          value={holiday.holidayDate}
                        >
                          <option value="">Select Date</option>
                          {dates.map((date) => (
                            <option key={date.value} value={date.value}>
                              {date.label}
                            </option>
                          ))}
                        </select>
                      {errors[
                        "holidayDate" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                      ] && (
                        <ErrorMessage
                          validationType="required"
                          fieldName="Date"
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        id={
                          "notes" +
                          holidayTemplate.templateId +
                          holiday.holidayIndex
                        }
                        placeholder="Enter the Notes"
                        defaultValue={holiday.notes}
                        {...register(
                          "notes" +
                            holidayTemplate.templateId +
                            holiday.holidayIndex,
                          {
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              updateHolidaysStore(
                                event,
                                "notes",
                                holiday.holidayIndex
                              ),
                          }
                        )}
                      />
                    </td>
                    <td>
                      <div className="action-buttons">
                        <i
                          className="fa-solid fa-minus"
                          style={{
                            display:
                              holidayTemplate.holidays.length === 1
                                ? "none"
                                : "inline-block",
                          }}
                          onClick={() => deleteHolidays(holiday.holidayIndex)}
                        ></i>
                        <i
                          className="fa-solid fa-plus ps-4"
                          style={{
                            display:
                              holidayTemplate.holidays.length - 1 === index
                                ? "inline-block"
                                : "none",
                          }}
                          onClick={() => addHolidays()}
                        ></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Holidays;