import React from 'react';
import { addRoles, updateRoles, removeRoles } from '../../Store/manageSlice';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { findHelpText } from "../../Utils/CommonUtils";
import swal from "sweetalert";
import ErrorMessage from "./ErrorMessage";
function MeetsRolesComponent(props: any) {
  const { register, errors, resetField, userbasedPromptsCheckUpdate } = props;
  const meets = useSelector(
    (state: any) => state.manageReducer.meetsData
  );
  const { meetsId } = useParams<"meetsId">();
  const dispatch = useDispatch<any>();
  const addNewRole = async (payload: any) => {
    await dispatch(addRoles(payload));
  }
  const updateRoleCallBack = (event: React.ChangeEvent<HTMLInputElement>, roleIndex: any) => {
    dispatch(updateRoles({ role: event.target.value, roleIndex }))

  };
  const removeRoleCallBack = (index:any) => {
    swal({
      title: "Warning", text: "Are you sure that you want to delete this Role?", icon: "warning",
      dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
    }).then((willDelete: any) => {
      if (willDelete) {
        swal({ title: "Success", text: "The selected Role has been deleted successfully", icon: "success", timer: 1000, buttons: [false] })
        dispatch(removeRoles({roleIndex:index}));
       resetField(`role.${index}`);
      }
    });
  }
  return (
    <div className="mt-3">
      <h2 className="h2 mt-12 d-flex align-items-center">Roles
        <div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("Roles")}>i</span></div>
        <button type="button" className="prompt-form-add ms-2" onClick={() => addNewRole({ aadObjectId: '' })}>Add Roles
          <i className="fa-sharp fa-solid fa-plus ms-1"></i></button>
      </h2>

      {meets.roles && meets.roles.map((roles: any, index: any) =>
        <div style={{ margin: '15px 6px' }}>
          <div className="position-relative">
            <input type="text" className="form-control form-control-lg"
              placeholder="Enter an Role" value={roles.role}
              {...register(`role.${index}` as const, {
                required: true, maxLength: 200
              })} 
              name={"role." + index} id={"role." + index} onChange={(event: any) => updateRoleCallBack(event, index)} />
            <div className="d-flex position-absolute" style={{ top: "1px", right: "1px" }} >
              <button type="button" onClick={() =>removeRoleCallBack(index)} className="fa-solid fa-trash" style={{ border: 'none', color: '#324e63', background: "#EEEEEE", padding: "15px", borderRight: "1px solid #ced4da" }}></button>
            </div>
          </div>
          {!!errors?.role?.[index] &&
          <ErrorMessage validationType="required"  fieldName="Role" maxLength="200" />}
        </div>
      )}
    </div>
  );
}

export default MeetsRolesComponent;