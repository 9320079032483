import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../../Services/authConfig";

function LandingPageComponent() {

  const navigate = useNavigate();

  const routeChange = (path: string) => {
    navigate(path);
  }

  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginApiRequest).catch((e) => {
      console.log(e);
    });
  };
  const [selectedFeatures, setSelectedFeatures] = useState('StandUpMeetings');

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="container-fluid landing-page" id="home">
        <div className='container'>
          <div className="row">
            <div className="col-lg-12 my-5 bot-header">
              <img className="img-fluid" src={require('../../Assets/Images/bot.png')} alt="logo" />
            </div>
          </div>
          <div className="row landing-bot">
            <div className="col-lg-5 col-md-6">
              <div className="meets-everyone">
                <h1 className="text-white fw-bolder landing-header">Meets
                  <br />for Everyone.</h1>
                <p className="p text-white mt-4 landing-text">
                  Be part of the growing number of teams that use Meets to simplify their meetings, and daily reports through automation.
                </p>
                <button className="btn btn-lg btn-dark mt-2 button-meeting" onClick={handleLogin}>Start my meeting</button>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 position-relative mt-3 pb-5">
              <div className="d-flex justify-content-center">
                <img className="img-fluid bg-botimages" src={require('../../Assets/Images/bgbot.png')} alt="landing-page" />
              </div>
            </div>
            <div className="d-flex justify-content-center scroll-about">
              <a href="#about" className="btn-scroll" title="Scroll Down">
                <i className="fa-solid fa-angle-down up-card"></i></a>
            </div>
          </div>
        </div>
      </div>
      <section className="about_section layout_padding" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>
                    Why Meetstand?
                  </h2>
                </div>
                <p>
                  Meetstand is a popular asynchronous meeting tool designed to facilitate communication and collaboration within teams. It is commonly used for stand-up meetings, but its flexibility allows it to be used for other types of check-ins and updates as well
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src={require('../../Assets/Images/LandingPage/about-text.png')} alt="play.png" />
                {/* <img src="images/about-img.png" alt=""> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trending_section layout_padding">
        <div id="accordion">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="detail-box">
                  <div className="heading_container">
                    <h2>
                      Trending Categories
                    </h2>
                  </div>
                  <div className="tab_container">
                    <div className="t-link-box"  onClick={() => setSelectedFeatures('StandUpMeetings')}>
                      <div className="number">
                        <h5>
                          01
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'StandUpMeetings' ? 'fw-bold' : '' }>
                          Stand-up Meetings
                        </h5>
                      </div>
                    </div>
                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('CustomizablePrompt')}>
                      <div className="number">
                        <h5>
                          02
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'CustomizablePrompt' ? 'fw-bold' : '' }>
                          Customizable Prompt
                        </h5>
                      </div>
                    </div>
                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('TriggerTime')}>
                      <div className="number">
                        <h5>
                          03
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'TriggerTime' ? 'fw-bold' : '' }>
                          Trigger Time
                        </h5>
                      </div>
                    </div>
                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('Holidays')}>
                      <div className="number">
                        <h5>
                          04
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'Holidays' ? 'fw-bold' : '' }>
                          Holidays
                        </h5>
                      </div>
                    </div>
                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('UserBasedPrompts')}>
                      <div className="number">
                        <h5>
                          05
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'UserBasedPrompts' ? 'fw-bold' : '' }>
                          User Based prompts
                        </h5>
                      </div>
                    </div>

                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('ChatGPTIntegration')}>
                      <div className="number">
                        <h5>
                          06
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'ChatGPTIntegration' ? 'fw-bold' : '' }>
                          Chat GPT Integration
                        </h5>
                      </div>
                    </div>

                    <div className="t-link-box collapsed" onClick={() => setSelectedFeatures('EngagementSummary')}>
                      <div className="number">
                        <h5>
                          07
                        </h5>
                      </div>
                      <hr />
                      <div className="t-name">
                        <h5 className={selectedFeatures === 'EngagementSummary' ? 'fw-bold' : '' }>
                          Engagement Summary
                        </h5>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-8 mt-5">
                <div className="collapse" style={{ display: selectedFeatures === 'StandUpMeetings' ? 'block' : 'none' }}>
                  <p>
                    Meetstand helps teams conduct asynchronous stand-up meetings. It sends automated messages to team members, asking them to provide updates on their progress, plans, and any blockers they may be facing.
                    Team members can respond to these messages at their convenience, and the information is compiled and shared with the team.
                  </p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/standup-list.png')} alt="standuplist" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse" style={{ display: selectedFeatures === 'CustomizablePrompt' ? 'block' : 'none' }}>
                  <p>Meetstand allows teams to define their own set of questions for the stand-up meetings.
                    This flexibility enables teams to gather specific information that is relevant to their projects and workflows.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/user-prompts.png')} alt="userprompts" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse" style={{ display: selectedFeatures === 'TriggerTime' ? 'block' : 'none' }}>
                  <p>Meetstand lets you schedule the timing and frequency of check-ins. You can set it up to run daily or at a custom interval.
                    This feature ensures that team members receive regular prompts to provide updates.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/trigger-time.png')} alt="triggertime" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse" style={{ display: selectedFeatures === 'Holidays' ? 'block' : 'none' }}>
                  <p>Meetstand integrates with various collaboration tools such as Slack, Microsoft Teams, and Google Chat. This integration allows for seamless communication and ensures that team members can respond to Geekbot prompts directly within their preferred chat platform.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/holiday-template.png')} alt="holidaytemplate" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse" style={{ display: selectedFeatures === 'UserBasedPrompts' ? 'block' : 'none' }}>
                  <p>Meetstand provides reporting features that allow team leads and managers to track team progress, identify patterns, and gain insights into individual and team performance. These reports can help identify potential bottlenecks and optimize workflows.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/user-prompts.png')} alt="userprompts" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="collapse" style={{ display: selectedFeatures === 'ChatGPTIntegration' ? 'block' : 'none' }}>
                  <p>Utilizing OpenAI technology, Meetstand creates concise meeting logs that deliver insights into team and individual progress. This streamlined process enhances the tracking of discussions, decisions, and action items, promoting effective communication and collaboration among team members.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/ChatGPTIntegration.png')} alt="userprompts" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="collapse" style={{ display: selectedFeatures === 'EngagementSummary' ? 'block' : 'none' }}>
                  <p>Meetstand sends a notification to the meeting organizer when someone fails to attend a meeting.</p>
                  <div className="img_container ">
                    <div className="box b-1">
                      <div className="img-box">
                        <img src={require('../../Assets/Images/LandingPage/engagement-summary.png')} alt="userprompts" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="discount_section  layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="detail-box">
                <h2>
                  With our meetstand, you can ensure perfect stand-up meetings every time, streamlining the process and boosting team productivity
                </h2>
                <h2 className="main_heading">
                  Start Today: Install Meetstand for your team!
                </h2>
                <div className="">
                  <a href="https://www.microsoft.com/en-in/microsoft-teams/download-app" target="_blank">
                    Add to Microsoft Teams <img src={require('../../Assets/Images/LandingPage/microsoft-teams.png')} alt="microsoft-teams.png" className='microsoft-bot'  />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src={require('../../Assets/Images/LandingPage/team-bot.png')} alt="discount" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-container container">
        <div className='row standup-meet'>
        <div className=" col-lg-4 col-md-6 col-sm-12 mt-3">
          <div className='project'>
          <h1><img src={require('../../Assets/Images/meeting.png')} alt="meeting" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Standup Meetings</h2>
            <div className="project__description">
              <div className="description">
                Report at your own pace with short, sharp check-ins powered by our asynchronous standup bot.
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className='project'>
          <h1><img src={require('../../Assets/Images/sprint.png')} alt="sprint" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Retrospectives</h2>
            <div className="project__description">
              <div className="description">
                Set sprint reviews on autopilot with a retrospective bot that gives everyone the space to speak.
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className='project'>
          <h1><img src={require('../../Assets/Images/survey.png')} alt="survey" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Anonymous</h2>
            <div className="project__description">
              <div className="description">
                Following fresh branding, this website was initially designed for B2B.
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className='project'>
          <h1><img src={require('../../Assets/Images/account.png')} alt="account" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Private 1-on-1s</h2>
            <div className="project__description">
              <div className="description">Give private 1-on-1s a boost, making the most of your in-person time by surfacing what matters before you meet.
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className='project'>
          <h1><img src={require('../../Assets/Images/bot-meet.png')} alt="meeting" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Meeting Notes</h2>
            <div className="project__description">
              <div className="description">
                End every meeting with a summary report, enabling your team to see what was discussed and any decisions made.
              </div>
            </div>
          </div>
</div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className='project'>
          <h1><img src={require('../../Assets/Images/costumer.png')} alt="costumer" className='project-icons' /></h1>
          <div className="project__info">
            <h2>Team Reports</h2>
            <div className="project__description">
              <div className="description">
                Track team well-being at a glance, using the happiness tracker to keep team morale in check and know when to step in.
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className="heading_container mt-5 featured-section">
            <h2>
              Featured Brands
            </h2>
          </div>
          <div className="py-24 bg-white">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                <ul className="features-bot">
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/integration.png')} alt="integration" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Integration with Collaboration Tools</h4>
                        <p className="text-gray-500 mb-0">Seamlessly integrate with popular platforms like Slack, Microsoft Teams, and Google Chat.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/reservation.png')} alt="reservation" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Scheduled Check-ins</h4>
                        <p className="text-gray-500 mb-0">Set up automated check-ins at your preferred frequency, whether it's daily or custom intervals.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/tap.png')} alt="tap" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Easy-to-Use Interface</h4>
                        <p className="text-gray-500 mb-0">Provide a user-friendly interface for a seamless user experience.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/collaboration.png')} alt="collaboration" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Remote Team Collaboration</h4>
                        <p className="text-gray-500 mb-0">Enable effective communication and collaboration for remote or distributed teams.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  my-auto">
                <div className="h-full mx-auto rounded bg-cover bg-right" >
                  <img src={require('../../Assets/Images/featured-brand.png')} alt="featured-brand" className='global-image ' />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                <ul className="features-bot">
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/transparency.png')} alt="transparency" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Increase Transparency</h4>
                        <p className="text-gray-500 mb-0">Encourage transparency and visibility across team members' progress and challenges.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/online-meeting.png')} alt="online-meeting" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Customizable Meeting Templates</h4>
                        <p className="text-gray-500 mb-0">Create and save templates for different types of check-ins or meetings, streamlining the process.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/world-time.png')} alt="world-time" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Timezone Support</h4>
                        <p className="text-gray-500 mb-0">Accommodate teams across different time zones with built-in timezone support.</p>
                      </div>
                    </div>
                  </li>
                  <li className="my-5 md:mt-0">
                    <div className="d-flex align-items-center">
                      <div className="flex items-center justify-center h-5 w-5 rounded-md text-indigo-100">
                        <img src={require('../../Assets/Images/data.png')} alt="data" className='featured-icons' />
                      </div>
                      <div className="ms-4">
                        <h4 className="featured-text">Streamlined Communication</h4>
                        <p className="text-gray-500 mb-0">Facilitate clear and organized communication within your team.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="we-offer-area text-center bg-gray">
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-12">
            </div>
          </div>
          <div className="row our-offer-items less-carousel">
            <div className="col-12 equal-height">
              <div className="item">
                <h1>Keep everyone in sync </h1>
                <p>
                  Run asynchronous standups, retros,and more workflows
                </p>
                <button type="button" className="callout--button" onClick={() => routeChange("/contactus")}>Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showButton && <a id="button-card" className="show-card" href='#home'></a>}
    </div>
  );
}
export default LandingPageComponent;