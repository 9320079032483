import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showAndHidePageLoader, fetchMeets, clearMeetsData } from "../../Store/manageSlice";
import { getInitials } from "../../Utils/CommonUtils";
import MeetsSchedule from "../../Components/Meets/MeetsSchedule";
import MeetsLogSummary from '../../Components/Meets/MeetsLogSummary';
import MinutesOfMeetsSkeleton from '../../Components/SkeletonLoader/MinutesOfMeetsSkeleton';
import MeetsLog from '../../Components/Meets/MeetsLog';
import Breadcrumbs from '../../Components/Layout/Shared/Breadcrumbs';

function MinutesOfMeets() {
    const dispatch = useDispatch<any>();

    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    }
    
    const meetsData = useSelector(
        (state: any) => state.manageReducer.meetsData
    );
    const { meetsId } = useParams<"meetsId">();

    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );
    
    const pageLoader = useSelector(
        (state: any) => state.manageReducer.pageLoader
    );

    useEffect(() => {
        dispatch(showAndHidePageLoader({ value: true }));
        if (defaultTeamsId) {
            (async () => {
                await dispatch(fetchMeets(meetsId));
                dispatch(showAndHidePageLoader({ value: false }));
            })();
        }
    }, [defaultTeamsId]);

    const userAdObjectId = useSelector(
        (state: any) => state.authReducer.userDetails.userAdObjectId
    );

    const manageUser = () => {
        return meetsData && meetsData.meetMembers.filter((e: any) => e.meetsPermissions.manage === true && e.aadObjectId == userAdObjectId).length > 0;
    }
   
    let [meetsMebersDisplayCount, setMeetsMebersDisplayCount] = useState(5);
    let [meetsQuestionStartCount, setMeetsQuestionStartCount] = useState(0);
    let [meetsQuestionEndCount, setMeetsQuestionEndCount] = useState(5);

    const showMoreMember = () => {
        setMeetsMebersDisplayCount(meetsData.meetMembers.length)
    }

    const showMoreQuestions = () => {
        if (meetsData.questions.length !== meetsQuestionEndCount) {
            setMeetsQuestionStartCount(meetsQuestionEndCount);
            setMeetsQuestionEndCount(meetsData.questions.length);
        }
    }

    const showTopFiveQuestions = () => {
        setMeetsQuestionStartCount(0);
        setMeetsQuestionEndCount(5);
    }

    useEffect(() => {
        return () => { // works on page leave
            dispatch(clearMeetsData());
        };
    }, []);

    return (
        <>
            {meetsData && <div className="container-fluid px-0">
                <div className="container-fluid bg-light">
                    <div className="container py-4">
                        <div className="d-flex flex-wrap">
                            <div className="minutes-button-back">
                                <h1 className="h1 my-0 fw-bold">Minutes Of Meets</h1>
                                <Breadcrumbs currentPage="Minutes Of Meets" />
                            </div>
                            <div className="ms-auto my-auto minutes-button-back">
                                <button className="float-end fw-bold newstandup-button shadow" onClick={() => routeChange("/")}>Back To Home</button>
                            </div>
                        </div>
                    </div>
                </div>
                { pageLoader && <MinutesOfMeetsSkeleton/>}
                { !pageLoader && <div>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-lg-12 position-relative">
                            <div className="position-relative" style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                                <div className="minutes-card-main">
                                    <div className="minutes-card-main-header">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center p-3 ms-4">
                                                <div className="shadow border mt-1 minutes-name-profile" style={{ width: "120px", height: "120px", background: "#fff", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="minute-card" style={{ width: "105px", height: "105px", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <span style={{ fontSize: "35px", fontWeight: "bolder", color: "#fff" }}>
                                                            {getInitials(meetsData.meetsName)}
                                                        </span></div>
                                                </div>
                                                <div className="ms-3">
                                                    <h4 className="h4 lh-sm fw-bolder mb-0 minutesmeets-name">{meetsData.meetsName}</h4>
                                                </div>
                                            </div>
                                            <div className="float-end ms-auto me-2 minutes-button">
                                                {manageUser() && <button
                                                    className="profile-card__button profile-card__button-edit my-0 mx-2"
                                                    onClick={() => routeChange('/manage/meets/' + meetsId)} >
                                                    <span className="meeting-button-show">Edit</span><i className="fa fa-pen meeting-button-hide"></i></button>}
                                                {manageUser() && <button className="profile-card__button profile-card__button-clone my-0 mx-2"
                                                    onClick={() => routeChange('/clone/meets/' + meetsId)}>
                                                    <span className="meeting-button-show">Clone</span><i className="fa-solid fa-clone meeting-button-hide"></i></button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-4">

                                    <div className="col-lg-4 col-md-4 col-sm-4 minutes-channel mt-4">
                                        <div>
                                            <h6 className="h6 mt-2 fw-bolder">Channels</h6>
                                            <div className='channel-list' style={{justifyContent:'flex-start'}}>
                                                {meetsData.channels.map((channel: any) =>
                                                    <button className="channel-name" style={{marginLeft:0, marginRight:'15px'}}><i className="fa-solid fa-tags"></i><span className="ms-2">{channel.channelName}</span></button>
                                                )} 
                                            </div>                              
                                        </div>
                                        <div className="mt-2">
                                            <h6 className="h6 mt-2 fw-bolder">Trigger Time</h6>
                                            <h6 className="h6 mt-3 lh-sm mb-5"> <MeetsSchedule meetSchedule={meetsData.meetsTrigger} /></h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-8 mt-4">
                                        <div className="pe-2">
                                            <h6 className="h6 fw-bolder">Prompts</h6>
                                            <div className="minutes-meet-prompts">
                                                {meetsData.questions.slice(meetsQuestionStartCount, meetsQuestionEndCount).map((question: any, index: any) => {
                                                    return (
                                                        <h6 className="h6 mb-0" key={question.question + index} title={question.question}>
                                                            {question.question}</h6>
                                                    );
                                                })}
                                            </div>
                                            {meetsData.questions.length > 5 && <div className="d-flex justify-content-center">
                                                <div className={"d-flex justify-content-center align-items-center prompt-click " + (meetsQuestionStartCount === 0 ? 'prompt-click-active' : '')}
                                                    onClick={showTopFiveQuestions}></div>
                                                <div className={"d-flex justify-content-center align-items-center ms-2 prompt-click " + (meetsQuestionStartCount === 5 ? 'prompt-click-active' : '')}
                                                    onClick={showMoreQuestions}></div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-3">
                                    <div className="col-lg-12">
                                        <div className="position-relative minutes-member">
                                            <h6 className="h6 my-2 fw-bolder position-absolute" style={{ top: "-40px" }}>Members</h6>
                                            <div className="d-flex flex-wrap position-relative mb-2 minutes-card-profile-main">

                                                {meetsData.meetMembers && meetsData.meetMembers.slice(0, meetsMebersDisplayCount).map((member: any, index: any) => {
                                                    return (
                                                        <div className="profile-card-social__item facebook" key={member.name + index} title={member.name}>
                                                            {getInitials(member.name)}</div>

                                                    );
                                                })}

                                                {meetsData.meetMembers.length > 5 && meetsMebersDisplayCount == 5 && <div className="profile-card-social__item facebook"
                                                    key={meetsData.meetMembers.length} title={meetsData.meetMembers.length} onClick={showMoreMember}>
                                                    + {meetsData.meetMembers.length - 5}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-4" style={{marginBottom:'180px'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div style={{ boxShadow: "2px 2px 8px 0px rgb(13 28 39 / 20%)", borderRadius: "12px" }}>
                                <div className="row mt-3 mx-0 minites-meeting-heading">
                                    <div className="col-lg-12 px-0">
                                        <div className="nav-align-top mt-1">
                                            <ul className="nav px-3" style={{ borderBottom: "2px solid #DEE2E6" }}>
                                                <li style={{ borderBottom: "3px solid #8A1BBA" }} className="nav-item nav-tab px-3 h5 mb-0 py-3">Meets Log</li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row timeline-heading mx-0 mt-3 px-3"> 
                                                                   
                                        <MeetsLogSummary isProfile={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>}
            </div>
            }
        </>
    );
}

export default MinutesOfMeets;