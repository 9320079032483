import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchHolidayTemplate,
    showAndHidePageLoader,
    createAndUpdateHolidayTemplate,
    loadDefaultEmptyHoliday,
    updateHolidayTemplateData,
    clearHolidayTemplateData
} from "../../Store/holidayTemplateSlice";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import HolidayTemplateName from '../../Components/Templates/holidayTemplateName';
import Holidays from '../../Components/Templates/holidays';
import HolidayTemplateDetailsSkeletonLoader from '../../Components/SkeletonLoader/HolidayTemplateDetailsSkeletonLoader';
import { updateBlockerData } from "../../Store/commonSlice";
import Breadcrumbs from '../../Components/Layout/Shared/Breadcrumbs';


interface HolidayTemplateDetailsComponentProps {
    isOverlay?: boolean;
    onOverlayClose?: (payload?:any) => void;
}

const HolidayTemplateDetailsComponent: React.FC<HolidayTemplateDetailsComponentProps> = ({ isOverlay = false, onOverlayClose }) => {
    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    }
    const [showComponent, setshowComponent] = useState(false);
    const { templateId } = useParams<"templateId">();
    const dispatch = useDispatch<any>();
    
    const defaultTeamsId = useSelector(
        (state: any) => state.meetsReducer.defaultTeamsId
    );

    const holidayTemplate = useSelector(
        (state: any) => state.holidayTemplateReducer.holidayTemplate
    );

    const pageLoader = useSelector(
        (state: any) => state.holidayTemplateReducer.pageLoader
    );

    const loggedInUser = useSelector(
        (state: any) => state.authReducer.userDetails
    );

    useEffect(() => {
        dispatch(showAndHidePageLoader({ value: true }));
        if (defaultTeamsId) {
            (async () => {
                if (!templateId) {
                    dispatch(loadDefaultEmptyHoliday());
                    dispatch(updateHolidayTemplateData({ name: "teamsId", value: defaultTeamsId }));
                    dispatch(updateHolidayTemplateData({ name: "createdByUserId", value: loggedInUser.userAdObjectId }));
                }
                else {
                    await dispatch(fetchHolidayTemplate(templateId));
                }
            dispatch(showAndHidePageLoader({ value: false }));
            setshowComponent(true);
            })();
        }
    }, [defaultTeamsId]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        resetField
    } = useForm(
        {
            mode: "all",
        }
    );

    const onSubmit = async () => {
        dispatch(showAndHidePageLoader({ value: true }));
        dispatch(updateBlockerData({ isModified: false }));
        let response = await dispatch(createAndUpdateHolidayTemplate(holidayTemplate));
        if (response.payload) {
            await swal({ title: "Success", text: `The holiday template ${templateId ? 'updated' : 'created'} successfully`, icon: "success", timer: 3000, buttons: [false] })
                .then((value) => {
                    dispatch(showAndHidePageLoader({ value: false }));
                    if(isOverlay === true && onOverlayClose) 
                        onOverlayClose(response?.payload);
                    else
                      routeChange("/templates")
                });
        }
    }

    const cancelEvent = () => {
        if(isOverlay === true && onOverlayClose) 
            onOverlayClose(null);
        else
          routeChange("/templates")
    }

    const onError = (errors: any, event: any) => {
        console.log(errors, event);
    }

    useEffect(() => {
        return () => { // works on page leave
            dispatch(clearHolidayTemplateData());
        };
    }, []);

    return (
        <div className="container-fluid px-0 Template-details">
            {pageLoader && <HolidayTemplateDetailsSkeletonLoader/>}
            {!pageLoader && showComponent && <div>
                <form  id="holidayTemplateDetails"  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit(onSubmit, onError)();}}>
                    <div className="container-fluid bg-light px-0">
                        <div className="container py-4">
                            <div className="d-flex meeting-panel">
                                <div className="">
                                    <h1 className="h1 my-0 fw-bold">Template Details</h1>
                                  {!isOverlay &&  <Breadcrumbs paths={[{ name: 'Templates', route: '/templates' }]} currentPage="Template Details"   hideTeams={true}/>}
                                </div>
                                <div className="ms-auto my-auto">
                                    <button type="submit" className="btn float-end fw-bold newstandup-button"><i className="fa-solid fa-check me-2"></i>Save</button>
                                    <button type="button" className="btn float-end fw-bold newstandup_cancel_button mx-2" onClick={() =>  cancelEvent()}><i className="fa-solid fa-xmark me-2"></i>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className='row'>
                            <HolidayTemplateName register={register}
                                errors={errors} reset={reset} />
                        </div>

                        <div className='row mt-3'>
                            <Holidays register={register}
                                errors={errors} isFromTemplate = {true} holidays = {holidayTemplate.holidays} resetField={resetField} />
                        </div>
                    </div>
                </form>
            </div>
            }
        </div>
    );
}

export default HolidayTemplateDetailsComponent;