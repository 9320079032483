import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

function NoMatch() {
    const navigate = useNavigate();
    let location = useLocation();
    
    const routeChange = (path: string) => {
        navigate(path);
    };

    if (location.pathname.toLowerCase().indexOf("signin") > -1) {
        routeChange("/")
   }

    return (
        <div className="bg-purple">
        <div className="stars">
            <div className="central-body">
            <h1 className='text-heading'>404</h1>
            <p className='text-looks'>The resource you are looking for may not be available or moved. <br/> Please navigate to the home page or retry sometime later.</p>
            <button className="btn btn-lg  w-80 mt-4 btn-request" style={{ borderRadius:'90px' }}  onClick={() => routeChange("/")}>GO BACK HOME</button>
            </div>
            <div className="objects">
                <div className="box_astronaut">
                    {/* <img className="object_astronaut" width="200px" src={require('../Assets/Images/bot-logo.png')} alt="logo" /> */}
                </div>
            </div>
            <div className="glowing_stars">
                <div className="star"></div>
                <div className="star"></div>
                <div className="star"></div>
                <div className="star"></div>
                <div className="star"></div>
            </div>
        </div>
    </div>
    );
}

export default NoMatch;