import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface commonStoreInterface {
    isBlocked: boolean
}

const initialState: commonStoreInterface = {
    isBlocked: false,
}

const commonSlice = createSlice({
    name: "commonReducer",
    initialState: initialState,
    reducers: {
        updateBlockerData: (state: any, action: PayloadAction<any>) => {
            state.isBlocked = action.payload.isModified
        },
    },
});

export const {
    updateBlockerData
} = commonSlice.actions;

export default commonSlice.reducer;