import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAddSelectedMember, updateRemoveSelectedMember, updateMemberPermission } from "../../Store/manageSlice";
import { memberList, requiredFields } from "../../Types/MeetsType";
import { getInitials, findHelpText } from "../../Utils/CommonUtils";
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { updateBlockerData } from "../../Store/commonSlice";
import swal from "sweetalert";

function MeetsMembers(props: requiredFields) {

  const dispatch = useDispatch<any>();
  const { errors, control } = props;

  const memberList = useSelector(
    (state: any) => state.manageReducer.memberList
  );

  const selectedMembers = useSelector(
    (state: any) => state.manageReducer.meetsData.meetMembers
  );

  const addSelectedMember = (event: any) => {
    dispatch(updateAddSelectedMember(event))
    dispatch(updateBlockerData({ isModified: true }))
  };

  const userAdObjectId = useSelector(
    (state: any) => state.authReducer.userDetails.userAdObjectId
  );

  const removeSelectedMember = async (event: any) => {
    if (event.meetsPermissions.manage && event.aadObjectId === userAdObjectId) {
      await swal({
        title: "Warning", text: "If you proceed you will no longer have access to this meets. If your intention was to avoid posting updates, you can instead try removing the report privilege from yourself.", icon: "warning",
        dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
      }).then(async willConfirm => {
        if (willConfirm) {
          await dispatch(updateRemoveSelectedMember(event))
          await dispatch(updateBlockerData({ isModified: true }))
        }
      });
    }
    else {
      await dispatch(updateRemoveSelectedMember(event))
      await dispatch(updateBlockerData({ isModified: true }))
    }
  };

  const isCheckSelectedMember = () => {
    return selectedMembers.length === 0 ? false : true;
  }

  const updatePermission = async (aadObjectId: string, event: any) => {
    if (event.target.name === "manage" && aadObjectId === userAdObjectId && !event.target.checked) {
      await swal({
        title: "Warning", text: "If you remove the manage privilege you will no longer be able to manage this meets.", icon: "warning",
        dangerMode: true, buttons: ["No", "Yes"], closeOnClickOutside: false,
      }).then(async willConfirm => {
        if (willConfirm) {
          dispatch(updateMemberPermission({ aadObjectId: aadObjectId, name: event.target.name, value: event.target.checked }))
        }
        else {
          event.target.checked = true;
        }

      });
    }
    else {
      await dispatch(updateMemberPermission({ aadObjectId: aadObjectId, name: event.target.name, value: event.target.checked }))
    }
    dispatch(updateBlockerData({ isModified: true }))
  }

  const manageUser = () => {
    return selectedMembers && selectedMembers.filter((e: any) => e.meetsPermissions.manage === true).length > 1
  }

  return (
    <div className="mb-3">
      <h2 className="h2 mt-4 d-flex align-items-center">Add Members<div className="question-icon ms-2 position-relative">
        <span className="tooltips" data-tool={findHelpText("members")}>i</span></div></h2>

      <div className={" " + (errors.meetsmembers && (selectedMembers && selectedMembers.length === 0) ? 'active_validation' : '')}>
        <Controller
          name="meetsmembers"
          control={control}
          rules={{
            validate: {
              isMember: () => isCheckSelectedMember(),
            }
          }}
          render={({
            field: { name },
            formState: { errors }
          }) => (
            <>
              <Select isSearchable className="form-select-lg px-0 add-member-select-color" value='Add Members' placeholder="Add Members" options={memberList}
                getOptionLabel={(option: any) => `${option.name}`} onChange={addSelectedMember} classNamePrefix="add-members-select"
              />
              {errors && errors[name] && errors[name]?.type === "isMember" && selectedMembers && selectedMembers.length === 0 && (
                <span className="error_message">Members is required</span>
              )}
            </>
          )}
        />
      </div>

      <div className="row">
        {(selectedMembers && selectedMembers.length === 0) && <div style={{ textAlign: "center", padding: '20px' }}>No Members</div>}
        {selectedMembers && selectedMembers.map((data: memberList, index: number) =>
          <div className="col-lg-4 col-md-4 col-sm-6 user-card" key={"standup_" + index}>
            <div className="card p-3 border-white shadow rounded-3 mt-4">
              <div className="d-flex">
                <div className="me-4 d-flex align-items-center">
                  <div className="teamMember-circle"> {getInitials(data.name)}</div>
                  <h6 className="h6 ms-2 my-0">{data.name}</h6>
                </div>
                {(manageUser() || !data.meetsPermissions.manage)
                  &&
                  <div className="ms-auto">
                    <button type="button" className="trash-button" onClick={() => removeSelectedMember(data)} key={data.aadObjectId +"remove"}>
                      <i className="fa-solid fa-trash"></i></button>
                  </div>}
              </div>

              <div className="d-flex mt-3 justify-content-between">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={index + "report"} key={data.aadObjectId + "report"} name="report"
                    defaultChecked={data.meetsPermissions && data.meetsPermissions.report}
                    onClick={(event) => updatePermission(data.aadObjectId, event)}
                  />
                  <label className="form-check-label">Report</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={index + "manage"} key={data.aadObjectId + "manage"} name="manage"
                    defaultChecked={data.meetsPermissions && data.meetsPermissions.manage}
                    disabled={!manageUser() && data.meetsPermissions.manage}
                    onClick={(event) => updatePermission(data.aadObjectId, event)} />
                  <label className="form-check-label">Manage</label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MeetsMembers;