import React from 'react';
function FooterComponent() {

  const openInNewTab = (url:string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="mt-auto">
      <div className="container-fluid bg-dark mt-5 footer-margin-top px-0">
        <div className="container px-0">
          <h5 className="h5 text-center my-0 py-3 text-white footer-text">© 2024 Meetstand, All rights reserved  | 
          <span onClick={() => openInNewTab("/termsandconditions")} className='footer-link' > Terms and Conditions</span> | 
          <span onClick={() => openInNewTab("/privacypolicy")} className='footer-link'> Privacy Policy</span> |
          <span onClick={() => openInNewTab("/support")} className='footer-link'> Support</span> |
          <span onClick={() => openInNewTab("/contactus")} className='footer-link'> Contact Us</span>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;