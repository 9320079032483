import React from 'react';


function HolidayTemplatesSkeletonLoader() {
    return (
        <div className="container mt-3 mb-3">
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="nav-align-top mt-1 mb-4">
                        <ul className="nav nav-pills row" role="tablist">
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5 holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton py-2 mt-2 holiday-template-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5 holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton py-2 mt-2 holiday-template-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5  holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton holiday-template-line py-2 mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="nav-align-top mt-1 mb-4">
                        <ul className="nav nav-pills row" role="tablist">
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5 holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton py-2 mt-2 holiday-template-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5 holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton py-2 mt-2 holiday-template-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item col-lg-4 mb-3">
                                <div className="nav-link p-0 h-60" role="tab">
                                    <div className="card skeleton-Card skeleton skeleton holiday-template">
                                        <div>
                                            <div className="skeleton py-2 mt-2 px-5  holiday-template-firstline"></div>
                                            <div className="skeleton border-line mt-2"></div>
                                            <div className="skeleton holiday-template-line py-2 mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HolidayTemplatesSkeletonLoader;