import React from 'react';


function CreateMeetsSkeletonLoader() {
    return (
        <div>
            <div className="container-fluid">
                <div className="container py-4">
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="skeleton-heading skeleton py-2"></div>
                                <div className="skeleton-heading skeleton py-4 mt-3"></div>
                            </div>
                        </div>
                        <div className="col m-auto">
                            <div className="skeleton-standup-edit-btn skeleton float-end mx-0"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-3 mb-3">
                <div className="row">
                    <div className="col-lg-6 my-2">
                        <div>
                            <div className="skeleton-heading skeleton py-3"></div>
                            <div className="mt-3 skeleton py-4 rounded"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 my-2">
                        <div>
                            <div className="skeleton-heading skeleton py-3"></div>
                            <div className="mt-3 skeleton py-4 rounded"></div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <div className="nav-align-top mt-1 mb-4">
                            <ul className="nav nav-pills row" role="tablist">
                                <li className="nav-item col-lg-4 mb-3">
                                    <div className="nav-link p-0 h-100">
                                        <div className="card skeleton-Card skeleton py-5">
                                            <div>
                                                <div>
                                                    <div className="col-lg-12">
                                                        <div className="skeleton-heading skeleton py-2"></div>
                                                        <ul className="ps-4 mt-2">
                                                            <div className="skeleton py-2 mt-2 px-5"></div>
                                                            <div className="skeleton py-2 mt-2"></div>
                                                            <div className="skeleton py-2 mt-2"></div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item col-lg-4 mb-3">
                                    <div className="nav-link p-0 h-100" role="tab">
                                        <div className="card skeleton-Card skeleton">
                                            <div>
                                                <div><div className="skeleton-heading skeleton py-2"></div></div>
                                                <div className="skeleton w-100 py-4 mt-3 rounded-3"></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item col-lg-4 mb-3">
                                    <div className="nav-link p-0 h-100" role="tab">
                                        <div className="card skeleton-Card skeleton">
                                            <div>
                                                <div><div className="skeleton-heading skeleton py-2"></div></div>
                                                <div className="skeleton py-2 mt-3"></div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="tab-content mt-3">
                                <div className="tab-pane fade show active mb-3" id="prompt-tab" role="tabpanel">
                                    <div>
                                        <div className="skeleton-heading skeleton py-3"></div>
                                        <div className="skeleton rounded py-5 mt-3"></div>
                                    </div>
                                    <div>
                                        <h2 className="h2 mt-4 d-flex align-items-center"><div className="skeleton-heading skeleton py-3"></div><button className="prompt-form-add skeleton ms-2 px-5 py-2"></button></h2>
                                        <div className="skeleton rounded py-4 mt-3"></div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="skeleton-heading skeleton py-3"></div>
                                        <div className="skeleton rounded py-5 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-auto">
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center py-3">
                            <div className="skeleton skeleton-footer py-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateMeetsSkeletonLoader;